import React, { useContext, cloneElement } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ApplicationContext } from "../../context/GlobalState";

const UnauthenticatedRoute = (props) => {
	//
	const [state, dispatch] = useContext(ApplicationContext);
	const { isAuthenticated } = state;
	const { children } = props;

	if (isAuthenticated) {
		return <Navigate to={"/"} />;
	}

	return cloneElement(children, props);
};

export default UnauthenticatedRoute;
