import React, { useState, useContext, useEffect, useRef } from "react";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Table,
  Modal,
  Tabs,
  Tab,
  OverlayTrigger,
  Tooltip,
} from "react-bootstrap";
import Geosuggest from "react-geosuggest";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faLanguage,
  faUserTie,
  faVideo,
  faTools,
  faClock,
  faTimes,
  faInfoCircle,
} from "@fortawesome/free-solid-svg-icons";
import {
  getUsersByRole,
  createDeposition,
  listDepositions,
  getDeposition,
  updateDeposition,
} from "../../libs/apiService";
import { ApplicationContext } from "../../context/GlobalState";
import Calendar from "react-calendar";
import "react-calendar/dist/Calendar.css"; // Import calendar CSS
import TimePicker from "react-time-picker"; // Import the time picker
import "react-time-picker/dist/TimePicker.css";
import "react-clock/dist/Clock.css";
import "./LawyerDashboard.css"; // Assuming you have a CSS file for LawyerDashboard
import config from "../../config";
/* global google */

export const LawyerDashboard = ({ calendar }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [showDepositionForm, setShowDepositionForm] = useState(!calendar);
  const [showCalendar, setShowCalendar] = useState(calendar); // Show calendar based on prop
  const [depositionDetails, setDepositionDetails] = useState({});
  const [calendarDate, setCalendarDate] = useState(new Date());
  const [newDate, setNewDate] = useState(new Date());
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [depositions, setDepositions] = useState([
    {
      depositionId: "",
      dateTime: "",
      expectedDuration: 0,
      caseNumber: "",
      caseName: "",
      witnessName: "",
      locationAddress: "",
      status: "",
      expedite: false,
      expediteDays: "0",
      needInterpreter: false,
      needCourtReporter: false,
      needVideographer: false,
      needVideoTech: false,
    },
  ]); // Assuming you have a way to fetch depositions
  const [showDepoModal, setShowDepoModal] = useState(false);
  const [newActiveTab, setNewActiveTab] = useState(null);
  const [selectedActiveTab, setSelectedActiveTab] = useState(null);
  const [courtReportersList, setCourtReportersList] = useState([]);
  const geosuggestEl = useRef(null);

  // FIXME:
  const [selectedDeposition, setSelectedDeposition] = useState({
    needCourtReporter: true,
    needInterpreter: false,
    needVideographer: false,
    needVideoTech: false,
    // existing fields
    /*
    depositionId: "", // FIXME: ON SUBMIT THIS IS CREATED ON BACKEND
    depoDate: "",
    depoTime: "",
    expectedDuration: "",
    caseNumber: "",
    caseName: "",
    witnessName: "",
    locationAddress: "",
    attorneyType: "",
    status: "", // FIXME: What is default status
    expedite: "",
    expediteDays: "",
    needCourtReporter: true,
    needInterpreter: false,
    needVideographer: false,
    needVideoTech: false,
    preferredCourtReporter: "",
    interpreterLanguage: "",
    videographerDetails: "",
    videoTechDetails: "",
    deliverableAddOnRequest: {
      word: true,
      etran: false,
      pdf: false,
      fullSizeTranscript: false,
      ascii: false,
      keywordIndexedTranscript: false,
    },
    copiesNeeded: 1,
    realTimeService: false,
    medTechService: false,
    arbitration: false,
    specialInstructions: "",
    */
  });

  const [newDeposition, setNewDeposition] = useState({
    // existing fields
    deliverableAddOnRequest: {},
    /*

    depositionId: "", // FIXME: ON SUBMIT THIS IS CREATED ON BACKEND
    depoDate: "",
    depoTime: "",
    expectedDuration: "",
    caseNumber: "",
    caseName: "",
    witnessName: "",
    locationAddress: "",
    attorneyType: "",
    status: "", // FIXME: What is default status
    needCourtReporter: true,
    needInterpreter: false,
    needVideographer: false,
    needVideoTech: false,
    preferredCourtReporter: "",
    interpreterLanguage: "",
    videographerDetails: "",
    videoTechDetails: "",
    deliverableAddOnRequest: {
      word: true,
      etran: false,
      pdf: false,
      fullSizeTranscript: false,
      ascii: false,
      keywordIndexedTranscript: false,
    },
    copiesNeeded: 1,
    realTimeService: false,
    medTechService: false,
    arbitration: false,
    specialInstructions: "",
    */
  });

  // FIXME: trying to dynamically set height of the App component
  const { user, barRef } = state;

  useEffect(() => {
    window.scrollTo(0, 0);
    console.log("USER RECORD ID", user.organizationId);
  }, []);

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  // Update component state when the calendar prop changes
  useEffect(() => {
    console.log("TEST CALENDAR", calendar);
    setShowCalendar(calendar);
    setShowDepositionForm(!calendar);
  }, [calendar]);

  // Fetch court reporters when the component mounts
  useEffect(() => {
    fetchCourtReporters();
  }, []);

  // live data for depositions
  useEffect(() => {
    fetchDepositions();
  }, []);

  // Function to fetch court reporters
  const fetchCourtReporters = async () => {
    setLoading(true);
    try {
      const payload = {}; // NOTE: Replace with actual organization ID for non-admin user
      const response = await getUsersByRole("Court_Reporter", payload);
      setCourtReportersList(response);
    } catch (error) {
      console.error("Failed to fetch court reporters", error);
    }
    setLoading(false);
  };

  const fetchDepositions = async () => {
    setLoading(true);
    try {
      const response = await listDepositions(user.organizationId);
      console.log("LLLIST DEPOSITIONS: ", response);
      setDepositions(response.data);
    } catch (error) {
      console.error("Failed to fetch depositions", error);
    }
    setLoading(false);
  };

  useEffect(() => {
    if (newDeposition && !newActiveTab) {
      const activeTabs = [
        "needCourtReporter",
        "needInterpreter",
        "needVideographer",
        "needVideoTech",
      ].filter((key) => newDeposition[key]);
      setNewActiveTab(activeTabs.length > 0 ? activeTabs[0] : null);
    } else if (selectedDeposition && !selectedActiveTab) {
      const activeTabs = [
        "needCourtReporter",
        "needInterpreter",
        "needVideographer",
        "needVideoTech",
      ].filter((key) => selectedDeposition[key]);
      setNewActiveTab(activeTabs.length > 0 ? activeTabs[0] : null);
    }
  }, [newDeposition, newActiveTab, selectedActiveTab]);

  const getDefaultActiveKey = (deposition) => {
    if (deposition.needCourtReporter) return "courtReporter";
    if (deposition.needInterpreter) return "interpreter";
    if (deposition.needVideographer) return "videographer";
    if (deposition.needVideoTech) return "videoTech";
    return null;
  };

  useEffect(() => {
    if (selectedDeposition) {
      setSelectedActiveTab(getDefaultActiveKey(selectedDeposition));
    }
  }, [selectedDeposition]);

  const handleAddDepositionClick = () => {
    setShowDepositionForm(true);
    setShowCalendar(false);
  };

  const handleToggleCalendar = () => {
    setShowCalendar(true);
    setShowDepositionForm(false);
  };

  const handleNewFormSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    const cleanedDeposition = { ...newDeposition };

    // Remove individual deliverableAddOnRequest properties
    Object.keys(cleanedDeposition).forEach((key) => {
      if (key.startsWith("deliverableAddOnRequest.")) {
        delete cleanedDeposition[key];
      }
    });

    try {
      let payload = {
        ...cleanedDeposition,
        userRecordId: user.userRecordId,
        organizationId: user.organizationId,
      };
      // Call the createDeposition API
      const response = await createDeposition(payload);
      console.log("Form submitted with deposition details:", response);

      // get updated depos in list
      await fetchDepositions();

      // Handle successful submission
      setShowDepositionForm(false);
      setShowCalendar(true);
    } catch (error) {
      console.error("Failed to create deposition:", error);
      alert("Failed to create deposition. Please try again.");
    }
    setLoading(false);
  };

  const handleSelectedFormSubmit = (e) => {
    e.preventDefault();

    // Handle form submission logic
    if (!selectedDeposition?.depoTime) {
      alert("Please select a deposition time - LawyerDashboard line: 349.");
      return;
    }

    // TODO: Need to submit to backend depos here
    setShowDepositionForm(false);
    setShowCalendar(true);
    console.log("Form submitted with deposition details:", selectedDeposition);
  };

  const handleSelectedInputChange = (e) => {
    console.log("handleSelectedInputChange e.target: ", e.target);
    console.log("handleSelectedInputChange e2: ", e);

    const { name, value, type, checked } = e.target;
    const updatedDeposition = {
      ...selectedDeposition,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name.startsWith("deliverableAddOnRequest")) {
      const deliverableName = name.split(".")[1];
      updatedDeposition.deliverableAddOnRequest[deliverableName] = checked;
    }

    setSelectedDeposition(updatedDeposition);

    // Update active tab based on the input field being updated
    let selectedActiveTab = null;
    if (name === "needInterpreter" || name === "interpreterLanguage") {
      selectedActiveTab = "needInterpreter";
    } else if (name === "needVideographer" || name === "videographerDetails") {
      selectedActiveTab = "needVideographer";
    } else if (name === "needVideoTech" || name === "videoTechDetails") {
      selectedActiveTab = "needVideoTech";
    } else if (
      name === "needCourtReporter" ||
      name === "preferredCourtReporter" ||
      name === "specialInstructions" ||
      name === "realTimeService" ||
      name === "medTechService" ||
      name === "arbitration" ||
      name === "copiesNeeded" ||
      name === "locationAddress" ||
      name === "depoDate" ||
      name === "caseNumber" ||
      name === "witnessName" ||
      name === "courtHearingCase" ||
      name === "attorneyType" ||
      name === "depoTime" ||
      name === "caseName" ||
      name === "expectedDuration" ||
      name.startsWith("deliverableAddOnRequest") ||
      name === "expedite" ||
      name === "expediteDays"
    ) {
      selectedActiveTab = "needCourtReporter";
    }

    setSelectedActiveTab(selectedActiveTab);
  };

  const handleNewInputChange = (e) => {
    console.log("handleNewInputChange e.target: ", e.target);
    console.log("handleNewInputChange e2: ", e);

    const { name, value, type, checked } = e.target;
    const updatedDeposition = {
      ...newDeposition,
      [name]: type === "checkbox" ? checked : value,
    };

    if (name.startsWith("deliverableAddOnRequest")) {
      const deliverableName = name.split(".")[1];
      updatedDeposition.deliverableAddOnRequest[deliverableName] = checked;
    }

    setNewDeposition(updatedDeposition);

    // Update active tab based on the input field being updated
    let newActiveTab = null;
    if (name === "needInterpreter" || name === "interpreterLanguage") {
      newActiveTab = "needInterpreter";
    } else if (name === "needVideographer" || name === "videographerDetails") {
      newActiveTab = "needVideographer";
    } else if (name === "needVideoTech" || name === "videoTechDetails") {
      newActiveTab = "needVideoTech";
    } else if (
      name === "needCourtReporter" ||
      name === "preferredCourtReporter" ||
      name === "specialInstructions" ||
      name === "realTimeService" ||
      name === "medTechService" ||
      name === "arbitration" ||
      name === "copiesNeeded" ||
      name === "locationAddress" ||
      name === "depoDate" ||
      name === "caseNumber" ||
      name === "witnessName" ||
      name === "courtHearingCase" ||
      name === "attorneyType" ||
      name === "depoTime" ||
      name === "caseName" ||
      name === "expectedDuration" ||
      name.startsWith("deliverableAddOnRequest") ||
      name === "expedite" ||
      name === "expediteDays"
    ) {
      newActiveTab = "needCourtReporter";
    }

    setNewActiveTab(newActiveTab);
  };

  const handleSelectedTimeChange = (value) => {
    if (value) {
      const [hours, minutes] = value.split(":").map(Number);
      const roundedMinutes = Math.round(minutes / 15) * 15;
      const adjustedMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;
      const adjustedHours = roundedMinutes === 60 ? (hours + 1) % 24 : hours;
      const formattedTime = `${String(adjustedHours).padStart(2, "0")}:${String(
        adjustedMinutes
      ).padStart(2, "0")}`;

      setSelectedDeposition((prevDetails) => ({
        ...prevDetails,
        depoTime: formattedTime,
      }));
    } else {
      setSelectedDeposition((prevDetails) => ({
        ...prevDetails,
        depoTime: value,
      }));
    }
  };

  const handleNewTimeChange = (value) => {
    if (value) {
      const [hours, minutes] = value.split(":").map(Number);
      const roundedMinutes = Math.round(minutes / 15) * 15;
      const adjustedMinutes = roundedMinutes === 60 ? 0 : roundedMinutes;
      const adjustedHours = roundedMinutes === 60 ? (hours + 1) % 24 : hours;
      const formattedTime = `${String(adjustedHours).padStart(2, "0")}:${String(
        adjustedMinutes
      ).padStart(2, "0")}`;

      setNewDeposition((prevDetails) => ({
        ...prevDetails,
        depoTime: formattedTime,
      }));
    } else {
      setNewDeposition((prevDetails) => ({
        ...prevDetails,
        depoTime: value,
      }));
    }
  };

  // handles date changes on primary calendar
  const handleSelectedDateChange = (date) => {
    setSelectedDate(date);
    // Fetch depositions for the selected date
    // setDepositions(fetchDepositionsForDate(date));
  };

  const handleDepositionClick = (deposition) => {
    setSelectedDeposition(deposition);
    setShowDepoModal(true);
  };

  const handleCloseDepoModal = () => {
    setShowDepoModal(false);
  };

  const isNewDepoFormValid = () => {
    const {
      copiesNeeded,
      locationAddress,
      depoDate,
      depoTime,
      caseNumber,
      witnessName,
      courtHearingCase,
      attorneyType,
      caseName,
      expectedDuration,
    } = newDeposition;

    const now = new Date();
    const depoDateTime = new Date(`${depoDate}T${depoTime}`);

    // Ensure depoDateTime is at least 15 minutes in the future
    const isDepoDateValid =
      depoDate &&
      depoTime &&
      depoDateTime > new Date(now.getTime() + 15 * 60000);

    return (
      user.verifiedEmail &&
      copiesNeeded > 0 &&
      locationAddress?.length > 7 &&
      isDepoDateValid &&
      caseNumber?.length > 3 &&
      witnessName?.length > 7 &&
      courtHearingCase?.length > 7 &&
      attorneyType &&
      caseName?.length > 7 &&
      expectedDuration > 0
    );
  };

  /**
   *  @function regexAddress
   *
   *  This functions splits the selected suggestion
   *  into parts to return the street address line
   *  1 data.
   *
   *  @param suggestion
   *
   *  @return [streetAddress, city, state]
   *
   */
  const regexAddress = (suggestion) => {
    if (suggestion.length > 0) {
      // FIXME: When address is 1085-1087 Boylston St Regex fails at 1085-
      let streetAddress = suggestion.split(", ")[0];
      let city = suggestion.split(", ")[1];
      let state = suggestion.split(", ")[2];
      console.log("REGEX 1: ", [streetAddress, city, state]);
      return [streetAddress, city, state];
    }
  };

  /**
   *
   * @function zipCodeSearch
   *
   */
  const zipCodeSearch = async (geoCodeURL) => {
    let geoData;
    let formattedAddress;
    let url = geoCodeURL;

    // NOTE: FIXED THE ERROR WITH PARSING THE JSON RESPONSE
    // Need to get address data back from google
    // to pull zipcode data
    // eslint-disable-next-line
    await fetch(url)
      .then((response) => response.json())
      // eslint-disable-next-line
      .then((data) => (formattedAddress = data));

    console.log("ADDRESS1 DATA", formattedAddress);

    geoData = formattedAddress.results[0];

    formattedAddress = geoData.formatted_address.split(", ");

    let geoCoords = geoData.geometry.location;
    let googlePlaceId = geoData.geometry.place_id;
    let geoViewport = geoData.geometry.viewport;

    console.log("FORMATTED ADDRESS1", formattedAddress);

    let city = formattedAddress[formattedAddress.length - 3];
    let stateOf = formattedAddress[formattedAddress.length - 2].split(" ")[0];
    let zipCode = formattedAddress[formattedAddress.length - 2].split(" ")[1];
    let country = formattedAddress[formattedAddress.length - 1];

    return {
      geoCoords: geoCoords,
      googlePlaceId: googlePlaceId,
      geoViewport: geoViewport,
      city: city,
      stateOf: stateOf,
      zipCode: zipCode,
      country: country,
    };

    // 711 East Okeechobee Road, Hialeah, FL 33010, USA
  };

  /**
   *
   * @function selectSuggestion
   *
   */
  const useSelectBillToSuggestion = async (suggestion) => {
    try {
      let streetAddress = suggestion.label;

      let apiKey = config.Places_API_KEY;
      let reg = regexAddress(streetAddress);

      streetAddress = reg[0];

      let url = "https://maps.googleapis.com/maps/api/geocode/json?address=";

      let address = streetAddress.split(" ");

      let query = "";

      for (let i = 0; i < address.length; i++) {
        if (i === address.length - 1) {
          query = query + address[i];
        } else {
          query = query + address[i] + "+";
        }
      }

      // build the google api url we need to get location data
      url += query + "+" + reg[1] + "+" + reg[2] + "&key=" + apiKey;

      // NOTE: call the zipCodeSearch function so that
      //       we can extract location data from google
      //       response inside of formatted_address field
      let {
        city,
        stateOf,
        zipCode,
        country,
        geoCoords,
        googlePlaceId,
        geoViewport,
      } = await zipCodeSearch(url);

      typeof zipCode === "string" && /^\d{5}$/.test(zipCode)
        ? console.log("zipCode is Good: ", zipCode)
        : (zipCode = "INVALID");

      let billToAddress = {
        streetAddress:
          typeof zipCode === "string" && /^\d{5}$/.test(zipCode)
            ? streetAddress
            : "INVALID",
        streetAddressLine2: "",
        city: city,
        stateOf: stateOf,
        zipCode: zipCode,
        country: country,
      };

      // Construct the address string
      let addressParts = [];

      if (billToAddress.streetAddress) {
        addressParts.push(billToAddress.streetAddress);
      }

      if (billToAddress.streetAddressLine2) {
        addressParts.push(billToAddress.streetAddressLine2);
      }

      if (billToAddress.city) {
        addressParts.push(billToAddress.city);
      }

      if (billToAddress.stateOf) {
        addressParts.push(billToAddress.stateOf);
      }

      if (billToAddress.zipCode) {
        addressParts.push(billToAddress.zipCode);
      }

      if (billToAddress.country) {
        addressParts.push(billToAddress.country);
      }

      let concatenatedAddress = addressParts.join(", ");

      console.log("CONCAT ADDRESS HERE: ", concatenatedAddress);

      console.log("SHOW DEPO FORM: ", showDepositionForm);
      if (showDepositionForm && !showDepoModal) {
        const updatedDeposition = {
          ...newDeposition,
          locationAddress: concatenatedAddress,
          geoCoords: geoCoords,
          googlePlaceId: googlePlaceId,
          geoViewport: geoViewport,
        };

        setNewDeposition(updatedDeposition);
      } else if (showCalendar && showDepoModal) {
        const updatedDeposition = {
          ...selectedDeposition,
          locationAddress: concatenatedAddress,
          geoCoords: geoCoords,
          googlePlaceId: googlePlaceId,
          geoViewport: geoViewport,
        };
        setSelectedDeposition(updatedDeposition);
      }
    } catch (err) {
      console.log("SELECT SUGGESTION ADDRESS CLEARED: ", err);
    }
  };

  /**
   *
   * @function renderDepositionForm()
   *
   * function that is responsible for rendering the create new
   * deposition form to let a lawyer request a new deposition
   *
   *
   **/
  const renderDepositionForm = () => (
    <Form onSubmit={(e) => e.preventDefault()} className="skeuomorphic-element">
      <Row>
        <Col md={6}>
          <Form.Group
            controlId="locationAddress"
            className="form-label-left fw-bold"
          >
            <Form.Label>Location Address</Form.Label>
            <Geosuggest
              ref={geosuggestEl}
              initialValue={
                isNaN(newDeposition.locationAddress) ||
                newDeposition.locationAddress.length > 0
                  ? newDeposition.locationAddress
                  : newDeposition.locationAddress
              }
              placeholder="Enter your Street Address!"
              country="us"
              onSuggestSelect={useSelectBillToSuggestion}
              location={new google.maps.LatLng(40.0038731, -75.0679655)}
              radius="5000000"
              required={true}
            />
          </Form.Group>

          <Form.Group controlId="depoDate" className="form-label-left fw-bold">
            <Form.Label>Depo Date</Form.Label>
            <Form.Control
              type="date"
              name="depoDate"
              value={newDeposition?.depoDate?.split("T")[0] || ""}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="caseNumber"
            className="form-label-left fw-bold"
          >
            <Form.Label>Case Number</Form.Label>
            <Form.Control
              type="text"
              name="caseNumber"
              value={newDeposition?.caseNumber || ""}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="witnessName"
            className="form-label-left fw-bold"
          >
            <Form.Label>Witness Name</Form.Label>
            <Form.Control
              type="text"
              name="witnessName"
              value={newDeposition?.witnessName || ""}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="courtHearingCase"
            className="form-label-left fw-bold"
          >
            <Form.Label>Court Hearing Case</Form.Label>
            <Form.Control
              type="text"
              name="courtHearingCase"
              value={newDeposition?.courtHearingCase || ""}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <fieldset className="form-label-left">
            <Form.Group>
              <Form.Label as="legend" className="fw-bold">
                Attorney Type
              </Form.Label>
              <Form.Check
                type="radio"
                label="Direct"
                name="attorneyType"
                value="Direct"
                checked={newDeposition?.attorneyType === "Direct"}
                onChange={handleNewInputChange}
              />
              <Form.Check
                type="radio"
                label="Cross"
                name="attorneyType"
                value="Cross"
                checked={newDeposition?.attorneyType === "Cross"}
                onChange={handleNewInputChange}
              />
            </Form.Group>
          </fieldset>
        </Col>

        <Col md={6}>
          <Form.Group controlId="depoTime" className="form-label-left">
            <Form.Label className="fw-bold">Depo Time</Form.Label>
            <div className="">
              <TimePicker
                onChange={handleNewTimeChange}
                value={newDeposition?.depoTime || ""}
                format="hh:mm a" // Use 12-hour format with AM/PM
                hourPlaceholder="hh"
                minutePlaceholder="mm"
                clearIcon={<FontAwesomeIcon icon={faTimes} />}
                clockIcon={<FontAwesomeIcon icon={faClock} />}
                minDetail="hour"
                maxDetail="minute"
                minuteAriaLabel="Minute"
                hourAriaLabel="Hour"
                className="time-picker"
                name="depoTime"
                amPmAriaLabel="Select AM/PM"
                disableClock={false} // Enable clock for better visual representation
                minTime="00:00"
                maxTime="12:00"
                step={15}
                required={true}
              />
            </div>
          </Form.Group>

          <Form.Group controlId="caseName" className="form-label-left fw-bold">
            <Form.Label>Case Name</Form.Label>
            <Form.Control
              type="text"
              name="caseName"
              value={newDeposition?.caseName || ""}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="expectedDuration"
            className="form-label-left fw-bold"
          >
            <Form.Label>Expected Duration of Depo</Form.Label>
            <Form.Control
              type="number"
              name="expectedDuration"
              value={newDeposition?.expectedDuration || 0}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group controlId="expedite" className="form-label-left">
            <Form.Check
              type="checkbox"
              label={
                <>
                  Expedite the deposition{" "}
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip id="tooltip-right">
                        Default turn around time is 10 days
                      </Tooltip>
                    }
                  >
                    <FontAwesomeIcon className="ms-1" icon={faInfoCircle} />
                  </OverlayTrigger>
                </>
              }
              name="expedite"
              checked={newDeposition?.expedite || false}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          {newDeposition.expedite && (
            <Form.Group controlId="expediteOptions" className="form-label-left">
              <Form.Label className="fw-bold">Expedite Options</Form.Label>
              <section
                style={{ display: "flex", fontSize: ".72rem", width: "100%" }}
              >
                <Form.Check
                  className="ms-0"
                  type="radio"
                  label="1 Day"
                  name="expediteDays"
                  value="1"
                  checked={newDeposition?.expediteDays === "1"}
                  onChange={handleNewInputChange}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label="2 Days"
                  name="expediteDays"
                  value="2"
                  checked={newDeposition?.expediteDays === "2"}
                  onChange={handleNewInputChange}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label="3 Days"
                  name="expediteDays"
                  value="3"
                  checked={newDeposition?.expediteDays === "3"}
                  onChange={handleNewInputChange}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label="4 Days"
                  name="expediteDays"
                  value="4"
                  checked={newDeposition?.expediteDays === "4"}
                  onChange={handleNewInputChange}
                />
                <Form.Check
                  className="ms-2"
                  type="radio"
                  label="5 Days"
                  name="expediteDays"
                  value="5"
                  checked={newDeposition?.expediteDays === "5"}
                  onChange={handleNewInputChange}
                />
              </section>
            </Form.Group>
          )}

          <Form.Group controlId="nodUpload" className="form-label-left fw-bold">
            <Form.Label>NOD (Notice of Deposition)</Form.Label>
            <Form.Control
              type="file"
              name="nodUpload"
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="needCourtReporter"
            className="form-label-left fw-bold"
          >
            <Form.Check
              type="checkbox"
              label="Need Court Reporter"
              name="needCourtReporter"
              checked={newDeposition?.needCourtReporter || false}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="needInterpreter"
            className="form-label-left fw-bold"
          >
            <Form.Check
              type="checkbox"
              label="Need Interpreter"
              name="needInterpreter"
              checked={newDeposition?.needInterpreter || false}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="needVideographer"
            className="form-label-left fw-bold"
          >
            <Form.Check
              type="checkbox"
              label="Need Videographer"
              name="needVideographer"
              checked={newDeposition?.needVideographer || false}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          <Form.Group
            controlId="needVideoTech"
            className="form-label-left fw-bold"
          >
            <Form.Check
              type="checkbox"
              label="Need Video Tech"
              name="needVideoTech"
              checked={newDeposition?.needVideoTech || false}
              onChange={handleNewInputChange}
            />
          </Form.Group>

          {newActiveTab && (
            <Tabs
              activeKey={newActiveTab}
              onSelect={(k) => setNewActiveTab(k)}
              className="mb-3"
            >
              {newDeposition.needCourtReporter && (
                <Tab
                  eventKey="needCourtReporter"
                  title={<FontAwesomeIcon icon={faUserTie} />}
                >
                  <Form.Group
                    controlId="preferredCourtReporter"
                    className="form-label-left"
                  >
                    <Form.Label>Preferred Court Reporter</Form.Label>
                    <Form.Control
                      as="select"
                      name="preferredCourtReporter"
                      value={newDeposition?.preferredCourtReporter || ""}
                      onChange={handleNewInputChange}
                    >
                      <option value="">Select a court reporter</option>
                      {courtReportersList.map((reporter) => (
                        <option
                          key={reporter.userRecordId}
                          value={`${reporter.lastName}, ${reporter.firstName}`}
                        >
                          {`${reporter.lastName}, ${reporter.firstName}`}
                        </option>
                      ))}
                    </Form.Control>
                  </Form.Group>

                  <Form.Group
                    controlId="deliverableAddOnRequest"
                    className="form-label-left mt-3"
                  >
                    <Form.Label className="fw-bold">
                      Deliverable Add-On Request
                    </Form.Label>

                    <div className="flex-container">
                      <Form.Check
                        type="checkbox"
                        label="Word"
                        name="deliverableAddOnRequest.word"
                        checked={
                          newDeposition?.deliverableAddOnRequest?.word || false
                        }
                        onChange={handleNewInputChange}
                      />

                      <Form.Check
                        type="checkbox"
                        label="ETran"
                        name="deliverableAddOnRequest.etran"
                        checked={
                          newDeposition?.deliverableAddOnRequest?.etran || false
                        }
                        onChange={handleNewInputChange}
                      />

                      <Form.Check
                        type="checkbox"
                        label="PDF"
                        name="deliverableAddOnRequest.pdf"
                        checked={
                          newDeposition?.deliverableAddOnRequest?.pdf || false
                        }
                        onChange={handleNewInputChange}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Full Size Transcript"
                        name="deliverableAddOnRequest.fullSizeTranscript"
                        checked={
                          newDeposition?.deliverableAddOnRequest
                            ?.fullSizeTranscript || false
                        }
                        onChange={handleNewInputChange}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Ascii"
                        name="deliverableAddOnRequest.ascii"
                        checked={
                          newDeposition?.deliverableAddOnRequest?.ascii || false
                        }
                        onChange={handleNewInputChange}
                      />

                      <Form.Check
                        type="checkbox"
                        label="Keyword Indexed Transcript"
                        name="deliverableAddOnRequest.keywordIndexedTranscript"
                        checked={
                          newDeposition?.deliverableAddOnRequest
                            ?.keywordIndexedTranscript || false
                        }
                        onChange={handleNewInputChange}
                      />
                    </div>
                  </Form.Group>

                  <Form.Group
                    controlId="copiesNeeded"
                    className="form-label-left mt-3 fw-bold"
                  >
                    <Form.Label>How Many Copies Do You Need?</Form.Label>
                    <Form.Control
                      type="number"
                      name="copiesNeeded"
                      value={newDeposition?.copiesNeeded || 1}
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="realTimeService"
                    className="form-label-left mt-3"
                  >
                    <Form.Check
                      type="switch"
                      label="Real Time Service Required"
                      name="realTimeService"
                      checked={newDeposition?.realTimeService || false}
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="medTechService"
                    className="form-label-left mt-3"
                  >
                    <Form.Check
                      type="switch"
                      label="Med Tech Service Required"
                      name="medTechService"
                      checked={newDeposition?.medTechService || false}
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="arbitration"
                    className="form-label-left mt-3"
                  >
                    <Form.Check
                      type="switch"
                      label="Arbitration"
                      name="arbitration"
                      checked={newDeposition?.arbitration || false}
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>

                  <Form.Group
                    controlId="specialInstructions"
                    className="form-label-left mt-3 fw-bold"
                  >
                    <Form.Label>Special Instructions</Form.Label>
                    <Form.Control
                      as="textarea"
                      name="specialInstructions"
                      rows={3}
                      value={newDeposition?.specialInstructions || ""}
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>
                </Tab>
              )}

              {newDeposition.needInterpreter && (
                <Tab
                  eventKey="needInterpreter"
                  title={<FontAwesomeIcon icon={faLanguage} />}
                >
                  <Form.Group
                    controlId="interpreterLanguage"
                    className="form-label-left"
                  >
                    <Form.Label>Interpreter Language</Form.Label>
                    <Form.Control
                      type="text"
                      name="interpreterLanguage"
                      value={newDeposition?.interpreterLanguage || ""}
                      placeholder="Freelancers coming soon..."
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>
                </Tab>
              )}

              {newDeposition.needVideographer && (
                <Tab
                  eventKey="needVideographer"
                  title={<FontAwesomeIcon icon={faVideo} />}
                >
                  <Form.Group
                    controlId="videographerDetails"
                    className="form-label-left"
                  >
                    <Form.Label>Videographer Details</Form.Label>
                    <Form.Control
                      type="text"
                      name="videographerDetails"
                      value={newDeposition?.videographerDetails || ""}
                      placeholder="Freelancers coming soon..."
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>
                </Tab>
              )}

              {newDeposition.needVideoTech && (
                <Tab
                  eventKey="needVideoTech"
                  title={<FontAwesomeIcon icon={faTools} />}
                >
                  <Form.Group
                    controlId="videoTechDetails"
                    className="form-label-left"
                  >
                    <Form.Label>Video Tech Details</Form.Label>
                    <Form.Control
                      type="text"
                      name="videoTechDetails"
                      value={newDeposition?.videoTechDetails || ""}
                      placeholder="Freelancers coming soon..."
                      onChange={handleNewInputChange}
                    />
                  </Form.Group>
                </Tab>
              )}
            </Tabs>
          )}
        </Col>
        <section style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleNewFormSubmit}
            style={{ width: "50%" }}
            className="mt-3 skeuomorphic-option-button"
            disabled={!isNewDepoFormValid()}
          >
            Create New Depo
          </Button>
        </section>
      </Row>
    </Form>
  );

  const renderDepositionCards = () => (
    <div className="mobile-card-view">
      {depositions.map((deposition) => (
        <div
          className="mobile-card"
          key={deposition.depositionId}
          onClick={() => handleDepositionClick(deposition)}
        >
          <h4>{deposition.caseName}</h4>
          <p>
            <strong>Depo ID:</strong> {deposition.depositionId}
          </p>
          <p>
            <strong>Depo Date:</strong> {deposition.depoDate}
          </p>
          <p>
            <strong>Depo Time:</strong> {deposition.depoTime}
          </p>
          <p>
            <strong>Duration:</strong> {deposition.expectedDuration}
          </p>
          <p>
            <strong>Case No:</strong> {deposition.caseNumber}
          </p>
          <p>
            <strong>Witness:</strong> {deposition.witnessName}
          </p>
          <p>
            <strong>Location:</strong> {deposition.locationAddress}
          </p>
          <p>
            <strong>Status:</strong> {deposition.status}
          </p>
        </div>
      ))}
    </div>
  );

  // Function to render the calendar and scheduled depositions
  const renderCalendar = () => (
    <div className="calendar-container">
      <Calendar
        className="skeuomorphic-element calendar-syle"
        onChange={handleSelectedDateChange}
        value={selectedDate}
        nextLabel=">"
        prevLabel="<"
        next2Label=">>"
        prev2Label="<<"
        showNeighboringMonth={false}
      />
      <div className="responsive-table">
        <Table striped bordered hover className="mt-3">
          <thead>
            <tr>
              <th>Depo ID</th>
              <th>Scheduled Date</th>
              <th>Case No.</th>
              <th>Case Name</th>
              <th>Location Address</th>
              <th>Depo Status</th>
            </tr>
          </thead>
          <tbody>
            {depositions.map((deposition) => (
              <tr
                className="table-row-depo"
                key={deposition.depositionId}
                onClick={() => handleDepositionClick(deposition)}
              >
                <td>{deposition.depositionId}</td>
                <td>{deposition.depoDate}</td>
                <td>{deposition.caseNumber}</td>
                <td>{deposition.caseName}</td>
                <td>{deposition.locationAddress}</td>
                <td>{deposition.status}</td>
              </tr>
            ))}
          </tbody>
        </Table>
      </div>
      {renderDepositionCards()}
    </div>
  );

  return (
    <Container fluid className="dashboard-container-ld">
      <Row>
        <Col>
          <Button
            className="skeuomorphic-option-button"
            onClick={handleAddDepositionClick}
            style={{ marginBottom: "1rem" }}
          >
            {showDepositionForm ? "Deposition Form" : "Schedule New Deposition"}
          </Button>
          <Button
            className="skeuomorphic-option-button"
            onClick={handleToggleCalendar}
            style={{ marginBottom: "1rem", marginLeft: "1rem" }}
          >
            {showCalendar ? "Calendar" : "Show Calendar"}
          </Button>
        </Col>
      </Row>

      {showDepositionForm && (
        <Row key="depositionForm">
          <Col>{renderDepositionForm()}</Col>
        </Row>
      )}

      {showCalendar && (
        <Row key="calendar" className="justify-content-center">
          <Col>{renderCalendar()}</Col>
        </Row>
      )}

      <Modal show={showDepoModal} onHide={handleCloseDepoModal}>
        <Modal.Header closeButton>
          <Modal.Title>Deposition Details</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedDeposition && (
            <>
              <Form className="skeuomorphic-element">
                <Row className="mb-1">
                  <Col>
                    <Form.Group
                      controlId="depositionId"
                      className="form-label-left"
                    >
                      <Form.Label>Job No</Form.Label>
                      <Form.Control
                        type="text"
                        name="depositionId"
                        defaultValue={selectedDeposition.depositionId}
                        onChange={handleSelectedInputChange}
                      />
                    </Form.Group>
                  </Col>
                  <Col>
                    <Form.Group controlId="depoDate">
                      <Form.Label>Depo Date</Form.Label>
                      <Form.Control
                        type="date"
                        name="depoDate"
                        defaultValue={
                          selectedDeposition.depoDate?.split(" ")[0]
                        }
                        onChange={handleSelectedInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <Row className="mb-3">
                  <Col>
                    <Form.Group controlId="depoTime">
                      <Form.Label>Depo Time</Form.Label>
                      <div className="">
                        <TimePicker
                          onChange={handleSelectedTimeChange}
                          value={selectedDeposition?.depoTime || ""}
                          format="hh:mm a" // Use 12-hour format with AM/PM
                          hourPlaceholder="hh"
                          minutePlaceholder="mm"
                          clearIcon={<FontAwesomeIcon icon={faTimes} />}
                          clockIcon={<FontAwesomeIcon icon={faClock} />}
                          minDetail="hour"
                          maxDetail="minute"
                          minuteAriaLabel="Minute"
                          hourAriaLabel="Hour"
                          className="time-picker"
                          name="depoTime"
                          amPmAriaLabel="Select AM/PM"
                          disableClock={false} // Enable clock for better visual representation
                          minTime="00:00"
                          maxTime="12:00"
                          step={15}
                          required={true}
                        />
                      </div>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col>
                    <Form.Group controlId="expectedDuration">
                      <Form.Label>Expected Length</Form.Label>
                      <Form.Control
                        type="number"
                        name="expectedDuration"
                        defaultValue={selectedDeposition.expectedDuration}
                        onChange={handleSelectedInputChange}
                      />
                    </Form.Group>
                  </Col>

                  <Col>
                    <Form.Group controlId="caseNumber">
                      <Form.Label>Case Number</Form.Label>
                      <Form.Control
                        type="text"
                        name="caseNumber"
                        defaultValue={selectedDeposition.caseNumber}
                        onChange={handleSelectedInputChange}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Form.Group controlId="caseName" style={{ marginTop: "1rem" }}>
                  <Form.Label>Case Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="caseName"
                    defaultValue={selectedDeposition.caseName}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group
                  controlId="witnessName"
                  style={{ marginTop: "1rem" }}
                >
                  <Form.Label>Witness Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="witnessName"
                    defaultValue={selectedDeposition.witnessName}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group
                  controlId="locationAddress"
                  style={{ marginTop: "1rem" }}
                >
                  <Form.Label>Location Address</Form.Label>
                  <Geosuggest
                    ref={geosuggestEl}
                    initialValue={
                      isNaN(selectedDeposition.locationAddress) ||
                      selectedDeposition.locationAddress.length > 0
                        ? selectedDeposition.locationAddress
                        : selectedDeposition.locationAddress
                    }
                    placeholder="Enter your Street Address!"
                    country="us"
                    onSuggestSelect={useSelectBillToSuggestion}
                    location={new google.maps.LatLng(40.0038731, -75.0679655)}
                    radius="5000000"
                    required={true}
                  />
                </Form.Group>

                <hr />

                <Form.Group controlId="expedite" className="form-label-left">
                  <Form.Check
                    type="checkbox"
                    label={
                      <>
                        Expedite the deposition{" "}
                        <OverlayTrigger
                          placement="top"
                          overlay={
                            <Tooltip id="tooltip-top">
                              Default turn around time is 10 days
                            </Tooltip>
                          }
                        >
                          <FontAwesomeIcon
                            className="ms-1"
                            icon={faInfoCircle}
                          />
                        </OverlayTrigger>
                      </>
                    }
                    name="expedite"
                    checked={selectedDeposition.expedite}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                {selectedDeposition.expedite && (
                  <Form.Group
                    controlId="expediteOptions"
                    className="form-label-left"
                  >
                    <Form.Label className="fw-bold">
                      Expedite Options
                    </Form.Label>
                    <section
                      style={{
                        display: "flex",
                        fontSize: ".72rem",
                        width: "100%",
                      }}
                    >
                      <Form.Check
                        className="ms-0 mb-0"
                        type="radio"
                        label="1 Day"
                        name="expediteDays"
                        value="1"
                        checked={selectedDeposition.expediteDays === "1"}
                        onChange={handleSelectedInputChange}
                      />
                      <Form.Check
                        className="ms-2 mb-0"
                        type="radio"
                        label="2 Days"
                        name="expediteDays"
                        value="2"
                        checked={selectedDeposition.expediteDays === "2"}
                        onChange={handleSelectedInputChange}
                      />
                      <Form.Check
                        className="ms-2 mb-0"
                        type="radio"
                        label="3 Days"
                        name="expediteDays"
                        value="3"
                        checked={selectedDeposition.expediteDays === "3"}
                        onChange={handleSelectedInputChange}
                      />
                      <Form.Check
                        className="ms-2 mb-0"
                        type="radio"
                        label="4 Days"
                        name="expediteDays"
                        value="4"
                        checked={selectedDeposition.expediteDays === "4"}
                        onChange={handleSelectedInputChange}
                      />
                      <Form.Check
                        className="ms-2 mb-0"
                        type="radio"
                        label="5 Days"
                        name="expediteDays"
                        value="5"
                        checked={selectedDeposition.expediteDays === "5"}
                        onChange={handleSelectedInputChange}
                      />
                    </section>
                  </Form.Group>
                )}

                <Form.Group controlId="nodUpload" style={{ marginTop: "1rem" }}>
                  <Form.Label>NOD (Notice of Deposition)</Form.Label>
                  <Form.Control
                    type="file"
                    name="nodUpload"
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group
                  controlId="needCourtReporter"
                  style={{ marginTop: "1rem" }}
                >
                  <Form.Check
                    type="checkbox"
                    label="Need Court Reporter"
                    name="needCourtReporter"
                    defaultChecked={selectedDeposition.needCourtReporter}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="needInterpreter">
                  <Form.Check
                    type="checkbox"
                    label="Need Interpreter"
                    name="needInterpreter"
                    defaultChecked={selectedDeposition.needInterpreter}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="needVideographer">
                  <Form.Check
                    type="checkbox"
                    label="Need Videographer"
                    name="needVideographer"
                    defaultChecked={selectedDeposition.needVideographer}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>

                <Form.Group controlId="needVideoTech">
                  <Form.Check
                    type="checkbox"
                    label="Need Video Tech"
                    name="needVideoTech"
                    defaultChecked={selectedDeposition.needVideoTech}
                    onChange={handleSelectedInputChange}
                  />
                </Form.Group>
              </Form>

              <Tabs
                activeKey={selectedActiveTab}
                onSelect={(k) => setSelectedActiveTab(k)}
                className="mt-3 mb-3"
              >
                {selectedDeposition.needCourtReporter && (
                  <Tab
                    eventKey="courtReporter"
                    title={<FontAwesomeIcon icon={faUserTie} />}
                  >
                    <div>
                      <FontAwesomeIcon icon={faUserTie} /> Court Reporter
                      details go here...
                    </div>
                  </Tab>
                )}

                {selectedDeposition.needInterpreter && (
                  <Tab
                    eventKey="interpreter"
                    title={<FontAwesomeIcon icon={faLanguage} />}
                  >
                    <div>
                      <FontAwesomeIcon icon={faLanguage} /> Interpreters coming
                      soon...
                    </div>
                  </Tab>
                )}

                {selectedDeposition.needVideographer && (
                  <Tab
                    eventKey="videographer"
                    title={<FontAwesomeIcon icon={faVideo} />}
                  >
                    <div>
                      <FontAwesomeIcon icon={faVideo} /> Videographers coming
                      soon...
                    </div>
                  </Tab>
                )}

                {selectedDeposition.needVideoTech && (
                  <Tab
                    eventKey="videoTech"
                    title={<FontAwesomeIcon icon={faTools} />}
                  >
                    <div>
                      <FontAwesomeIcon icon={faTools} /> Video Techs coming
                      soon...
                    </div>
                  </Tab>
                )}
              </Tabs>
            </>
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button
            variant="secondary"
            className="skeuomorphic-option-button"
            onClick={handleCloseDepoModal}
          >
            Close
          </Button>

          <Button
            variant="primary"
            className="skeuomorphic-option-button"
            onClick={handleSelectedFormSubmit}
          >
            Save Changes
          </Button>
        </Modal.Footer>
      </Modal>
    </Container>
  );
};
