import React, { useRef, useState, useContext } from "react";
import Accordion from "react-bootstrap/Accordion";
import Form from "react-bootstrap/Form";
import Card from "react-bootstrap/Card";
import Button from "react-bootstrap/Button";
import { useNavigate } from "react-router-dom";
import { LoaderButton, LoaderButtonPlus } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import config from "../../config";
import "./NewSalesLead.css";
import { ApplicationContext } from "../../context/GlobalState";
import {
  userHasAuthenticated,
  setUserEmail,
  updateUser,
} from "../../context/actions";

export default function NewSalesLead() {
  const [state, dispatch] = useContext(ApplicationContext);
  const file = useRef(null);
  const nav = useNavigate();
  const [content, setContent] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  const [campaigns, setCampaigns] = useState([
    {
      advertiserName: "",
      startDate: "",
      endDate: "",
      budget: "",
      pricingModel: "",
      audienceAge: "",
      audienceGender: "",
      audienceIncomeBracket: "",
      interestCategories: [],
      geoFences: [],
      geoTargets: [],
      redirectURL: "",
      mediaCreatives: [],
    },
  ]);
  let [idx, setI] = useState(0);

  const validateForm = (i) => {
    console.log("YOOOOO: ", campaigns[i]);
    return (
      campaigns[i].advertiserName.length > 0 //&&
      //campaigns[i].startDate.length > 0
    );
  };

  console.log("IO Test: ", state);

  const handleFileChange = (event) => {
    file.current = event.target.files[0];
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (file.current && file.current.size > config.MAX_ATTACHMENT_SIZE) {
      alert(
        `Please pick a file smaller than ${
          config.MAX_ATTACHMENT_SIZE / 1000000
        } MB.`
      );
      return;
    }

    setIsLoading(true);
  };

  const handleChange = (e, index) => {
    const { name, value } = e.target;
    const newCampaigns = [...campaigns];
    newCampaigns[index][name] = value;
    setCampaigns(newCampaigns);
  };

  const handleAdd = () => {
    setCampaigns([
      ...campaigns,
      {
        advertiserName: "",
        startDate: "",
        endDate: "",
        budget: "",
        pricingModel: "",
        audienceAge: "",
        audienceGender: "",
        audienceIncomeBracket: "",
        interestCategories: [],
        geoFences: [],
        geoTargets: [],
        redirectURL: "",
        mediaCreatives: [],
      },
    ]);
    setI(idx++);
  };

  return (
    <>
      <Main>
        <article className="NewSalesLead">
          <h4>Create new insertion order</h4>
          <p style={{ fontSize: ".81rem", marginTop: ".72rem" }}>
            Create your campaigns and run your ads against a predefined budget
            and ad spend objectives.
          </p>

          <Accordion defaultActiveKey="0" flush>
            {campaigns.map((campaign, index) => (
              <Accordion.Item eventKey={index} key={index}>
                <Accordion.Header>Campaign {index + 1}</Accordion.Header>

                <Accordion.Body>
                  <Form>
                    <Form.Group>
                      <Form.Label>Campaign Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="advertiserName"
                        value={campaign.advertiserName}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Campaign Pricing Model</Form.Label>
                      <Form.Control
                        type="text"
                        name="pricingModel"
                        value={campaign.pricingModel}
                        onChange={(e) => handleChange(e, index)}
                      />
                    </Form.Group>
                  </Form>
                </Accordion.Body>
              </Accordion.Item>
            ))}

            <section className="add-campaign-btn">
              <LoaderButtonPlus
                block="true"
                size="lg"
                type="button"
                variant="success"
                onClick={() => {
                  handleAdd();
                }}
                isLoading={isLoading}
                disabled={!validateForm(idx)}
              >
                Add Campaign
              </LoaderButtonPlus>
            </section>
          </Accordion>

          <Form onSubmit={handleSubmit}>
            <Form.Group controlId="content">
              <Form.Control
                value={content}
                as="textarea"
                onChange={(e) => setContent(e.target.value)}
                className="nucleus-form-fields"
              />
            </Form.Group>

            <Form.Group
              controlId="file"
              className="new-sales-order-file-upload-group"
            >
              <Form.Label>Attachment</Form.Label>
              <Form.Control
                onChange={handleFileChange}
                className="nucleus-form-fields"
                type="file"
              />
            </Form.Group>
          </Form>
        </article>
      </Main>
    </>
  );
}
