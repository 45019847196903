import { useRef, useContext, useEffect, useState } from "react";
import { ApplicationContext } from "./context/GlobalState";
import {
  userHasAuthenticated,
  setLoadingBar,
  setUserEmail,
  updateUser,
  setUserOrganizationId,
  setFirstName,
  setLastName,
  setUserCellPhone,
  setCorporateName,
  setUserBillingAddress,
  setOrgOfficePhone,
  setUserAccountStatus,
  setBodyOverflow,
} from "./context/actions";
import { LoadingBar } from "./components/LoadingBar";
import { Auth, API } from "aws-amplify";
import Router from "./routes/Routes";

import { getUserRecordAPI, getOrganizationData } from "./libs/apiService";
import GeoLocation from "./components/GeoLocation";
import InstallPrompt from "./components/InstallPrompt";
import IOSInstallPrompt from "./components/IOSInstallPrompt";
import { isIOS, isSafari } from "./libs/utilities";
//import logo from "./logo.svg";
//import Home from "./containers/Home";
import "./App.css";

const App = () => {
  // on compponent did mount/change/etc
  const loadingBarRef = useRef(null);
  const [state, dispatch] = useContext(ApplicationContext);
  const [isAuthenticating, setIsAuthenticating] = useState(true);
  const [loading, setLoading] = useState(false);
  const { barRef, isAuthenticated } = state;
  const { isModalOpen } = state;
  const [isInstalled, setIsInstalled] = useState(false);

  useEffect(() => {
    const checkInstallation = () => {
      if (
        window.matchMedia("(display-mode: standalone)").matches ||
        window.navigator.standalone
      ) {
        setIsInstalled(true);
      }
    };

    window.addEventListener("load", checkInstallation);
    return () => window.removeEventListener("load", checkInstallation);
  }, []);

  // clean up state on modal open and close actiosn throughout app
  useEffect(() => {
    // Function to enable body scroll
    const enableBodyScroll = () => {
      document.body.style.overflow = "auto";
    };

    // Function to disable body scroll
    const disableBodyScroll = () => {
      document.body.style.overflow = "hidden";
    };

    // Listen for changes in the modal's visibility
    if (isModalOpen) {
      disableBodyScroll();
      dispatch(setBodyOverflow("hidden"));
    } else {
      enableBodyScroll();
      dispatch(setBodyOverflow("auto"));
    }
    /*
    // Clean-up function to ensure body scroll is enabled when the component unmounts
    return () => {
      enableBodyScroll();
      dispatch(setBodyOverflow("auto"));
    };
    */
  }, [isModalOpen]); // Depend on the modal's visibility state

  useEffect(() => {
    document.body.style.overflow = state.bodyOverflow;
  }, [state.bodyOverflow]);

  /**
   *  @function authenticateOnLoad
   *
   *  function gets user's authenticated session to
   *  persist login session to application state
   *
   **/
  const authenticateOnLoad = async () => {
    setLoading(true);
    try {
      let sesh = await Auth.currentSession();
      console.log("App Session: ", sesh);

      console.log(
        "App Session - organizationId: ",
        sesh.idToken.payload["custom:organizationId"]
      );
      console.log("App Session - userRecordId: ", sesh.idToken.payload["sub"]);
      console.log(
        "App Session - userRole: ",
        sesh.idToken.payload["custom:userRole"]
      );

      console.log("App Session - email: ", sesh.idToken.payload.email);
      console.log(
        "App Session - verified email: ",
        sesh.idToken.payload.email_verified
      );

      dispatch(userHasAuthenticated(true));
      dispatch(setUserEmail(sesh.idToken.payload.email));

      // FIXME: REDUNDANT BE SURE TO USE FROM USER OBJECT
      dispatch(
        setUserOrganizationId(sesh.idToken.payload["custom:organizationId"])
      );

      dispatch(
        updateUser({
          userRole: sesh.idToken.payload["custom:userRole"],
          organizationId: sesh.idToken.payload["custom:organizationId"],
          userRecordId: sesh.idToken.payload["sub"],
          verifiedEmail: sesh.idToken.payload.email_verified,
        })
      );

      console.log("onLoad fxn: ", state);
    } catch (e) {
      //
      if (e !== "No current user") {
        alert(e);
      }
    }

    setIsAuthenticating(false);
    setLoading(false);
  };

  /**
   *
   * @function fetchUserData
   *
   * this function gets user record data and updates state
   * to persist data throughout the app
   *
   **/
  const fetchUserData = async () => {
    setLoading(true);
    if (!isAuthenticated) return;
    try {
      let userData = await getUserRecordAPI(
        state.user.organizationId,
        state.user.userRecordId
      );

      console.log("[AUDIT] - USER DATA2: ", userData);
      dispatch(setFirstName(userData.firstName));
      dispatch(setLastName(userData.lastName));
      dispatch(setUserCellPhone(userData.cellPhone));
      dispatch(setUserAccountStatus(userData.accountStatus));
      dispatch(
        updateUser({ ...state.user, smsApproved: userData.smsApproved })
      );

      setLoading(false);
    } catch (err) {
      console.log("[ERROR] - USER DATA: ", err);

      setLoading(false);
    }
  };

  const fetchOrgData = async () => {
    setLoading(true);
    if (!isAuthenticated) return;
    try {
      let orgData = await getOrganizationData(state.user.organizationId);
      console.log("[AUDIT] - ORG DATA1: ", orgData);

      dispatch(setCorporateName(orgData.organizationName));
      dispatch(setOrgOfficePhone(orgData.servicePhone));

      let billToAddress = {
        streetAddress: orgData.locations[0].streetAddress,
        streetAddressLine2: orgData.locations[0].streetAddressLine2
          ? orgData.locations[0].streetAddressLine2
          : "",
        city: orgData.locations[0].city,
        stateOf: orgData.locations[0].stateOf,
        zipCode: orgData.locations[0].zipCode,
        country: orgData.locations[0].country,
      };

      dispatch(setUserBillingAddress(billToAddress));

      setLoading(false);
    } catch (err) {
      console.log("[ERROR] - ORG DATA: ", err);

      setLoading(false);
    }
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (!barRef) dispatch(setLoadingBar(loadingBarRef));
  }, [barRef]);

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  useEffect(() => {
    console.log("./App Auth? ", isAuthenticated);
    try {
      // NOTE: Checking user session here
      authenticateOnLoad();
    } catch (e) {
      //
      console.log("ERROR: ", e);
    }
  }, []);

  useEffect(() => {
    console.log("TEST ./App Auth2? ", isAuthenticated);
    try {
      // NOTE: GET & PERSIST User Data here
      fetchUserData();
      fetchOrgData();
    } catch (e) {
      //
      console.log("ERROR: ", e);
    }
  }, [isAuthenticated]);

  useEffect(() => {
    console.log("CURR_STATE2: ", state);
  }, [state]);

  return (
    !isAuthenticating && (
      <div className="App">
        <LoadingBar ref={loadingBarRef} />
        {!isInstalled && !isSafari() && <InstallPrompt />}
        {!isInstalled && isSafari() && <IOSInstallPrompt />}
        <GeoLocation />
        <Router />
      </div>
    )
  );
};

export { App };
