import React from "react";
import Media from "react-media";
import hero from "../../assets/hero-img-1@2x.png";
import hero2 from "../../assets/hero-img-2@2x.png";
import hero3 from "../../assets/hero-img-3@2x.png";
import check from "../../assets/icon-1@2x.png";
import blueCheck from "../../assets/icon-4@2x.png";
import greenCheck from "../../assets/icon-25@2x.png";
import shieldCheck from "../../assets/frame-41-1@2x.png";
import shieldCheck2 from "../../assets/frame-41-3@2x.png";
import decor from "../../assets/work-decoartion-1@2x.png";
import faq_decor from "../../assets/faq-decoration-1@2x.png";
import faq_decor2 from "../../assets/faq-decoration-2@2x.png";
import faq_star from "../../assets/faq-star-1@2x.png";
import calendar from "../../assets/schedule-1@2x.png";
import calendar2 from "../../assets/schedule-2@2x.png";
import calendar3 from "../../assets/schedule-3@2x.png";
import jobsAvailable from "../../assets/work-1@2x.png";
import jobsAvailable2 from "../../assets/work-2@2x.png";
import jobsAvailable3 from "../../assets/work-3@2x.png";
import techProcess from "../../assets/uit-process-1@2x.png";
import techProcess2 from "../../assets/uit-process-3@2x.png";
import timeProgress from "../../assets/lets-icons-time-progress-light-2@2x.png";
import timeProgress2 from "../../assets/lets-icons-time-progress-light-3@2x.png";
import aboutImg from "../../assets/about-img-1@2x.png";
import aboutImg2 from "../../assets/about-img-2@2x.png";
import aboutImg3 from "../../assets/about-img-3@2x.png";
import webBrowserPWA from "../../assets/web-browser.svg";
import checkPlus from "../../assets/icon-6@2x.png";
import checkMinus from "../../assets/icon-7@2x.png";
import logoSubmenu from "../../assets/logo-1@2x.png";
import logoSubmenu2 from "../../assets/logo-4@2x.png";
import facebookLogo from "../../assets/icon-11@2x.png";
import linkedInLogo from "../../assets/icon-12@2x.png";
import XLogo from "../../assets/icon-13@2x.png";
import emailIcon from "../../assets/icon-34@2x.png";
import phoneIcon from "../../assets/icon-35@2x.png";
import locationIcon from "../../assets/icon-16@2x.png";
import "./LandingPage.css";

export const LandingPage = () => {
    return (
        <div className="landing-page">
            <Media
                query="(max-width: 767px)"
                render={() => (
                    <div className="sm-landing">
                        <div className="div" style={{ marginBottom: "6rem" }}>
                            <div className="hero">
                                <div className="row">
                                    <div className="col">
                                        <div className="hero-img">
                                            <div className="overlap-group">
                                                <div className="hero-block">
                                                    <div className="div-2" />
                                                </div>
                                                <img
                                                    className="img"
                                                    alt="Hero img"
                                                    src={hero}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="group-wrapper">
                                        <div className="group">
                                            <div className="text">
                                                <p className="span-wrapper">
                                                    <span className="text-wrapper">
                                                        Deposition Services
                                                        Scheduling Made Easy
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="div-wrapper">
                                                <p className="p">
                                                    <span className="span">
                                                        Streamline your legal
                                                        workflow with Depo App!
                                                        Connect with deposition
                                                        freelancers and schedule
                                                        deposition services.
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-2">
                                                <div className="frame">
                                                    <img
                                                        className="icon"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element">
                                                    <p className="text-3">
                                                        <span className="text-wrapper-2">
                                                            Save Money Going
                                                            Direct
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-4">
                                                <p className="text-5">
                                                    <span className="span">
                                                        The Depo App can provide
                                                        an additional source of
                                                        revenue for deposition
                                                        freelancers.
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-6">
                                                <div className="frame">
                                                    <img
                                                        className="icon"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element">
                                                    <p className="text-3">
                                                        <span className="text-wrapper-2">
                                                            Tools for Success
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-7">
                                                <p className="text-8">
                                                    <span className="span">
                                                        Get services such as
                                                        document recovery, case
                                                        and witness deposition
                                                        financial analytics,
                                                        archived recordings, and
                                                        find different types of
                                                        deposition support
                                                        freelancers
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-9">
                                                <div className="frame">
                                                    <img
                                                        className="icon"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element">
                                                    <p className="text-3">
                                                        <span className="text-wrapper-2">
                                                            Earn More
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-10">
                                                <p className="text-5">
                                                    <span className="span">
                                                        The Depo App can provide
                                                        an additional source of
                                                        revenue for deposition
                                                        freelancers.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="work">
                                <div className="overlap">
                                    <div className="container">
                                        <div className="text-11">
                                            <p className="text-12">
                                                <span className="text-wrapper-3">
                                                    Your best work happens in
                                                    Depo App
                                                </span>
                                            </p>
                                        </div>
                                        <div className="frame-2">
                                            <div className="card">
                                                <div className="overlap-wrapper">
                                                    <div className="overlap-2">
                                                        <div className="card-bg" />
                                                        <div className="schedule-elipse" />
                                                        <div className="overlap-group-wrapper">
                                                            <div className="overlap-group-2">
                                                                <div className="text-13">
                                                                    <p className="text-14">
                                                                        <span className="text-wrapper-4">
                                                                            Lawyers
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="text-15">
                                                                    <p className="text-16">
                                                                        <span className="text-wrapper-5">
                                                                            Schedule
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="button">
                                                                    <div className="btn">
                                                                        <img
                                                                            className="icon-2"
                                                                            alt="Icon"
                                                                            src={
                                                                                blueCheck
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="schedule"
                                                            alt="Schedule"
                                                            src={calendar}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-2">
                                                <div className="group-2">
                                                    <div className="overlap-2">
                                                        <div className="card-bg-2" />
                                                        <div className="work-elipse" />
                                                        <div className="overlap-group-wrapper">
                                                            <div className="overlap-group-2">
                                                                <div className="text-13">
                                                                    <p className="text-17">
                                                                        <span className="text-wrapper-6">
                                                                            Freelancers
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="text-15">
                                                                    <p className="text-18">
                                                                        <span className="text-wrapper-7">
                                                                            Work
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                                <div className="button">
                                                                    <div className="btn">
                                                                        <img
                                                                            className="icon-2"
                                                                            alt="Icon"
                                                                            src={
                                                                                greenCheck
                                                                            }
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="work-2"
                                                            alt="Work"
                                                            src={jobsAvailable}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-2">
                                            <div className="element-wrapper">
                                                <div className="element-2">
                                                    <p className="text-19">
                                                        <span className="text-wrapper-8">
                                                            The Depo App will
                                                            become the place for
                                                            your team to easily
                                                            manage dedicated
                                                            litigation and trial
                                                            support specialists
                                                            to ensure that you
                                                            receive what you
                                                            need on timeand with
                                                            precise attention to
                                                            detail. Our goal is
                                                            to handle the
                                                            technology so that
                                                            you can focus your
                                                            attention on winning
                                                            your case.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="frame-wrapper">
                                            <div className="frame-3">
                                                <div className="text-20">
                                                    <p className="text-21">
                                                        <span className="text-wrapper-9">
                                                            Get Started Today
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="features">
                                <div className="container-3">
                                    <div className="title">
                                        <p className="text-22">
                                            <span className="text-wrapper">
                                                Discover The Features
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row-2">
                                        <div className="col-2">
                                            <div className="frame-wrapper-2">
                                                <div className="frame-4">
                                                    <div className="overlap-group-3">
                                                        <img
                                                            className="img-3"
                                                            alt="Uit process"
                                                            src={techProcess}
                                                        />
                                                        <div className="ellipse" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-3">
                                                <p className="text-23">
                                                    <span className="text-wrapper-2">
                                                        Fast and simple
                                                        scheduling and billing
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-4">
                                                <p className="text-24">
                                                    <span className="span">
                                                        Our patent pending
                                                        scheduling algorithm
                                                        will allow law firms and
                                                        lawyers to savemoney on
                                                        deposition transcripts
                                                        and allow our court
                                                        reporters to optimize
                                                        their availabilityand
                                                        earn money. It&#39;s a
                                                        win-win for everyone!
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-3">
                                            <div className="frame-wrapper-2">
                                                <div className="frame-4">
                                                    <div className="overlap-group-3">
                                                        <img
                                                            className="img-3"
                                                            alt="Frame"
                                                            src={shieldCheck}
                                                        />
                                                        <div className="ellipse" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-3">
                                                <p className="text-23">
                                                    <span className="text-wrapper-2">
                                                        Your data is backed up
                                                        safely and securely
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-5">
                                                <p className="text-25">
                                                    <span className="span">
                                                        Lawyers benefit by
                                                        dealing directly with
                                                        deposition freelancers
                                                        so less people
                                                        are interactingwith
                                                        chain of custody legal
                                                        documents.The Depo App
                                                        can also provide backup
                                                        and documentrecover
                                                        services with a 1-2-3
                                                        backup philosophy in
                                                        place to protect
                                                        testimony and exhibits.
                                                        We use top of the line
                                                        data security to protect
                                                        and preserve
                                                        confidentiality.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-4">
                                            <div className="frame-wrapper-2">
                                                <div className="frame-4">
                                                    <div className="overlap-group-3">
                                                        <img
                                                            className="img-3"
                                                            alt="Lets icons time"
                                                            src={timeProgress}
                                                        />
                                                        <div className="ellipse" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-3">
                                                <p className="text-23">
                                                    <span className="text-wrapper-2">
                                                        Just in time deposition
                                                        support freelancers
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-4">
                                                <p className="text-24">
                                                    <span className="span">
                                                        Court Reporters that
                                                        sign up to our platform
                                                        are available at the
                                                        last minute to
                                                        fulfill emergencydepositions.
                                                        The patent pending
                                                        scheduling algorithm
                                                        provides freelancers at
                                                        a moments notice.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about">
                                <div className="row-3">
                                    <div className="col-5">
                                        <div className="frame-5">
                                            <div className="text-26">
                                                <p className="text-27">
                                                    <span className="text-wrapper">
                                                        About Us
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-6">
                                                <p className="text-28">
                                                    <span className="span">
                                                        The Depo App was created
                                                        because we wanted to
                                                        normalize the random
                                                        nature of depositions.
                                                        Lawyers and court
                                                        reportersface timing
                                                        challenges scheduling
                                                        depositions and this
                                                        in-efficiency costs
                                                        everybody money. We came
                                                        up with a
                                                        proprietarypatent
                                                        pending scheduling
                                                        algorithm that better
                                                        manages the logistics so
                                                        lawyers can save money
                                                        and court reporters
                                                        canoptimize their
                                                        earning capacity.
                                                    </span>
                                                </p>
                                            </div>
                                            <button className="button-2">
                                                <div className="frame-6">
                                                    <div className="text-29">
                                                        <p className="text-21">
                                                            <span className="text-wrapper-9">
                                                                Learn About Us
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="about-img-wrapper">
                                        <div className="about-img">
                                            <div className="overlap-group">
                                                <div className="about-block">
                                                    <div className="div-2" />
                                                </div>
                                                <img
                                                    className="about-img-2"
                                                    alt="About img"
                                                    src={aboutImg}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="download">
                                <div className="gorup-wrapper">
                                    <div className="gorup">
                                        <div className="frame-7">
                                            <div className="text-30">
                                                <p className="text-31">
                                                    <span className="span">
                                                        Join Professionals
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-32">
                                                <p className="text-33">
                                                    <span className="text-wrapper">
                                                        Download The App!
                                                    </span>
                                                </p>
                                            </div>
                                            <img
                                                className="frame-8"
                                                alt="Frame"
                                                style={{
                                                    height: "6rem",
                                                    marginTop: "2rem",
                                                }}
                                                src={webBrowserPWA} // TODO: Need to use this to deeplink to PWA install
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq">
                                <div className="overlap-3">
                                    <img
                                        className="img-2"
                                        alt="Faq decoration"
                                        src={faq_decor}
                                    />
                                    <div className="container-4">
                                        <div className="text-11">
                                            <p className="text-12">
                                                <span className="text-wrapper-3">
                                                    Frequently Asked Questions
                                                </span>
                                            </p>
                                        </div>
                                        <div className="accordion">
                                            <div className="group-3">
                                                <div className="accordion-2">
                                                    <div className="frame-9">
                                                        <div className="text-34">
                                                            <p className="text-35">
                                                                <span className="span">
                                                                    At volutpat
                                                                    fringilla
                                                                    nullam
                                                                    tempor neque
                                                                    gravida.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-3"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-3">
                                                    <div className="frame-10">
                                                        <div className="text-34">
                                                            <p className="text-35">
                                                                <span className="span">
                                                                    Tempor a
                                                                    diam
                                                                    volutpat
                                                                    condimentum
                                                                    leo neque
                                                                    aliquet
                                                                    adipiscing.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-3"
                                                            alt="Icon"
                                                            src={checkMinus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-4">
                                                    <div className="frame-11">
                                                        <div className="text-36">
                                                            <p className="text-37">
                                                                <span className="span">
                                                                    The Depo App
                                                                    was created
                                                                    because we
                                                                    wanted to
                                                                    normalize
                                                                    the random
                                                                    nature of
                                                                    depositions.
                                                                    Lawyers and
                                                                    court
                                                                    reportersface
                                                                    timing
                                                                    challenges
                                                                    scheduling
                                                                    depositions
                                                                    and this
                                                                    in-efficiency
                                                                    costs
                                                                    everybody
                                                                    money. We
                                                                    came up with
                                                                    a
                                                                    proprietarypatent
                                                                    pending
                                                                    scheduling
                                                                    algorithm
                                                                    that better
                                                                    manages the
                                                                    logistics so
                                                                    lawyers can
                                                                    save money
                                                                    and court
                                                                    reporters
                                                                    canoptimize
                                                                    their
                                                                    earning
                                                                    capacity.
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-title">
                                                    <div className="frame-10">
                                                        <div className="text-34">
                                                            <p className="text-35">
                                                                <span className="span">
                                                                    Semper
                                                                    aliquam nibh
                                                                    dictumst
                                                                    aliquam
                                                                    ullamcorper
                                                                    volutpat.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-3"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-2">
                                                    <div className="frame-10">
                                                        <div className="text-34">
                                                            <p className="text-35">
                                                                <span className="span">
                                                                    Ligula amet
                                                                    sit purus
                                                                    commodo in
                                                                    nulla
                                                                    senectus
                                                                    nulla.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-3"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-3">
                                                    <div className="frame-12">
                                                        <div className="text-34">
                                                            <p className="text-35">
                                                                <span className="span">
                                                                    Aliquam
                                                                    lorem
                                                                    feugiat
                                                                    iaculis
                                                                    feugiat
                                                                    risus vitae
                                                                    pharetra.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-3"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cta">
                                            <div className="container-5">
                                                <div className="faw-elipse" />
                                                <div className="text-38">
                                                    <p className="text-39">
                                                        <span className="text-wrapper-10">
                                                            Still have question?
                                                        </span>
                                                    </p>
                                                </div>
                                                <button className="button-3">
                                                    <div className="frame-13">
                                                        <div className="text-40">
                                                            <p className="text-41">
                                                                <span className="text-wrapper-11">
                                                                    Contact Us
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </button>
                                                <img
                                                    className="faq-star"
                                                    alt="Faq star"
                                                    src={faq_star}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer className="footer">
                                <div className="container-6">
                                    <div className="group-4">
                                        <img
                                            className="logo"
                                            alt="Logo"
                                            src={logoSubmenu}
                                        />
                                        <div className="frame-14">
                                            <div className="btn-wrapper">
                                                <div className="icon-wrapper">
                                                    <img
                                                        className="icon-2"
                                                        alt="Icon"
                                                        src={facebookLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-15">
                                                <div className="icon-wrapper">
                                                    <img
                                                        className="icon-2"
                                                        alt="Icon"
                                                        src={linkedInLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-16">
                                                <div className="icon-wrapper">
                                                    <img
                                                        className="icon-2"
                                                        alt="Icon"
                                                        src={XLogo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-4">
                                        <div className="col-6">
                                            <div className="frame-17">
                                                <div className="item">
                                                    <img
                                                        className="icon-4"
                                                        alt="Icon"
                                                        src={emailIcon}
                                                    />
                                                    <button className="button-4">
                                                        <div className="frame-18">
                                                            <div
                                                                className="text-42"
                                                                style={{
                                                                    textAlign:
                                                                        "start",
                                                                }}
                                                            >
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        rl@thedepoappcompany.com
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-19">
                                                    <img
                                                        className="icon-4"
                                                        alt="Icon"
                                                        src={phoneIcon}
                                                    />
                                                    <button className="button-5">
                                                        <div className="frame-18">
                                                            <div className="text-44">
                                                                <p
                                                                    className="text-43"
                                                                    style={{
                                                                        textAlign:
                                                                            "start",
                                                                    }}
                                                                >
                                                                    <span className="span">
                                                                        +267-246-8849
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-20">
                                                    <img
                                                        className="icon-5"
                                                        alt="Icon"
                                                        src={locationIcon}
                                                    />
                                                    <div className="button-6">
                                                        <div className="frame-21">
                                                            <div className="text-45">
                                                                <p
                                                                    className="text-46"
                                                                    style={{
                                                                        textAlign:
                                                                            "start",
                                                                    }}
                                                                >
                                                                    <span className="span">
                                                                        PO Box
                                                                        11344
                                                                        Philadelphia,
                                                                        pa,
                                                                        19137
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-7">
                                                <p className="text-47">
                                                    <span className="text-wrapper-8">
                                                        We are in beta if you
                                                        encounter any
                                                        emergencies call us
                                                        directly at
                                                        +267-246-8849 or fill in
                                                        the form with details
                                                        and we will contact you
                                                        within 48 hours
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="row-wrapper">
                                            <div className="row-5">
                                                <div className="col-7">
                                                    <div
                                                        className="text-48"
                                                        style={{
                                                            textAlign: "start",
                                                        }}
                                                    >
                                                        <p className="text-49">
                                                            <span className="text-wrapper-12">
                                                                App
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-7">
                                                        <div className="frame-18">
                                                            <div className="text-50">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Home
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-8">
                                                        <div className="frame-18">
                                                            <div className="text-51">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Our App
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-9">
                                                        <div className="frame-18">
                                                            <div className="text-52">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Lawyers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-10">
                                                        <div className="frame-18">
                                                            <div className="text-53">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Freelancers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-8">
                                                    <div
                                                        className="text-48"
                                                        style={{
                                                            textAlign: "start",
                                                        }}
                                                    >
                                                        <p className="text-49">
                                                            <span className="text-wrapper-12">
                                                                Company
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-11">
                                                        <div className="frame-18">
                                                            <div className="text-54">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-12">
                                                        <div className="frame-18">
                                                            <div className="text-54">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-13">
                                                        <div className="frame-18">
                                                            <div className="text-55">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Contact
                                                                        Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-14">
                                                        <div className="frame-18">
                                                            <div className="text-56">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Tech
                                                                        Support
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-9">
                                                    <div
                                                        className="text-48"
                                                        style={{
                                                            textAlign: "start",
                                                        }}
                                                    >
                                                        <p className="text-49">
                                                            <span className="text-wrapper-12">
                                                                Download
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-15">
                                                        <div className="frame-18">
                                                            <div className="text-57">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        Google
                                                                        Play
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-16">
                                                        <div className="frame-18">
                                                            <div className="text-58">
                                                                <p className="text-43">
                                                                    <span className="span">
                                                                        App
                                                                        Store
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright">
                                        <div className="text-59">
                                            <p className="text-60">
                                                <span className="text-wrapper-8">
                                                    Apple logo and App Store are
                                                    trademarks of Apple Inc.,
                                                    registered in the U.S. and
                                                    other countries. Google Play
                                                    and the Google Play logo are
                                                    trademarks of Google LLC.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                )}
            />

            <Media
                query="(min-width: 768px) and (max-width: 1439px)"
                render={() => (
                    <div className="md-landing">
                        <div className="div-3" style={{ marginBottom: "6rem" }}>
                            <div className="hero-2">
                                <div className="row-6">
                                    <div className="hero-img-wrapper">
                                        <div className="hero-img-2">
                                            <div className="overlap-group-4">
                                                <div className="hero-elipse-wrapper">
                                                    <div className="div-4" />
                                                </div>
                                                <img
                                                    className="hero-img-3"
                                                    alt="Hero img"
                                                    src={hero2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-10">
                                        <div className="group-5">
                                            <div className="text-63">
                                                <p className="text-64">
                                                    <span className="text-wrapper-13">
                                                        Deposition Services
                                                        Scheduling Made Easy
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-65">
                                                <p className="text-66">
                                                    <span className="text-wrapper-14">
                                                        Streamline your legal
                                                        workflow with Depo App!
                                                        Connect with deposition
                                                        freelancers and schedule
                                                        deposition services.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="frame-22">
                                    <div className="frame-23">
                                        <div className="text-67">
                                            <div className="img-wrapper">
                                                <img
                                                    className="icon-6"
                                                    alt="Icon"
                                                    src={check}
                                                />
                                            </div>
                                            <div className="element-8">
                                                <p className="text-68">
                                                    <span className="text-wrapper-15">
                                                        Save Money Going Direct
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-69">
                                            <p className="text-70">
                                                <span className="text-wrapper-14">
                                                    The Depo App can provide an
                                                    additional source of revenue
                                                    for deposition freelancers.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="frame-24">
                                        <div className="text-71">
                                            <div className="img-wrapper">
                                                <img
                                                    className="icon-6"
                                                    alt="Icon"
                                                    src={check}
                                                />
                                            </div>
                                            <div className="element-9">
                                                <p className="text-72">
                                                    <span className="text-wrapper-15">
                                                        Tools for Success
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-73">
                                            <p className="text-74">
                                                <span className="text-wrapper-14">
                                                    Get services such as
                                                    document recovery, case and
                                                    witness deposition financial
                                                    analytics, archived
                                                    recordings, and find
                                                    different types of
                                                    deposition support
                                                    freelancers
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                    <div className="frame-25">
                                        <div className="text-71">
                                            <div className="img-wrapper">
                                                <img
                                                    className="icon-6"
                                                    alt="Icon"
                                                    src={check}
                                                />
                                            </div>
                                            <div className="element-9">
                                                <p className="text-72">
                                                    <span className="text-wrapper-15">
                                                        Earn More
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="text-75">
                                            <p className="text-70">
                                                <span className="text-wrapper-14">
                                                    The Depo App can provide an
                                                    additional source of revenue
                                                    for deposition freelancers.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="work-3">
                                <div className="overlap-4">
                                    <div className="container-9">
                                        <div className="text-84">
                                            <p className="text-85">
                                                <span className="text-wrapper-18">
                                                    Your best work happens in
                                                    Depo App
                                                </span>
                                            </p>
                                        </div>
                                        <div className="frame-35">
                                            <div className="card-3">
                                                <div className="group-6">
                                                    <div className="overlap-group-5">
                                                        <div className="card-bg-3" />
                                                        <div className="schedule-elipse-2" />
                                                        <div className="group-7">
                                                            <div className="text-86">
                                                                <p className="text-87">
                                                                    <span className="text-wrapper-19">
                                                                        Lawyers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="text-88">
                                                                <p className="text-89">
                                                                    <span className="text-wrapper-20">
                                                                        Schedule
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="img-4"
                                                            alt="Schedule"
                                                            src={calendar2}
                                                        />
                                                        <div className="button-20">
                                                            <div className="btn-2">
                                                                <img
                                                                    className="icon-9"
                                                                    alt="Icon"
                                                                    src={
                                                                        blueCheck
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-4">
                                                <div className="group-8">
                                                    <div className="overlap-group-5">
                                                        <div className="card-bg-4" />
                                                        <div className="work-elipse-2" />
                                                        <div className="group-7">
                                                            <div className="text-86">
                                                                <p className="text-90">
                                                                    <span className="text-wrapper-21">
                                                                        Freelancers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="text-88">
                                                                <p className="text-91">
                                                                    <span className="text-wrapper-22">
                                                                        Work
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="img-4"
                                                            alt="Work"
                                                            src={jobsAvailable2}
                                                        />
                                                        <div className="button-20">
                                                            <div className="btn-2">
                                                                <img
                                                                    className="icon-9"
                                                                    alt="Icon"
                                                                    src={
                                                                        greenCheck
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-10">
                                            <div className="group-9">
                                                <div className="element-10">
                                                    <p className="text-92">
                                                        <span className="text-wrapper-23">
                                                            The Depo App will
                                                            become the place for
                                                            your team to easily
                                                            manage dedicated
                                                            litigation and trial
                                                            support specialists
                                                            to ensure that you
                                                            receive what you
                                                            need on timeand with
                                                            precise attention to
                                                            detail. Our goal is
                                                            to handle the
                                                            technology so that
                                                            you can focus your
                                                            attention on winning
                                                            your case.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button-21">
                                            <div className="frame-36">
                                                <div className="text-93">
                                                    <p className="text-83">
                                                        <span className="text-wrapper-17">
                                                            Get Started Today
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="features-2">
                                <div className="container-11">
                                    <div className="title-2">
                                        <p className="text-94">
                                            <span className="text-wrapper-13">
                                                Discover The Features
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row-7">
                                        <div className="col-11">
                                            <div
                                                className="frame-wrapper-3"
                                                style={{ left: "56.5px" }}
                                            >
                                                <div className="frame-37">
                                                    <div className="overlap-group-6">
                                                        <img
                                                            className="img-5"
                                                            alt="Uit process"
                                                            src={techProcess}
                                                        />
                                                        <div className="ellipse-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-11">
                                                <p className="text-95">
                                                    <span className="text-wrapper-15">
                                                        Fast and simple
                                                        scheduling and billing
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-12">
                                                <p className="text-96">
                                                    <span className="text-wrapper-14">
                                                        Our patent pending
                                                        scheduling algorithm
                                                        will allow law firms and
                                                        lawyers to savemoney on
                                                        deposition transcripts
                                                        and allow our court
                                                        reporters to optimize
                                                        their availabilityand
                                                        earn money. It&#39;s a
                                                        win-win for everyone!
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div
                                                className="frame-wrapper-3"
                                                style={{ left: "56.5px" }}
                                            >
                                                <div className="frame-37">
                                                    <div className="overlap-group-6">
                                                        <img
                                                            className="img-5"
                                                            alt="Frame"
                                                            src={shieldCheck}
                                                        />
                                                        <div className="ellipse-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-11">
                                                <p className="text-95">
                                                    <span className="text-wrapper-15">
                                                        Your data is backed up
                                                        safely and securely
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-13">
                                                <p className="text-97">
                                                    <span className="text-wrapper-14">
                                                        Lawyers benefit by
                                                        dealing directly with
                                                        deposition freelancers
                                                        so less people
                                                        are interactingwith
                                                        chain of custody legal
                                                        documents.The Depo App
                                                        can also provide backup
                                                        and documentrecover
                                                        services with a 1-2-3
                                                        backup philosophy in
                                                        place to protect
                                                        testimony and exhibits.
                                                        We use top of the line
                                                        data security to protect
                                                        and preserve
                                                        confidentiality.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-13">
                                            <div
                                                className="frame-wrapper-3"
                                                style={{ left: "56.5px" }}
                                            >
                                                <div className="frame-37">
                                                    <div className="overlap-group-6">
                                                        <img
                                                            className="img-5"
                                                            alt="Lets icons time"
                                                            src={timeProgress}
                                                        />
                                                        <div className="ellipse-2" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-11">
                                                <p className="text-95">
                                                    <span className="text-wrapper-15">
                                                        Just in time deposition
                                                        support freelancers
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-14">
                                                <p className="text-98">
                                                    <span className="text-wrapper-14">
                                                        Court Reporters that
                                                        sign up to our platform
                                                        are available at the
                                                        last minute to
                                                        fulfill emergencydepositions.
                                                        The patent pending
                                                        scheduling algorithm
                                                        provides freelancers at
                                                        a moments notice.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-2">
                                <div className="row-8">
                                    <div className="col-14">
                                        <div className="frame-38">
                                            <div className="text-99">
                                                <p className="text-100">
                                                    <span className="text-wrapper-13">
                                                        About Us
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-15">
                                                <p className="text-101">
                                                    <span className="text-wrapper-14">
                                                        The Depo App was created
                                                        because we wanted to
                                                        normalize the random
                                                        nature of depositions.
                                                        Lawyers and court
                                                        reportersface timing
                                                        challenges scheduling
                                                        depositions and this
                                                        in-efficiency costs
                                                        everybody money. We came
                                                        up with a
                                                        proprietarypatent
                                                        pending scheduling
                                                        algorithm that better
                                                        manages the logistics so
                                                        lawyers can save money
                                                        and court reporters
                                                        canoptimize their
                                                        earning capacity.
                                                    </span>
                                                </p>
                                            </div>
                                            <button className="button-22">
                                                <div className="frame-39">
                                                    <div className="text-102">
                                                        <p className="text-83">
                                                            <span className="text-wrapper-17">
                                                                Learn About Us
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-15">
                                        <div className="about-img-3">
                                            <div className="overlap-group-7">
                                                <div className="about-elipse-wrapper">
                                                    <div className="div-4" />
                                                </div>
                                                <img
                                                    className="about-img-4"
                                                    alt="About img"
                                                    src={aboutImg2}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="download-2">
                                <div className="container-12">
                                    <div className="gorup-2">
                                        <div
                                            className="frame-40"
                                            style={{ alignContent: "center" }}
                                        >
                                            <div className="text-103">
                                                <p className="text-104">
                                                    <span className="text-wrapper-14">
                                                        Join Professionals
                                                    </span>
                                                </p>
                                            </div>

                                            <div className="frame-41">
                                                <div className="frame-42">
                                                    <div className="text-105">
                                                        <p className="text-106">
                                                            <span className="text-wrapper-13">
                                                                Download The
                                                                App!
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>

                                            <img
                                                className="frame-43"
                                                alt="Frame"
                                                src={webBrowserPWA}
                                                style={{
                                                    width: "18rem",
                                                    left: "164px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-2">
                                <div className="overlap-5">
                                    <img
                                        className="faq-decoration"
                                        alt="Faq decoration"
                                        src={faq_decor2}
                                    />
                                    <div className="container-13">
                                        <div className="text-107">
                                            <p className="text-108">
                                                <span className="text-wrapper-18">
                                                    Frequently Asked Questions
                                                </span>
                                            </p>
                                        </div>
                                        <div className="accordion-5">
                                            <div className="group-10">
                                                <div className="accordion-6">
                                                    <div className="frame-44">
                                                        <div className="text-109">
                                                            <p className="text-110">
                                                                <span className="text-wrapper-14">
                                                                    At volutpat
                                                                    fringilla
                                                                    nullam
                                                                    tempor neque
                                                                    gravida.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-10"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-7">
                                                    <div className="frame-45">
                                                        <div className="text-109">
                                                            <p className="text-110">
                                                                <span className="text-wrapper-14">
                                                                    Tempor a
                                                                    diam
                                                                    volutpat
                                                                    condimentum
                                                                    leo neque
                                                                    aliquet
                                                                    adipiscing.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-10"
                                                            alt="Icon"
                                                            src={checkMinus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-8">
                                                    <div className="frame-46">
                                                        <div className="text-111">
                                                            <p className="text-112">
                                                                <span className="text-wrapper-14">
                                                                    The Depo App
                                                                    was created
                                                                    because we
                                                                    wanted to
                                                                    normalize
                                                                    the random
                                                                    nature of
                                                                    depositions.
                                                                    Lawyers and
                                                                    court
                                                                    reportersface
                                                                    timing
                                                                    challenges
                                                                    scheduling
                                                                    depositions
                                                                    and this
                                                                    in-efficiency
                                                                    costs
                                                                    everybody
                                                                    money. We
                                                                    came up with
                                                                    a
                                                                    proprietarypatent
                                                                    pending
                                                                    scheduling
                                                                    algorithm
                                                                    that better
                                                                    manages the
                                                                    logistics so
                                                                    lawyers can
                                                                    save money
                                                                    and court
                                                                    reporters
                                                                    canoptimize
                                                                    their
                                                                    earning
                                                                    capacity.
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-9">
                                                    <div className="frame-45">
                                                        <div className="text-109">
                                                            <p className="text-110">
                                                                <span className="text-wrapper-14">
                                                                    Semper
                                                                    aliquam nibh
                                                                    dictumst
                                                                    aliquam
                                                                    ullamcorper
                                                                    volutpat.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-10"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-4">
                                                    <div className="frame-45">
                                                        <div className="text-109">
                                                            <p className="text-110">
                                                                <span className="text-wrapper-14">
                                                                    Ligula amet
                                                                    sit purus
                                                                    commodo in
                                                                    nulla
                                                                    senectus
                                                                    nulla.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-10"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-5">
                                                    <div className="frame-47">
                                                        <div className="text-109">
                                                            <p className="text-110">
                                                                <span className="text-wrapper-14">
                                                                    Aliquam
                                                                    lorem
                                                                    feugiat
                                                                    iaculis
                                                                    feugiat
                                                                    risus vitae
                                                                    pharetra.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-10"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cta-2">
                                            <div className="container-14">
                                                <div className="overlap-6">
                                                    <div className="overlap-group-8">
                                                        <div className="faw-elipse-2" />
                                                        <button className="button-23">
                                                            <div className="frame-48">
                                                                <div className="text-113">
                                                                    <p className="text-77">
                                                                        <span className="text-wrapper-16">
                                                                            Contact
                                                                            Us
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div className="overlap-7">
                                                        <div className="text-114">
                                                            <p className="text-115">
                                                                <span className="text-wrapper-24">
                                                                    Still have
                                                                    question?
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="faq-star-2"
                                                            alt="Faq star"
                                                            src={faq_star}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer className="footer-2">
                                <div className="container-15">
                                    <div className="group-11">
                                        <img
                                            className="logo-4"
                                            alt="Logo"
                                            src={logoSubmenu2}
                                        />
                                        <div className="frame-49">
                                            <div className="button-24">
                                                <div className="btn-3">
                                                    <img
                                                        className="icon-11"
                                                        alt="Icon"
                                                        src={facebookLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-50">
                                                <div className="btn-3">
                                                    <img
                                                        className="icon-11"
                                                        alt="Icon"
                                                        src={linkedInLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-51">
                                                <div className="btn-3">
                                                    <img
                                                        className="icon-11"
                                                        alt="Icon"
                                                        src={XLogo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-9">
                                        <div className="col-16">
                                            <div className="frame-52">
                                                <div className="frame-53">
                                                    <img
                                                        className="icon-12"
                                                        alt="Icon"
                                                        src={emailIcon}
                                                    />
                                                    <button className="button-25">
                                                        <div className="frame-33">
                                                            <div
                                                                className="text-116"
                                                                style={{
                                                                    textAlign:
                                                                        "start",
                                                                }}
                                                            >
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        rl@thedepoappcompany.com
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-54">
                                                    <img
                                                        className="icon-12"
                                                        alt="Icon"
                                                        src={phoneIcon}
                                                    />
                                                    <button className="button-26">
                                                        <div className="frame-33">
                                                            <div
                                                                className="text-117"
                                                                style={{
                                                                    textAlign:
                                                                        "start",
                                                                }}
                                                            >
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        +267-246-8849
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-55">
                                                    <img
                                                        className="icon-13"
                                                        alt="Icon"
                                                        src={locationIcon}
                                                    />
                                                    <div className="button-27">
                                                        <div className="frame-56">
                                                            <div
                                                                className="text-118"
                                                                style={{
                                                                    textAlign:
                                                                        "start",
                                                                }}
                                                            >
                                                                <p className="text-119">
                                                                    <span className="text-wrapper-14">
                                                                        PO Box
                                                                        11344
                                                                        Philadelphia,
                                                                        pa,
                                                                        19137
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-16">
                                                <p className="text-120">
                                                    <span className="text-wrapper-23">
                                                        We are in beta if you
                                                        encounter any
                                                        emergencies call us
                                                        directly at
                                                        +267-246-8849 or fill in
                                                        the form with details
                                                        and we will contact you
                                                        within 48 hours
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-17">
                                            <div className="row-10">
                                                <div className="col-18">
                                                    <div className="text-121">
                                                        <p
                                                            className="text-122"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-25">
                                                                App
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-28">
                                                        <div className="frame-33">
                                                            <div className="text-123">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Home
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-29">
                                                        <div className="frame-33">
                                                            <div className="text-124">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Our App
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-30">
                                                        <div className="frame-33">
                                                            <div className="text-125">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Lawyers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-31">
                                                        <div className="frame-33">
                                                            <div className="text-126">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Freelancers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-19">
                                                    <div className="text-121">
                                                        <p
                                                            className="text-122"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-25">
                                                                Company
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-32">
                                                        <div className="frame-33">
                                                            <div className="text-127">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-33">
                                                        <div className="frame-33">
                                                            <div className="text-127">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-34">
                                                        <div className="frame-33">
                                                            <div className="text-128">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Contact
                                                                        Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-35">
                                                        <div className="frame-33">
                                                            <div className="text-129">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Tech
                                                                        Support
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-20">
                                                    <div className="text-121">
                                                        <p
                                                            className="text-122"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-25">
                                                                Download
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-36">
                                                        <div className="frame-33">
                                                            <div className="text-130">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        Google
                                                                        Play
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-37">
                                                        <div className="frame-33">
                                                            <div className="text-131">
                                                                <p className="text-79">
                                                                    <span className="text-wrapper-14">
                                                                        App
                                                                        Store
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright-2">
                                        <div className="text-132">
                                            <p className="text-133">
                                                <span className="text-wrapper-23">
                                                    Apple logo and App Store are
                                                    trademarks of Apple Inc.,
                                                    registered in the U.S. and
                                                    other countries. Google Play
                                                    and the Google Play logo are
                                                    trademarks of Google LLC.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                )}
            />

            <Media
                query="(min-width: 1440px)"
                render={() => (
                    <div className="lg-landing">
                        <div className="div-5" style={{ marginBottom: "6rem" }}>
                            <div className="hero-3">
                                <div className="row-11">
                                    <div className="col-21">
                                        <div className="hero-img-4">
                                            <div className="overlap-group-9">
                                                <div className="hero-block-2">
                                                    <div className="div-6" />
                                                </div>
                                                <img
                                                    className="hero-img-5"
                                                    alt="Hero img"
                                                    src={hero3}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-22">
                                        <div className="group-12">
                                            <div className="text-136">
                                                <p className="text-137">
                                                    <span className="text-wrapper-26">
                                                        Deposition Services
                                                        Scheduling Made Easy
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-138">
                                                <p className="text-139">
                                                    <span className="text-wrapper-27">
                                                        Streamline your legal
                                                        workflow with Depo App!
                                                        Connect with deposition
                                                        freelancers and schedule
                                                        deposition services.
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-140">
                                                <div className="frame-57">
                                                    <img
                                                        className="icon-14"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element-17">
                                                    <p className="text-141">
                                                        <span className="text-wrapper-28">
                                                            Save Money Going
                                                            Direct
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-142">
                                                <p className="text-143">
                                                    <span className="text-wrapper-27">
                                                        The Depo App can provide
                                                        an additional source of
                                                        revenue for deposition
                                                        freelancers.
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-144">
                                                <div className="frame-57">
                                                    <img
                                                        className="icon-14"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element-17">
                                                    <p className="text-141">
                                                        <span className="text-wrapper-28">
                                                            Tools for Success
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-145">
                                                <p className="text-146">
                                                    <span className="text-wrapper-27">
                                                        Get services such as
                                                        document recovery, case
                                                        and witness deposition
                                                        financial analytics,
                                                        archived recordings, and
                                                        find different types of
                                                        deposition support
                                                        freelancers
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="text-147">
                                                <div className="frame-57">
                                                    <img
                                                        className="icon-14"
                                                        alt="Icon"
                                                        src={check}
                                                    />
                                                </div>
                                                <div className="element-17">
                                                    <p className="text-141">
                                                        <span className="text-wrapper-28">
                                                            Earn More
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                            <div className="text-148">
                                                <p className="text-143">
                                                    <span className="text-wrapper-27">
                                                        The Depo App can provide
                                                        an additional source of
                                                        revenue for deposition
                                                        freelancers.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="work-4">
                                <div className="overlap-8">
                                    <img
                                        className="work-decoartion"
                                        alt="Work decoartion"
                                        src={decor}
                                    />
                                    <div className="container-16">
                                        <div className="text-149">
                                            <p className="text-150">
                                                <span className="text-wrapper-29">
                                                    Your best work happens in
                                                    Depo App
                                                </span>
                                            </p>
                                        </div>
                                        <div className="frame-58">
                                            <div className="card-5">
                                                <div className="group-13">
                                                    <div className="overlap-group-10">
                                                        <div className="card-bg-5" />
                                                        <div className="schedule-elipse-3" />
                                                        <div className="group-14">
                                                            <div className="text-151">
                                                                <p className="text-152">
                                                                    <span className="text-wrapper-30">
                                                                        Lawyers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="text-153">
                                                                <p className="text-154">
                                                                    <span className="text-wrapper-31">
                                                                        Schedule
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="img-6"
                                                            alt="Schedule"
                                                            src={calendar3}
                                                        />
                                                        <div className="button-38">
                                                            <div className="btn-4">
                                                                <img
                                                                    className="icon-15"
                                                                    alt="Icon"
                                                                    src={
                                                                        blueCheck
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="card-6">
                                                <div className="group-15">
                                                    <div className="overlap-group-10">
                                                        <div className="card-bg-6" />
                                                        <div className="work-elipse-3" />
                                                        <div className="group-14">
                                                            <div className="text-151">
                                                                <p className="text-155">
                                                                    <span className="text-wrapper-32">
                                                                        Freelancers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                            <div className="text-153">
                                                                <p className="text-156">
                                                                    <span className="text-wrapper-33">
                                                                        Work
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                        <img
                                                            className="img-6"
                                                            alt="Work"
                                                            src={jobsAvailable3}
                                                        />
                                                        <div className="button-38">
                                                            <div className="btn-4">
                                                                <img
                                                                    className="icon-15"
                                                                    alt="Icon"
                                                                    src={
                                                                        greenCheck
                                                                    }
                                                                />
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="container-17">
                                            <div className="group-16">
                                                <div className="element-18">
                                                    <p className="text-157">
                                                        <span className="text-wrapper-34">
                                                            The Depo App will
                                                            become the place for
                                                            your team to easily
                                                            manage dedicated
                                                            litigation and trial
                                                            support specialists
                                                            to ensure that you
                                                            receive what you
                                                            need on timeand with
                                                            precise attention to
                                                            detail. Our goal is
                                                            to handle the
                                                            technology so that
                                                            you can focus your
                                                            attention on winning
                                                            your case.
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                        <button className="button-39">
                                            <div className="frame-59">
                                                <div className="text-158">
                                                    <p className="text-159">
                                                        <span className="text-wrapper-35">
                                                            Get Started Today
                                                        </span>
                                                    </p>
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                </div>
                            </div>
                            <div className="features-3">
                                <div className="container-18">
                                    <div className="title-3">
                                        <p className="text-160">
                                            <span className="text-wrapper-26">
                                                Discover The Features
                                            </span>
                                        </p>
                                    </div>
                                    <div className="row-12">
                                        <div className="col-23">
                                            <div
                                                className="frame-wrapper-4"
                                                style={{ left: "135px" }}
                                            >
                                                <div className="frame-60">
                                                    <div className="overlap-group-11">
                                                        <img
                                                            className="img-7"
                                                            alt="Uit process"
                                                            src={techProcess2}
                                                        />
                                                        <div className="ellipse-3" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-19">
                                                <p className="text-161">
                                                    <span className="text-wrapper-28">
                                                        Fast and simple
                                                        scheduling and billing
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-20">
                                                <p className="text-162">
                                                    <span className="text-wrapper-27">
                                                        Our patent pending
                                                        scheduling algorithm
                                                        will allow law firms and
                                                        lawyers to savemoney on
                                                        deposition transcripts
                                                        and allow our court
                                                        reporters to optimize
                                                        their availabilityand
                                                        earn money. It&#39;s a
                                                        win-win for everyone!
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-24">
                                            <div
                                                className="frame-wrapper-4"
                                                style={{ left: "135px" }}
                                            >
                                                <div className="frame-60">
                                                    <div className="overlap-group-11">
                                                        <img
                                                            className="img-7"
                                                            alt="Frame"
                                                            src={shieldCheck2}
                                                        />
                                                        <div className="ellipse-3" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-19">
                                                <p className="text-161">
                                                    <span className="text-wrapper-28">
                                                        Your data is backed up
                                                        safely and securely
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-21">
                                                <p className="text-163">
                                                    <span className="text-wrapper-27">
                                                        Lawyers benefit by
                                                        dealing directly with
                                                        deposition freelancers
                                                        so less people
                                                        are interactingwith
                                                        chain of custody legal
                                                        documents.The Depo App
                                                        can also provide backup
                                                        and documentrecover
                                                        services with a 1-2-3
                                                        backup philosophy in
                                                        place to protect
                                                        testimony and exhibits.
                                                        We use top of the line
                                                        data security to protect
                                                        and preserve
                                                        confidentiality.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-25">
                                            <div
                                                className="frame-wrapper-4"
                                                style={{ left: "135px" }}
                                            >
                                                <div className="frame-60">
                                                    <div className="overlap-group-11">
                                                        <img
                                                            className="img-7"
                                                            alt="Lets icons time"
                                                            src={timeProgress2}
                                                        />
                                                        <div className="ellipse-3" />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-19">
                                                <p className="text-161">
                                                    <span className="text-wrapper-28">
                                                        Just in time deposition
                                                        support freelancers
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-20">
                                                <p className="text-162">
                                                    <span className="text-wrapper-27">
                                                        Court Reporters that
                                                        sign up to our platform
                                                        are available at the
                                                        last minute to
                                                        fulfill emergencydepositions.
                                                        The patent pending
                                                        scheduling algorithm
                                                        provides freelancers at
                                                        a moments notice.
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="about-3">
                                <div className="row-13">
                                    <div className="col-21">
                                        <div className="frame-61">
                                            <div className="text-164">
                                                <p className="text-165">
                                                    <span className="text-wrapper-26">
                                                        About Us
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="element-22">
                                                <p className="text-166">
                                                    <span className="text-wrapper-27">
                                                        The Depo App was created
                                                        because we wanted to
                                                        normalize the random
                                                        nature of depositions.
                                                        Lawyers and court
                                                        reportersface timing
                                                        challenges scheduling
                                                        depositions and this
                                                        in-efficiency costs
                                                        everybody money. We came
                                                        up with a
                                                        proprietarypatent
                                                        pending scheduling
                                                        algorithm that better
                                                        manages the logistics so
                                                        lawyers can save money
                                                        and court reporters
                                                        canoptimize their
                                                        earning capacity.
                                                    </span>
                                                </p>
                                            </div>
                                            <button className="button-40">
                                                <div className="frame-62">
                                                    <div className="text-167">
                                                        <p className="text-159">
                                                            <span className="text-wrapper-35">
                                                                Learn About Us
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                    <div className="col-22">
                                        <div className="about-img-5">
                                            <div className="overlap-group-9">
                                                <div className="about-block-2">
                                                    <div className="div-6" />
                                                </div>
                                                <img
                                                    className="about-img-6"
                                                    alt="About img"
                                                    src={aboutImg3}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="download-3">
                                <div className="container-19">
                                    <div className="gorup-3">
                                        <div className="frame-63">
                                            <div className="text-168">
                                                <p className="text-169">
                                                    <span className="text-wrapper-27">
                                                        Join Professionals
                                                    </span>
                                                </p>
                                            </div>
                                            <div className="frame-64">
                                                <div className="frame-65">
                                                    <div className="text-170">
                                                        <p className="text-171">
                                                            <span className="text-wrapper-26">
                                                                Download The
                                                                App!
                                                            </span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <img
                                                className="frame-66"
                                                alt="Frame"
                                                src={webBrowserPWA}
                                                style={{
                                                    width: "18rem",
                                                    left: "164px",
                                                }}
                                            />
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="faq-3">
                                <div className="overlap-9">
                                    <img
                                        className="faq-decoration-2"
                                        alt="Faq decoration"
                                        src={faq_decor}
                                    />
                                    <div className="container-20">
                                        <div className="text-172">
                                            <p className="text-173">
                                                <span className="text-wrapper-29">
                                                    Frequently Asked Questions
                                                </span>
                                            </p>
                                        </div>
                                        <div className="accordion-10">
                                            <div className="group-17">
                                                <div className="accordion-11">
                                                    <div className="frame-67">
                                                        <div className="text-174">
                                                            <p className="text-175">
                                                                <span className="text-wrapper-27">
                                                                    At volutpat
                                                                    fringilla
                                                                    nullam
                                                                    tempor neque
                                                                    gravida.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-16"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-12">
                                                    <div className="frame-68">
                                                        <div className="text-174">
                                                            <p className="text-175">
                                                                <span className="text-wrapper-27">
                                                                    Tempor a
                                                                    diam
                                                                    volutpat
                                                                    condimentum
                                                                    leo neque
                                                                    aliquet
                                                                    adipiscing.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-16"
                                                            alt="Icon"
                                                            src={checkMinus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-13">
                                                    <div className="frame-69">
                                                        <div className="text-176">
                                                            <p className="text-177">
                                                                <span className="text-wrapper-27">
                                                                    The Depo App
                                                                    was created
                                                                    because we
                                                                    wanted to
                                                                    normalize
                                                                    the random
                                                                    nature of
                                                                    depositions.
                                                                    Lawyers and
                                                                    court
                                                                    reportersface
                                                                    timing
                                                                    challenges
                                                                    scheduling
                                                                    depositions
                                                                    and this
                                                                    in-efficiency
                                                                    costs
                                                                    everybody
                                                                    money. We
                                                                    came up with
                                                                    a
                                                                    proprietarypatent
                                                                    pending
                                                                    scheduling
                                                                    algorithm
                                                                    that better
                                                                    manages the
                                                                    logistics so
                                                                    lawyers can
                                                                    save money
                                                                    and court
                                                                    reporters
                                                                    canoptimize
                                                                    their
                                                                    earning
                                                                    capacity.
                                                                </span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-14">
                                                    <div className="frame-68">
                                                        <div className="text-174">
                                                            <p className="text-175">
                                                                <span className="text-wrapper-27">
                                                                    Semper
                                                                    aliquam nibh
                                                                    dictumst
                                                                    aliquam
                                                                    ullamcorper
                                                                    volutpat.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-16"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-6">
                                                    <div className="frame-68">
                                                        <div className="text-174">
                                                            <p className="text-175">
                                                                <span className="text-wrapper-27">
                                                                    Ligula amet
                                                                    sit purus
                                                                    commodo in
                                                                    nulla
                                                                    senectus
                                                                    nulla.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-16"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                                <div className="accordion-title-7">
                                                    <div className="frame-70">
                                                        <div className="text-174">
                                                            <p className="text-175">
                                                                <span className="text-wrapper-27">
                                                                    Aliquam
                                                                    lorem
                                                                    feugiat
                                                                    iaculis
                                                                    feugiat
                                                                    risus vitae
                                                                    pharetra.
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="icon-16"
                                                            alt="Icon"
                                                            src={checkPlus}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="cta-3">
                                            <div className="container-21">
                                                <div className="overlap-10">
                                                    <div className="overlap-11">
                                                        <div className="faw-elipse-3" />
                                                        <button className="button-41">
                                                            <div className="frame-71">
                                                                <div className="text-178">
                                                                    <p className="text-179">
                                                                        <span className="text-wrapper-36">
                                                                            Contact
                                                                            Us
                                                                        </span>
                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                    <div className="overlap-group-12">
                                                        <div className="text-180">
                                                            <p className="text-181">
                                                                <span className="text-wrapper-37">
                                                                    Still have
                                                                    question?
                                                                </span>
                                                            </p>
                                                        </div>
                                                        <img
                                                            className="faq-star-3"
                                                            alt="Faq star"
                                                            src={faq_star}
                                                        />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <footer className="footer-3">
                                <div className="container-22">
                                    <div className="group-18">
                                        <img
                                            className="logo-5"
                                            alt="Logo"
                                            src={logoSubmenu2}
                                        />
                                        <div className="frame-72">
                                            <div className="button-42">
                                                <div className="btn-4">
                                                    <img
                                                        className="icon-15"
                                                        alt="Icon"
                                                        src={facebookLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-73">
                                                <div className="btn-4">
                                                    <img
                                                        className="icon-15"
                                                        alt="Icon"
                                                        src={linkedInLogo}
                                                    />
                                                </div>
                                            </div>
                                            <div className="frame-74">
                                                <div className="btn-4">
                                                    <img
                                                        className="icon-15"
                                                        alt="Icon"
                                                        src={XLogo}
                                                    />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row-14">
                                        <div className="col-26">
                                            <div className="frame-75">
                                                <div className="frame-76">
                                                    <img
                                                        className="icon-17"
                                                        alt="Icon"
                                                        src={emailIcon}
                                                    />
                                                    <button className="button-43">
                                                        <div className="frame-77">
                                                            <div className="text-182">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        rl@thedepoappcompany.com
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-78">
                                                    <img
                                                        className="icon-17"
                                                        alt="Icon"
                                                        src={phoneIcon}
                                                    />
                                                    <button className="button-44">
                                                        <div className="frame-77">
                                                            <div className="text-184">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        +267-246-8849
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="frame-79">
                                                    <img
                                                        className="icon-17"
                                                        alt="Icon"
                                                        src={locationIcon}
                                                    />
                                                    <div className="button-45">
                                                        <div className="frame-77">
                                                            <div className="text-185">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        PO Box
                                                                        11344
                                                                        Philadelphia,
                                                                        pa,
                                                                        19137
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="element-23">
                                                <p className="text-186">
                                                    <span className="text-wrapper-34">
                                                        We are in beta if you
                                                        encounter any
                                                        emergencies call us
                                                        directly at
                                                        +267-246-8849 or fill in
                                                        the form with details
                                                        and we will contact you
                                                        within 48 hours
                                                    </span>
                                                </p>
                                            </div>
                                        </div>
                                        <div className="col-27">
                                            <div className="row-15">
                                                <div className="col-28">
                                                    <div className="text-187">
                                                        <p
                                                            className="text-188"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-38">
                                                                App
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-46">
                                                        <div className="frame-77">
                                                            <div className="text-189">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Home
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-47">
                                                        <div className="frame-77">
                                                            <div className="text-190">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Our App
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-48">
                                                        <div className="frame-77">
                                                            <div className="text-191">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Lawyers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-49">
                                                        <div className="frame-77">
                                                            <div className="text-192">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Freelancers
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-29">
                                                    <div className="text-187">
                                                        <p
                                                            className="text-188"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-38">
                                                                Company
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-50">
                                                        <div className="frame-77">
                                                            <div className="text-193">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-51">
                                                        <div className="frame-77">
                                                            <div className="text-193">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        About Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-52">
                                                        <div className="frame-77">
                                                            <div className="text-194">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Contact
                                                                        Us
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-53">
                                                        <div className="frame-77">
                                                            <div className="text-195">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Tech
                                                                        Support
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                <div className="col-30">
                                                    <div className="text-187">
                                                        <p
                                                            className="text-188"
                                                            style={{
                                                                textAlign:
                                                                    "start",
                                                            }}
                                                        >
                                                            <span className="text-wrapper-38">
                                                                Download
                                                            </span>
                                                        </p>
                                                    </div>
                                                    <button className="button-54">
                                                        <div className="frame-77">
                                                            <div className="text-196">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        Google
                                                                        Play
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                    <button className="button-55">
                                                        <div className="frame-77">
                                                            <div className="text-197">
                                                                <p className="text-183">
                                                                    <span className="text-wrapper-27">
                                                                        App
                                                                        Store
                                                                    </span>
                                                                </p>
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="copyright-3">
                                        <div className="text-198">
                                            <p className="text-199">
                                                <span className="text-wrapper-34">
                                                    Apple logo and App Store are
                                                    trademarks of Apple Inc.,
                                                    registered in the U.S. and
                                                    other countries. Google Play
                                                    and the Google Play logo are
                                                    trademarks of Google LLC.
                                                </span>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </footer>
                        </div>
                    </div>
                )}
            />
        </div>
    );
};
