import React, { useState, useContext, useEffect, useRef } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate, useSearchParams } from "react-router-dom";
import Geosuggest from "react-geosuggest";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import HandSigned from "../../components/HandSigned";
//import { HandSigned } from "@react-goodies/hand-signed";
import Markdown from "react-markdown";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import { LoaderButton } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { isEmail, regexAccountId } from "../../libs/utilities";
import {
	corporateRecordCheck,
	postSignatureAPI,
	createNewUser,
} from "../../libs/apiService";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import "./Register.css";
import { ApplicationContext } from "../../context/GlobalState";
import {
	userHasAuthenticated,
	isModalOpen,
	setBodyOverflow,
	setFirstName,
	setLastName,
	setUserEmail,
	setUserCellPhone,
	updateUser,
	setUserBillingAddress,
	setUserOrganizationId,
	setCorporateName,
	setUserSignature,
	setUserTermsAccepted,
} from "../../context/actions";

import config from "../../config";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-regular-svg-icons";
import { faTimesCircle } from "@fortawesome/free-regular-svg-icons";

import MD from "../TermsAndConditions/termsConditions.js";

const checkCircle = <FontAwesomeIcon icon={faCheckCircle} />; // TODO: check for use?
const checkX = <FontAwesomeIcon icon={faTimesCircle} />;

/* global google */

const Register = () => {
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);
	const [step, setStep] = useState("0");
	const [userRecordId, setUserRecordId] = useState(""); // FIXME: need to deprecate
	const [showSelectedRadio, setShowSelectedRadio] = useState({
		freelancerClass: ".do-not-display",
		lawFirmClass: ".do-not-display",
	});
	const [showModal, setShowModal] = useState(false);
	//const [newUser, setNewUser] = useState(null);

	const [handSignedDimensions, setHandSignedDimensions] = useState({
		width: "100%",
		height: "100%",
	}); // Default dimensions

	const [signatureRawData, setSignatureRawData] = useState(""); // Step 1: New state for raw data

	const [fields, handleFieldChange, setFieldValue, setAnyFieldValue] =
		useFormFields({
			email: "",
			firstName: "",
			lastName: "",
			cellPhone: "",
			corporateName: "",
			password: "",
			confirmPassword: "",
			confirmationCode: "",
			streetAddress: "",
			streetAddressLine2: "",
			city: "",
			zipCode: "",
			affiliateId: "",
			userRole: "",
			userRoleRadioLawFirm: "",
			userRoleRadioFreelancer: "",
			organizationId: "",
			termsAccepted: false,
			signature: "",
		});

	const { user, barRef, billingAddress } = state;
	const [isPasswordValid, setIsPasswordValid] = useState(true);
	const signaturePadRef = useRef(null);
	const handSignedContainerRef = useRef(null);
	const [isSignatureDrawn, setIsSignatureDrawn] = useState(false);
	const nav = useNavigate();
	const geosuggestEl = useRef(null);
	console.log("REGISTER_STATE: ", state);

	/*
	 *	NOTE: Capture Affiliate ID here
	 *
	 */
	let [searchParams, setSearchParams] = useSearchParams();
	console.log("affiliate clues: ", searchParams.get("affiliateId") === null);

	let affiliateId = searchParams.get("affiliateId");
	affiliateId !== null
		? (affiliateId = searchParams.get("affiliateId"))
		: (affiliateId = "NONE"); // NOTE: ?affiliateId=1234567_ID&systemAdminRef=AutoMater2022
	console.log("queryParams: ", affiliateId);

	/**
	 *
	 *	useEffect() updates
	 *
	 *
	 **/

	useEffect(() => {
		// Scroll to the top of the page when the component mounts
		window.scrollTo(0, 0);
	});

	// Call this function on component mount and window resize
	useEffect(() => {
		// set scroll to auto
		disableBackgroundScroll(false);

		// update signatue pad dims
		updateHandSignedDimensions();
		window.addEventListener("resize", updateHandSignedDimensions);

		return () => {
			window.removeEventListener("resize", updateHandSignedDimensions);
		};
	}, []);

	useEffect(() => {
		const interval = setInterval(() => {
			if (signaturePadRef.current) {
				const rawData = signaturePadRef.current.getRawData();
				console.log("RAW DATA ----->: ", rawData);

				// Check if rawData contains any stroke data
				const isSignaturePresent = rawData.some(
					(stroke) => stroke.length > 0
				);
				if (rawData && isSignaturePresent) {
					setIsSignatureDrawn(true);
					// Dispatch action to keep overflow as hidden
					dispatch(setBodyOverflow("hidden"));
				} else {
					setIsSignatureDrawn(false);
					// Optionally reset overflow to auto when no signature is detected
					// dispatch(setBodyOverflow("auto"));
				}
			}
		}, 100); // Check every 100 milliseconds

		return () => clearInterval(interval);
	}, []);

	// monitor onLoad operations here
	useEffect(() => {
		if (isLoading) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading]);

	useEffect(() => {
		//
		const rawData = signaturePadRef.current?.getRawData();
		const isSignatureEmpty = !rawData || rawData.length < 1;

		if (isSignatureEmpty) {
			dispatch(setUserTermsAccepted(false));
			dispatch(setUserSignature(""));
			setFieldValue("signature", "");
			setFieldValue("termsAccepted", false);
		}

		console.log("SET_TERMS_ACCEPTED: ", isSignatureEmpty);
		dispatch(setUserTermsAccepted(fields.termsAccepted));
	}, [fields.termsAccepted]);

	useEffect(() => {
		//signatureRawData
		const rawData = signaturePadRef.current?.getRawData();
		const isSignatureEmpty = !rawData || rawData.length < 1;

		if (isSignatureEmpty) {
			dispatch(setUserTermsAccepted(false));
			dispatch(setUserSignature(""));
			setFieldValue("signature", "");
			setFieldValue("termsAccepted", false);
		}

		console.log("SET_SIGNATURE"); //TODO:
		if (signaturePadRef?.current !== null) {
			setSignatureRawData(
				signaturePadRef.current.getRawData() === null
					? [[]]
					: signaturePadRef.current.getRawData()
			); //
		}
		//
		dispatch(setUserSignature(fields.signature));
		//
		if (fields.termsAccepted === true) {
			handleAcceptTerms();
		}
	}, [fields.signature]);

	// monitor userRole changes here
	useEffect(() => {
		// when state change on fields.userRole
		// we need to setShowSelectedRadio to correct view Law_Firm vs Freelancer
		console.log("USER ROLE CHANGED: ", fields.userRole);

		if (fields.userRole === "") {
			//
			console.log("NOBODY SELECTED");

			setShowSelectedRadio({
				freelancerClass: "do-not-display",
				lawFirmClass: "do-not-display",
			});
		} else if (
			fields.userRole === "Law_Firm" &&
			fields.userRoleRadioFreelancer === ""
		) {
			console.log("LAW FIRM SELECTED", fields);
			// selected is the Law_Firm
			// therefore the Freelancer needs to hide

			setShowSelectedRadio({
				freelancerClass: "do-not-display",
				lawFirmClass: "please-display",
			});
		} else if (
			fields.userRole === "Freelancer" &&
			fields.userRoleRadioLawFirm === ""
		) {
			console.log("FREELANCER SELECTED", fields);
			// selected is the Freelancer
			// therefore the Law_firm dropdown needs to hide

			setShowSelectedRadio({
				freelancerClass: "please-display",
				lawFirmClass: "do-not-display",
			});
		} else if (
			fields.userRole === "Law_Firm" &&
			fields.userRoleRadioFreelancer === "Freelancer"
		) {
			console.log("LAW_FIRM SELECTION #2 TESTING", fields);
			const role = fields.userRole;
			const selector = fields.userRoleRadioLawFirm;

			setShowSelectedRadio({
				freelancerClass: "do-not-display",
				lawFirmClass: "please-display",
			});

			setFieldValue("userRoleSelectFreelancer", "Select Role");
			setFieldValue("userRoleRadioFreelancer", "");
			setFieldValue("userRoleSelectLawFirm", selector);
			setFieldValue("userRole", role);
		} else if (
			fields.userRole === "Freelancer" &&
			fields.userRoleRadioLawFirm === "Law_Firm"
		) {
			console.log("LAW_FIRM SELECTION #2 TESTING", fields);
			const role = fields.userRole;
			const selector = fields.userRoleRadioFreelancer;

			setShowSelectedRadio({
				freelancerClass: "please-display",
				lawFirmClass: "do-not-display",
			});
			setFieldValue("userRoleSelectLawFirm", "Select Role");
			setFieldValue("userRoleRadioLawFirm", "");
			setFieldValue("userRoleSelectFreelancer", selector);
			setFieldValue("userRole", role);
		}
	}, [fields.userRole]);

	// NOTE: getting the correct userRole based on any email change/update
	useEffect(() => {
		async function updateRole() {
			try {
				let apiCheck = await corporateRecordCheck({
					adminEmail: fields.email,
				});

				console.log(
					"ORG ID API CHECKER: ",
					apiCheck.data.organizationId
				);
				console.log("USER ROLE API CHECKER: ", apiCheck.data.userRole);

				// NOTE: Addinig user Role to state
				if (
					apiCheck.data.userRole === "System_Admin" ||
					apiCheck.data.userRole === "System_Manager"
				) {
					// NOTE: DO NOT CALL setAnyFieldValue ANYWHERE ELSE!!
					setAnyFieldValue("userRole", apiCheck.data.userRole);
				} else {
					// NOTE: DO NOT CALL setAnyFieldValue ANYWHERE ELSE!!
					setAnyFieldValue("userRole", "");
				}

				console.log("AFTER EMAIL UPDATE - fields: ", fields);
			} catch (err) {
				//
				if (err.message === "Invalid email format") {
					//
					console.log("STILL CHECKING EMAIL FIELD!");
				}
			}
		}

		if (isEmail(fields.email)) {
			console.log("UPDATING THE ROLE IN FIELDS: ");
			updateRole();
		}
	}, [fields.email]);

	// useEffect hook to validate the password whenever it changes
	useEffect(() => {
		// Assuming validatePassword is a function that returns true if the password is valid
		setIsPasswordValid(validatePassword(fields.password));
	}, [fields.password]);

	// just printing vaolues and current state to console for testing here
	useEffect(() => {
		disableBackgroundScroll(false);
		// Scroll to the top of the page when the component mounts
		console.log("RegisterState1: - state", state);
		console.log("RegisterState2 - fields: ", fields);
	});

	/**
	 *
	 * validation functions here
	 *
	 *
	 **/

	const validateKycForm = () => {
		return (
			fields.corporateName.length > 0 &&
			fields.email.length > 0 &&
			fields.lastName.length > 0 &&
			fields.firstName.length > 0 &&
			isEmail(fields.email) &&
			billingAddress.streetAddress.length > 0 &&
			billingAddress.city.length > 0 &&
			billingAddress.streetAddress !== "INVALID"
		);
	};

	const validatePasswordForm = () => {
		return (
			fields.password.length >= 8 &&
			validatePassword(fields.password) &&
			fields.password === fields.confirmPassword &&
			fields.userRole !== "Freelancer" &&
			fields.userRole !== "Law_Firm" &&
			fields.userRole !== "" &&
			fields.termsAccepted !== false
		);
	};

	const validateConfirmationForm = () => {
		//
		return fields.confirmationCode.length > 0;
	};

	/**
	 *
	 * @function isDepoAppDomain(email)
	 *
	 * This function will tell us if the email comes from a specific domain, in this case depoapp.com
	 *
	 * @param {string} email - the email to validate upon
	 *
	 * @returns {boolean}
	 *
	 */
	const isDepoAppDomain = (email) => {
		// Regular expression to match the domain part of an email
		const domainRegex = /@(.+)$/;

		// Extract the domain from the email
		const match = email.match(domainRegex);

		// Check if the domain is 'depoapp.com'
		return match && match[1] === "depoapp.com";
	};

	const validatePassword = (password) => {
		const minLength = 8;
		const hasUpperCase = /[A-Z]/.test(password);
		const hasLowerCase = /[a-z]/.test(password);
		const hasNumbers = /\d/.test(password);
		const hasSpecialChars = /[/!@#$%^&*~_`;+=(),.?"':{}|<>\\[\]-]/.test(
			password
		);

		return (
			password.length >= minLength &&
			hasUpperCase &&
			hasLowerCase &&
			hasNumbers &&
			hasSpecialChars
		);
	};

	const disableBackgroundScroll = (isModalOpen) => {
		if (isModalOpen) {
			document.body.style.overflow = "hidden";
		} else if (!isModalOpen) {
			document.body.style.overflow = "auto";

			const registerButton = document.getElementById("registerButton1");
			if (registerButton) registerButton.focus();
		} else {
			document.body.style.overflow = "auto";
		}
	};

	const handleFocus = (event) => {
		// Only apply special handling for smaller screens
		if (window.innerWidth <= 768) {
			setTimeout(() => {
				event.target.scrollIntoView({
					behavior: "smooth",
					block: "nearest",
				});
			}, 300); // Adjust delay as needed
		} else {
			// Scrolls the input element into view
			event.target.scrollIntoView({ behavior: "smooth", block: "start" });
			document.body.style.overflow = "auto";
		}
	};

	/**
	 *
	 * @function updateHandSignedDimensions
	 * Calculates both width and height, ensuring the height
	 * is always 10% of the width.
	 *
	 *
	 **/
	const updateHandSignedDimensions = () => {
		if (handSignedContainerRef.current) {
			const containerWidth = handSignedContainerRef.current.offsetWidth;
			const calculatedHeight = containerWidth * 0.1; // Height is 10% of the width
			setHandSignedDimensions({
				width: `${containerWidth}px`, // Set width in pixels
				height: `${calculatedHeight}px`, // Set height in pixels
			});
		}
	};

	/**
	 *
	 * @function handleAcceptTerms
	 *
	 **/
	const handleAcceptTerms = () => {
		// Ensure terms are accepted and a signature is provided before closing the modal
		console.log("Accepted:", fields.termsAccepted);
		console.log("Signature:", fields.signature);
		if (fields.termsAccepted && fields.signature) {
			// Here, you can handle sending the acceptance and signature data to the database

			disableBackgroundScroll(false);
			setShowModal(false); // Close the modal
		} else {
			alert("Please accept the terms and provide your signature.");
		}
	};

	/**
	 *
	 * 	@function handleSubmit
	 *
	 * 	onSubmit validates KYC data
	 *
	 **/
	const handleSubmit = async (e) => {
		// FIXME: need to get userRole from backend
		let passed = false;
		e.preventDefault();
		setIsLoading(true);

		let user = {
			username: fields.email.toLowerCase().trim(),
			password: fields.password,
			attributes: {
				// FIXME:
				"custom:userRole": fields.userRole,
				"custom:organizationId": state.organizationId, //this.state.organizationId.toLowerCase(), // custom attribute, not standard
			},
		};

		dispatch(setUserCellPhone(fields.cellPhone));

		let authUser = "";
		try {
			// NOTE: handle login from AWS
			authUser = await Auth.signUp(user);

			console.log("[AUDIT]: Sent Confirmation on SIGNUP For: ", authUser);

			//dispatch(setUserOrganizationId(state.organizationId));

			//setNewUser(authUser);

			// register newUser -----> new
			passed = true;
		} catch (e) {
			// FIXME: STILL NEED TO RESOLVE WHAT HAPPENS ON ERRORS

			try {
				console.log(
					"handleSubmit user registration error: ",
					e.message
				);
				if (
					e.message ===
					"An account with the given email already exists."
				) {
					// need to set state to not-null to bring up confirmation screen again
					console.log("part-2 register error");

					const authUser = await Auth.resendSignUp(
						fields.email.toLowerCase().trim()
					);

					console.log("Resent Confirmation For: ", authUser);

					onError(
						e.message +
							" Please check your email for a new confirmation code!"
					);

					dispatch(
						updateUser({
							userRole: user.attributes["custom:userRole"],
							organizationId: state.organizationId,
							userRecordId: authUser.userSub,
							verified: authUser.userConfirmed,
							password: fields.password,
						})
					);
					setIsLoading(false);
				} else {
					onError(e.message);

					console.log("[AUDIT] Error in sign up flow #1: ", e);
					console.log("[AUDIT] Error STATE #1: ", state);
					setIsLoading(false);
					nav("/login");
				}
			} catch (err) {
				onError(err.message);
				console.log("[AUDIT] Error in sign up flow #2: ", err);
				console.log("[AUDIT] Error STATE #2: ", state);
				setIsLoading(false);
				nav("/login");
			}
		}

		if (passed) {
			// NOTE: Send user to DB
			console.log("THIS IS THE USER SUB: ", authUser.userSub);
			let signature = "";
			try {
				//
				signature = await postSignatureAPI(authUser.userSub);
			} catch (e) {
				//
				console.log("GetSignature Error: ", e);
			}

			try {
				// create new user in db AFTER user confirms registration email ONLY
				await createNewUser({
					user: {
						organizationId: state.organizationId.toLowerCase(),
						userRole: fields.userRole,
						userRecordId: authUser.userSub,
						organizationName: fields.corporateName.trim(),
						streetAddress: billingAddress.streetAddress,
						addressLine2: fields.streetAddressLine2
							? fields.streetAddressLine2
							: "",
						city: state.billingAddress.city,
						stateOf: state.billingAddress.stateOf,
						zipCode: state.billingAddress.zipCode,
						country: state.billingAddress.country,
						firstName: fields.firstName.trim(),
						lastName: fields.lastName.trim(),
						email: fields.email.trim(),
						cellPhone: fields.cellPhone,
						signature: fields.signature, // TODO: update corporate-records API
						termsAccepted: fields.termsAccepted, // TODO: update GET user data in App component
						signatureRawData: signatureRawData,
						affiliateId: affiliateId || null,
					},
					signature: signature,
				});

				dispatch(
					updateUser({
						userRole: user.attributes["custom:userRole"],
						organizationId: state.organizationId,
						userRecordId: authUser.userSub,
						verified: authUser.userConfirmed,
						password: fields.password,
					})
				); // NOTE: make sure to update space with authUser attributes alone
			} catch (e) {
				//
				console.log("CreateNewUser DB Error: ", e);
			}
		}

		setIsLoading(false);
	};

	/**
	 *
	 * 	@function handleConfirmationSubmit when validating confirmation code
	 * 	sent to the user's email address.
	 *
	 *
	 **/
	const handleConfirmationSubmit = async (e) => {
		//
		e.preventDefault();
		setIsLoading(true);

		try {
			//
			const signUpConfirmation = await Auth.confirmSignUp(
				state.email.toLowerCase().trim(),
				fields.confirmationCode.trim()
			);
			console.log(
				"[AUDIT]]: USER IS NOW CONFIRMED: ",
				signUpConfirmation
			);

			const signInData = await Auth.signIn(
				state.email.toLowerCase().trim(),
				state.user.password
			);
			console.log(
				"[AUDIT]]: USER IS NOW LOGGED INTO SYSTEM: ",
				signInData
			);

			dispatch(userHasAuthenticated(true));

			console.log("[AUDIT]: B4 Signin Reset", state);
			dispatch(
				updateUser({
					userRole: signInData.attributes["custom:userRole"],
					organizationId:
						signInData.attributes["custom:organizationId"],
					userRecordId: signInData.attributes["sub"],
					verified: signInData.attributes.email_verified,
					password: "secured",
				})
			);
			console.log("[AUDIT]: After Signin Reset", state);
			// Set isLoading to false
			setIsLoading(false);
			nav("/");
		} catch (e) {
			//
			console.log("ConfirmSubmit Error: ", e);
			onError(e);
			setIsLoading(false);
			nav("/login");
		}
	};

	/**
	 *  @function regexAddress
	 *
	 *  This functions splits the selected suggestion
	 *  into parts to return the street address line
	 *  1 data.
	 *
	 *  @param suggestion
	 *
	 *  @return [streetAddress, city, state]
	 *
	 */
	const regexAddress = (suggestion) => {
		if (suggestion.length > 0) {
			// FIXME: When address is 1085-1087 Boylston St Regex fails at 1085-
			let streetAddress = suggestion.split(", ")[0];
			let city = suggestion.split(", ")[1];
			let state = suggestion.split(", ")[2];
			console.log("REGEX 1: ", [streetAddress, city, state]);
			return [streetAddress, city, state];
		}
	};

	/**
	 *
	 * @function zipCodeSearch
	 *
	 */
	const zipCodeSearch = async (geoCodeURL) => {
		let formattedAddress;
		let url = geoCodeURL;

		// NOTE: FIXED THE ERROR WITH PARSING THE JSON RESPONSE
		// Need to get address data back from google
		// to pull zipcode data
		// eslint-disable-next-line
		await fetch(url)
			.then((response) => response.json())
			// eslint-disable-next-line
			.then((data) => (formattedAddress = data));

		console.log("ADDRESS1 DATA", formattedAddress);

		formattedAddress =
			formattedAddress.results[0].formatted_address.split(", ");

		console.log("FORMATTED ADDRESS1", formattedAddress);

		let city = formattedAddress[formattedAddress.length - 3];
		let stateOf =
			formattedAddress[formattedAddress.length - 2].split(" ")[0];
		let zipCode =
			formattedAddress[formattedAddress.length - 2].split(" ")[1];
		let country = formattedAddress[formattedAddress.length - 1];

		let check = await corporateRecordCheck({ adminEmail: fields.email });
		console.log("ORG ID CHECKER: ", check.data.organizationId);
		console.log("USERROLLE CHECKER: ", check.data.userRole);

		let organizationId = check.data.organizationId;
		console.log("DOUBLE CHEK IT NOW: ", organizationId);

		dispatch(setUserOrganizationId(organizationId));

		return {
			organizationId: organizationId,
			city: city,
			stateOf: stateOf,
			zipCode: zipCode,
			country: country,
		};

		// 711 East Okeechobee Road, Hialeah, FL 33010, USA
	};

	/**
	 *
	 * @function selectSuggestion
	 *
	 */
	const useSelectBillToSuggestion = async (suggestion) => {
		try {
			let streetAddress = suggestion.label;

			let apiKey = config.Places_API_KEY;
			let reg = regexAddress(streetAddress);

			streetAddress = reg[0];

			let url =
				"https://maps.googleapis.com/maps/api/geocode/json?address=";

			let address = streetAddress.split(" ");

			let query = "";

			for (let i = 0; i < address.length; i++) {
				if (i === address.length - 1) {
					query = query + address[i];
				} else {
					query = query + address[i] + "+";
				}
			}

			// build the google api url we need to get location data
			url += query + "+" + reg[1] + "+" + reg[2] + "&key=" + apiKey;

			// NOTE: call the zipCodeSearch function so that
			//       we can extract location data from google
			//       response inside of formatted_address field
			let { organizationId, city, stateOf, zipCode, country } =
				await zipCodeSearch(url);

			typeof zipCode === "string" && /^\d{5}$/.test(zipCode)
				? console.log("zipCode is Good: ", zipCode)
				: (zipCode = "INVALID");

			let billToAddress = {
				streetAddress:
					typeof zipCode === "string" && /^\d{5}$/.test(zipCode)
						? streetAddress
						: "INVALID",
				streetAddressLine2: "",
				city: city,
				stateOf: stateOf,
				zipCode: zipCode,
				country: country,
			};

			dispatch(setUserBillingAddress(billToAddress));

			// NOTE: dispath values here to prep for back button in next step
			dispatch(setFirstName(fields.firstName.trim()));
			dispatch(setLastName(fields.lastName.trim()));
			dispatch(setCorporateName(fields.corporateName.trim()));
			dispatch(setUserEmail(fields.email.trim()));

			//dispatch(setUserBillingAddress(billToAddress));
		} catch (err) {
			console.log("SELECT SUGGESTION ADDRESS CLEARED: ", err);
		}
	};

	const renderConfirmationForm = () => {
		//
		return (
			//
			<Form
				onSubmit={handleConfirmationSubmit}
				className="registration-form"
			>
				<Form.Group controlId="confirmationCode" size="lg">
					<Form.Label>Confirmation Code</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="tel"
						onChange={handleFieldChange}
						value={fields.confirmationCode}
						className="nucleus-form-fields"
					/>
					<Form.Text muted>
						Please check your email and your SPAM folder for the
						code or verification link sent from a{" "}
						<code>DepoApp.com</code> email address.
					</Form.Text>
				</Form.Group>

				{affiliateId !== "NONE" && (
					<Form.Group controlId="affiliateId" size="lg" id="fg3">
						<Form.Label>Affiliate Code</Form.Label>
						<Form.Control
							onFocus={handleFocus}
							type="test"
							value={affiliateId}
							onChange={handleFieldChange}
							className="nucleus-form-fields-read-only"
							readOnly
						/>
						<Form.Text muted>Just a test.</Form.Text>
					</Form.Group>
				)}

				<section className="registration-submit-btn">
					<LoaderButton
						block="true"
						size="lg"
						type="submit"
						variant="success"
						isLoading={isLoading}
						disabled={!validateConfirmationForm()}
					>
						Verify
					</LoaderButton>
				</section>
			</Form>
		);
	};

	/**
	 *
	 * 	renders the KYC form in the 1st registration step
	 *
	 *
	 **/
	const renderKycForm = () => {
		//
		return (
			<Form onSubmit={handleSubmit} className="registration-form">
				<Form.Group controlId="email" size="lg">
					<Form.Label>Corporate Email</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="email"
						value={fields.email}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						required={true}
					/>
				</Form.Group>

				<Container fluid id="fg4c" className="no-side-padding-register">
					<Row className="contain-row-register">
						<Col className="right-side-padding-register">
							<Form.Group controlId="firstName" size="lg">
								<Form.Label>First Name</Form.Label>
								<Form.Control
									onFocus={handleFocus}
									type="text"
									value={fields.firstName}
									onChange={handleFieldChange}
									className="nucleus-form-fields"
									required={true}
								/>
							</Form.Group>
						</Col>
						<Col className="left-side-padding-register">
							<Form.Group controlId="lastName" size="lg">
								<Form.Label>Last Name</Form.Label>
								<Form.Control
									onFocus={handleFocus}
									type="text"
									value={fields.lastName}
									onChange={handleFieldChange}
									className="nucleus-form-fields"
									required={true}
								/>
							</Form.Group>
						</Col>
					</Row>
				</Container>

				<Form.Group controlId="corporateName" size="lg" id="fg4a">
					<Form.Label>Company Name</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="text"
						value={fields.corporateName}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						placeholder="ACME Industries, LLC"
						required={true}
					/>
				</Form.Group>

				<Form.Group controlId="streetAddress" id="fg4a">
					<Form.Label>Street Address</Form.Label>
					<Geosuggest
						ref={geosuggestEl}
						initialValue={
							isNaN(billingAddress.streetAddress) ||
							billingAddress.streetAddress.length > 0
								? billingAddress.streetAddress
								: fields.streetAddress
						}
						placeholder="Enter your Street Address!"
						country="us"
						onSuggestSelect={useSelectBillToSuggestion}
						location={
							new google.maps.LatLng(40.0038731, -75.0679655)
						}
						radius="5000000"
						readOnly={
							fields.corporateName.length > 0 ? false : true
						}
						required={true}
					/>
				</Form.Group>

				<Form.Group controlId="streetAddressLine2" size="lg" id="fg4a">
					<Form.Label>Suite or Apartment</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="streetAddressLine2"
						value={
							isNaN(billingAddress.streetAddressLine2) ||
							billingAddress.streetAddressLine2.length > 0
								? billingAddress.streetAddressLine2
								: fields.streetAddressLine2
						}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						readOnly={
							fields.corporateName.length > 0 ? false : true
						}
					/>
				</Form.Group>

				<Container fluid id="fg4b" className="no-side-padding-register">
					<Row className="contain-row-register">
						<Col className="right-side-padding-register">
							<Form.Group controlId="city" size="lg">
								<Form.Label>City</Form.Label>
								<Form.Control
									onFocus={handleFocus}
									type="text"
									value={
										isNaN(billingAddress.city) ||
										billingAddress.city.length > 0
											? billingAddress.city
											: fields.city
									}
									onChange={handleFieldChange}
									className="nucleus-form-fields-read-only"
									readOnly
									placeholder="auto"
								/>
							</Form.Group>
						</Col>
						<Col className="left-side-padding-register">
							<Form.Group controlId="zipCode" size="lg">
								<Form.Label>Zip Code</Form.Label>
								<Form.Control
									onFocus={handleFocus}
									type="text"
									value={
										isNaN(billingAddress.zipCode) ||
										billingAddress.zipCode.length > 0
											? billingAddress.zipCode
											: fields.zipCode
									}
									onChange={handleFieldChange}
									className="nucleus-form-fields-read-only"
									readOnly
									placeholder="fill"
								/>
							</Form.Group>
						</Col>
					</Row>
				</Container>

				<section className="registration-submit-btn">
					<LoaderButton
						block="true"
						size="lg"
						type="button"
						variant="success"
						onClick={() => {
							dispatch(setUserEmail(fields.email.toLowerCase()));
							if (fields.streetAddressLine2.length > 0) {
								dispatch(
									setUserBillingAddress({
										streetAddress:
											billingAddress.streetAddress,
										streetAddressLine2:
											fields.streetAddressLine2,
										city: billingAddress.city,
										stateOf: billingAddress.stateOf,
										zipCode: billingAddress.zipCode,
										country: billingAddress.country,
									})
								);
							}
							setStep("1");
						}}
						isLoading={isLoading}
						disabled={!validateKycForm()}
					>
						Next
					</LoaderButton>
				</section>
			</Form>
		);
	};

	/**
	 *
	 * 	renders the password and user role selection form
	 * 	in the 2nd registration step
	 *
	 *
	 **/
	const renderPasswordForm = () => {
		console.log(
			"Passing signatureRawData to HandSigned:",
			signatureRawData
		);

		let confirmPW = "do-not-display";
		// Check if the confirm password matches the password
		const confirmPasswordMatch = fields.password !== fields.confirmPassword;

		if (confirmPasswordMatch) {
			confirmPW = "pw-no-match";
		}

		let lawFirmClass = showSelectedRadio.lawFirmClass;
		let freelancerClass = showSelectedRadio.freelancerClass;

		console.log("TESTING FIELD CHANGES in PW RENDER: ", fields);

		console.log(
			"TEST showSelectedRadio LAW FIRM: ",
			showSelectedRadio.lawFirmClass
		);
		console.log(
			"TEST showSelectedRadio FREELANCER: ",
			showSelectedRadio.freelancerClass
		);

		//
		console.log("Freelancer vs Lawyer: ", fields);
		return (
			<Form onSubmit={handleSubmit} className="registration-form">
				<Form.Group controlId="cellPhone" size="lg">
					<Form.Label>Mobile Phone</Form.Label>

					<PhoneInput
						country={"us"}
						onlyCountries={[
							"us",
							"ca",
							"de",
							"fr",
							"es",
							"gb",
							"in",
						]}
						autoFormat={true}
						value={fields.cellPhone}
						countryCodeEditable={false}
						onChange={(cellPhone) =>
							setFieldValue("cellPhone", cellPhone)
						}
						inputProps={{
							name: "cellPhone",
							required: true,
							autoFocus: false,
						}}
						isValid={(value, country) => {
							if (value.match(/12345/)) {
								return (
									"Invalid value: " + value + ", " + country
								);
							} else if (value.match(/1234/)) {
								return false;
							} else {
								return true;
							}
						}}
						className="nucleus-form-fields"
					/>
				</Form.Group>

				{!isDepoAppDomain(fields.email) && (
					<Form.Group controlId="userRole" size="lg" id="fg3">
						<Form.Label>Select your Primary Role</Form.Label>

						<div>
							<Form.Check
								inline
								type="radio"
								label="Lawyer/Law Firm/Legal Assistant"
								name="lawyer-radio"
								value="Law_Firm"
								checked={
									fields.userRole === "Law_Firm" ||
									fields.userRole === "Law_Firm_Admin" ||
									fields.userRole === "Law_Firm_Manager" ||
									fields.userRole === "Lawyer" ||
									fields.userRole === "Legal_Assistant"
								}
								onChange={handleFieldChange}
								id={"userRoleRadioLawFirm"}
							/>

							<Form.Select
								aria-label="Client Onboarding"
								className={lawFirmClass}
								onChange={handleFieldChange}
								id={"userRoleSelectLawFirm"}
								value={fields?.userRoleSelectLawFirm}
							>
								<option>Select Role</option>

								<option value="Law_Firm_Admin">
									Law Firm Administrator
								</option>

								<option value="Law_Firm_Manager">
									Law Firm Manager
								</option>

								<option value="Lawyer">Lawyer</option>

								<option value="Legal_Assistant">
									Legal Assistant
								</option>
							</Form.Select>

							<Form.Check
								inline
								type="radio"
								label="Freelancer"
								name="freelancer-radio"
								value="Freelancer"
								checked={
									fields.userRole === "Freelancer" ||
									fields.userRole === "Court_Reporter" ||
									fields.userRole === "Scopist" ||
									fields.userRole === "Videgrapher" ||
									fields.userRole === "Interpreter"
								}
								onChange={handleFieldChange}
								id={"userRoleRadioFreelancer"}
							/>

							<Form.Select
								aria-label="Freelancer Onboarding"
								className={freelancerClass}
								onChange={handleFieldChange}
								id={"userRoleSelectFreelancer"}
								value={fields?.userRoleSelectFreelancer}
							>
								<option>Select Role</option>
								<option value="Court_Reporter">
									Court Reporter*
								</option>
								<option value="Scopist">Scopist</option>
								<option value="Videgrapher">Videgrapher</option>
								<option value="Interpreter">Interpreter</option>
							</Form.Select>
						</div>

						<Form.Text muted style={{ fontSize: "12px" }}>
							Your permissions on platform are set by your role.
						</Form.Text>
					</Form.Group>
				)}

				<Form.Group controlId="password" size="lg" id="fg3">
					<Form.Label>Password</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						autoComplete="new-password"
					/>

					{!isPasswordValid && (
						<Form.Text
							style={{ fontSize: "12px" }}
							className="invalid-pw"
						>
							Must use eight characters, include at least one
							number and use both lower and uppercase letters, and
							special characters, for example{" "}
							<code style={{ color: "red" }}>
								^ $ * . [ ] {} ( ) ? - " ! @ # % & / \ , > ' : ;
								| _ ~ ` + =
							</code>
						</Form.Text>
					)}
				</Form.Group>

				<Form.Group controlId="confirmPassword" size="lg" id="fg4">
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						onFocus={handleFocus}
						type="password"
						value={fields.confirmPassword}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
						autoComplete="new-password"
					/>

					<Form.Text
						style={{ fontSize: "12px" }}
						className={confirmPW}
					>
						Passwords must match.
					</Form.Text>
				</Form.Group>

				<Form.Group
					controlId="termsCheckbox"
					className="terms-checkbox"
				>
					<Form.Check
						type="checkbox"
						value={
							fields.termsAccepted === true &&
							signatureRawData !== "" &&
							signatureRawData !== [[]] &&
							signatureRawData.length >= 1
								? true
								: false
						}
						checked={
							fields.termsAccepted === true &&
							signatureRawData !== "" &&
							signatureRawData !== [[]] &&
							signatureRawData.length >= 1
								? true
								: false
						}
						label="I have read and agree to the Terms and Conditions"
						onChange={() => {
							fields.termsAccepted =
								fields.termsAccepted === true &&
								signatureRawData !== "" &&
								signatureRawData !== [[]] &&
								signatureRawData.length >= 1
									? true
									: false;
						}}
						onClick={() => {
							disableBackgroundScroll(true);
							setShowModal(true);
							dispatch(isModalOpen(true));
							console.log("SHOWING THE MODAL");
						}}
					/>
				</Form.Group>
				<Row style={{ display: "flex", minWidth: "100%" }}>
					<Col style={{ width: "50%" }}>
						<section className="registration-submit-btn">
							<LoaderButton
								block="true"
								size="lg"
								variant="success"
								onClick={() => {
									setStep("0");
								}} // Navigate to /register route
								isLoading={isLoading}
							>
								Back
							</LoaderButton>
						</section>
					</Col>
					<Col style={{ width: "50%" }}>
						<section className="registration-submit-btn">
							<LoaderButton
								id="registerButton1"
								block="true"
								size="lg"
								type="submit"
								variant="success"
								isLoading={isLoading}
								disabled={!validatePasswordForm()}
							>
								Register
							</LoaderButton>
						</section>
					</Col>
				</Row>
			</Form>
		);
	};

	return (
		<>
			<Main>
				<article className="Registration">
					{user === null
						? step === "0"
							? renderKycForm()
							: renderPasswordForm() //renderPasswordForm()
						: renderConfirmationForm()}
				</article>
			</Main>

			<Modal
				show={showModal}
				onHide={() => {
					setShowModal(false);
				}}
				onExited={() => {
					console.log("EXITED");
					dispatch(isModalOpen(false));
				}}
				size="lg"
			>
				<Modal.Header closeButton>
					<Modal.Title>Terms and Conditions</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<div className="markdown-content">
						<Markdown>{MD}</Markdown>
					</div>

					<div className="signature-pad-title">
						<h4>X_Signature Pad</h4>
					</div>
					<HandSigned
						ref={signaturePadRef}
						color="#0033CC"
						backgroundColor=""
						borderColor=""
						initialData={signatureRawData}
						style={{
							width: handSignedDimensions.width,
							height: handSignedDimensions.height,
						}}
						className="signature-pad signature-pad-skeuomorphic"
					/>
				</Modal.Body>
				<Modal.Footer>
					<Button
						variant="secondary"
						onClick={() => {
							signaturePadRef.current.clear();
							setAnyFieldValue("signature", "");
							setAnyFieldValue("termsAccepted", false);
							setIsSignatureDrawn(false);
							console.log("SIGNATURE CLEARED");
						}}
					>
						Clear
					</Button>
					<Button
						variant="primary"
						onClick={() => {
							const signatureData =
								signaturePadRef.current.getDataURL();
							setAnyFieldValue("signature", signatureData);
							setAnyFieldValue("termsAccepted", true);
							// Ensure isSignatureDrawn or similar state is correctly updated to reflect the presence of a signature
							setIsSignatureDrawn(true);
							// Logic to close the modal, assuming setShowModal controls the modal visibility

							setShowModal(false);
							disableBackgroundScroll(false);
							console.log("Signature accepted and modal closed");
							console.log(
								"SIGNATURE ACCEPTED TEST",
								signaturePadRef.current?.getRawData()
							);
						}}
						disabled={!isSignatureDrawn}
					>
						Accept & Sign
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};

export default Register;
