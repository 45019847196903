import { useEffect } from "react";
import Main from "../../layouts/Main";
import "./Page404.css";

const Page404 = () => {
  //const [fetchItem, , items] = useApiCall(getItems);
  useEffect(() => {
    //
  }, []);

  return (
    <>
      <Main>
        <article className="Page404">
          <div className="unavailable-content">
            <h1>404 Error; Page unavailable!</h1>
            <p>
              Unavailable Content. Try again soon or give us a call and we will
              build it for you! 305.306.8617
            </p>
          </div>
        </article>
      </Main>
    </>
  );
};

export default Page404;
