import React, { useState, useContext, useEffect } from "react";
//import Media from "react-media";
import { useNavigate } from "react-router-dom";
import { Form, Button, Container, Row, Col, Table } from "react-bootstrap";
import "./SystemAdminDashboard.css"; // Assuming your CSS file is named Dashboard.css

import {
  getUsersByRole,
  listDepositions,
  getOrganizations,
  listUsersByOrganization,
  listSupportQueries,
  updateUser,
} from "../../libs/apiService";
import { ApplicationContext } from "../../context/GlobalState";

const SystemAdminDashboard = () => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [statusUpdates, setStatusUpdates] = useState({});
  const [lawyersList, setLawyersList] = useState([]);
  const [courtReportersList, setCourtReportersList] = useState([]);
  const [depositionsList, setDepositionsList] = useState([]);
  const [supportQueriesList, setSupportQueriesList] = useState([]);
  const [paginationKeys, setPaginationKeys] = useState([]);
  const [selectedButton, setSelectedButton] = useState(null);
  const [organizations, setOrganizations] = useState([]);
  const [selectedOrganization, setSelectedOrganization] = useState(null);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const navigate = useNavigate();

  // logged in user's orgId
  const { organizationId, barRef } = state;

  useEffect(() => {
    //
    fetchOrganizations();
    setSelectedButton("lawyers");
    fetchLawyers("lawyers");
  }, []);

  useEffect(() => {
    console.log("CURR_STATE_SYS_ADMIN: ", state);
  }, [state]);

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  const fetchOrganizations = async () => {
    setLoading(true);
    try {
      const response = await getOrganizations(state.user.userRecordId);
      setOrganizations(response);
    } catch (error) {
      console.error("Failed to fetch organizations", error);
    }
    setLoading(false);
  };

  const fetchUsersByOrganization = async (orgId) => {
    setLoading(true);
    try {
      const response = await listUsersByOrganization({ organizationId: orgId });
      setUsers(response);
    } catch (error) {
      console.error("Failed to fetch users by organization", error);
    }
    setLoading(false);
  };

  const resetFilters = () => {
    setSelectedOrganization(null);
    setSelectedUser(null);
    setUsers([]);
  };

  // get list of lawyers
  const fetchLawyers = async (buttonId) => {
    setLoading(true);
    setSelectedButton(buttonId);
    resetFilters();
    setCourtReportersList([]); // Clear court reporters list
    setDepositionsList([]); // Clear depositions list
    setSupportQueriesList([]); // Clear support queries list

    try {
      const payload = selectedOrganization
        ? { organizationId: selectedOrganization }
        : {}; // NOTE: Replace with actual organization ID for the org specific filter
      const response = await getUsersByRole("Lawyer", payload);
      setLawyersList(response);
    } catch (error) {
      console.error("Failed to fetch lawyers", error);
    }
    setLoading(false);
  };

  // get list of court reporters
  const fetchCourtReporters = async (buttonId) => {
    setLoading(true);
    setSelectedButton(buttonId);
    resetFilters();
    setLawyersList([]); // Clear lawyers list
    setDepositionsList([]); // Clear depositions list
    setSupportQueriesList([]); // Clear support queries list
    try {
      const payload = selectedOrganization
        ? { organizationId: selectedOrganization }
        : {}; // NOTE: Replace with actual organization ID for the org specific filter
      const response = await getUsersByRole("Court_Reporter", payload);
      setCourtReportersList(response);
    } catch (error) {
      console.error("Failed to fetch court reporters", error);
    }
    setLoading(false);
  };

  const fetchDepositions = async (buttonId) => {
    setLoading(true);
    setSelectedButton(buttonId);
    resetFilters();
    setLawyersList([]); // Clear lawyers list
    setCourtReportersList([]); // Clear court reporters list
    setSupportQueriesList([]); // Clear support queries list
    try {
      const response = await listDepositions("all");
      setDepositionsList(response.data);
    } catch (error) {
      console.error("Failed to fetch depositions", error);
    }
    setLoading(false);
  };

  const fetchSupportQueries = async (buttonId) => {
    setLoading(true);
    setSelectedButton(buttonId);
    resetFilters();
    setLawyersList([]); // Clear lawyers list
    setCourtReportersList([]); // Clear court reporters list
    setDepositionsList([]); // Clear depositions list
    try {
      const response = await listSupportQueries("all");
      setSupportQueriesList(response);
    } catch (error) {
      console.error("Failed to fetch support queries", error);
    }
    setLoading(false);
  };

  const handleOrganizationChange = async (e) => {
    setLoading(true);
    const orgId = e.target.value;
    setSelectedOrganization(orgId);
    fetchUsersByOrganization(orgId);

    if (selectedButton === "depositions") {
      const response = await listDepositions(orgId);
      setDepositionsList(response.data);
    } else if (selectedButton === "support-queries") {
      const response = await listSupportQueries({ organizationId: orgId });
      setSupportQueriesList(response);
    } else {
      // Fetch lawyers or court reporters based on the selected button
      if (selectedButton === "lawyers") {
        fetchLawyers(selectedButton);
      } else if (selectedButton === "court-reporters") {
        fetchCourtReporters(selectedButton);
      }
    }
    setLoading(false);
  };

  const handleUserChange = async (e) => {
    setLoading(true);
    const userId = e.target.value;
    setSelectedUser(userId);

    if (selectedButton === "depositions") {
      const response = await listDepositions(selectedOrganization, userId);
      setDepositionsList(response.data);
    } else if (selectedButton === "support-queries") {
      const response = await listSupportQueries({
        organizationId: selectedOrganization,
        userRecordId: userId,
      });
      setSupportQueriesList(response);
    }
    setLoading(false);
  };

  // TODO: Need to create edit views
  // FIXEME: Instead of new right use modal
  const handleRowClick = (lawyerId) => {
    // Navigate to detail/edit screen
    navigate(`/lawyers/${lawyerId}`);
  };

  const renderStatusActions = (user) => {
    const { accountStatus, userRecordId, organizationId } = user;

    const handleButtonClick = async (newStatus) => {
      // Update the status in a temporary state mapping
      setStatusUpdates((prev) => ({ ...prev, [userRecordId]: newStatus }));
      // Make API call to update status
      await updateUser(organizationId, userRecordId, {
        accountStatus: newStatus,
      });
      // Update the user list to reflect the new status
      if (selectedButton === "lawyers") {
        fetchLawyers(selectedButton);
      } else if (selectedButton === "court-reporters") {
        fetchCourtReporters(selectedButton);
      }
    };

    const effectiveStatus =
      accountStatus === "APPEALED - APPROVED" ? "APPROVED" : accountStatus;

    switch (effectiveStatus) {
      case "INQUIRY":
        return (
          <>
            <button
              className="card-button approve"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("APPROVED");
              }}
              title="Approve"
            >
              ✔️
            </button>
            <button
              className="card-button reject"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("REJECTED");
              }}
              title="Reject"
            >
              ❌
            </button>
          </>
        );
      case "APPROVED":
        return (
          <>
            <button
              className="card-button pause"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("PAUSED");
              }}
              title="Pause"
            >
              ⏸️
            </button>
            <button
              className="card-button cancel"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("CANCELLED");
              }}
              title="Cancel"
            >
              ❌
            </button>
          </>
        );
      case "CANCELLED":
      case "REJECTED":
        return (
          <>
            <button
              className="card-button appeal-approve"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("APPROVED");
              }}
              title="Appeal Approve"
            >
              ✔️
            </button>
            <button
              className="card-button appeal-deny"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("APPEALED - DENIED");
              }}
              title="Appeal Deny"
            >
              ❌
            </button>
          </>
        );
      case "PAUSED":
        return (
          <>
            <button
              className="card-button restart"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("APPROVED");
              }}
              title="Restart"
            >
              🔄
            </button>
            <button
              className="card-button delete"
              onClick={(e) => {
                e.stopPropagation();
                // FIXME: Placeholder for delete function
                console.log("Delete function to be implemented");
              }}
              title="Delete"
            >
              🗑️
            </button>
          </>
        );
      case "UNVERIFIED_EMPLOYEE":
        return (
          <>
            <button
              className="card-button hire"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("HIRED");
              }}
              title="Hire"
            >
              ✔️
            </button>
            <button
              className="card-button deny"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("DENIED");
              }}
              title="Deny"
            >
              ❌
            </button>
          </>
        );
      case "HIRED":
        return (
          <>
            <button
              className="card-button fire"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("FIRED");
              }}
              title="Fire"
            >
              🔥
            </button>
            <button
              className="card-button leave"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("ON_LEAVE");
              }}
              title="Leave"
            >
              🏖️
            </button>
          </>
        );
      case "FIRED":
        return (
          <>
            <button
              className="card-button appeal-rehire"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("HIRED");
              }}
              title="Appeal Rehire"
            >
              ✔️
            </button>
            <button
              className="card-button appeal-fire"
              onClick={(e) => {
                e.stopPropagation();
                handleButtonClick("APPEALED - FIRED");
              }}
              title="Appeal Fire"
            >
              ❌
            </button>
          </>
        );
      default:
        return null;
    }
  };

  return (
    <>
      <Container fluid className="dashboard-container-sa mt-3">
        <Row className="g-4">
          <Col className="min-w-100-sa-dash">
            <Button
              variant="lawyers"
              size="lg"
              className={`dashboard-button btn-lawyers ${
                selectedButton === "lawyers" ? "selected" : ""
              }`}
              block="true"
              onClick={() => fetchLawyers("lawyers")} // Fetch lawyers on click
            >
              <div className="content-wrapper">
                <div className="number-icon">18</div>
                <p className="sa-button-label">Lawyers</p>
              </div>
            </Button>
          </Col>
          <Col className="min-w-100-sa-dash">
            <Button
              variant="court-reporters"
              size="lg"
              className={`dashboard-button btn-court-reporters ${
                selectedButton === "court-reporters" ? "selected" : ""
              }`}
              block="true"
              onClick={() => fetchCourtReporters("court-reporters")}
            >
              <div className="content-wrapper">
                <div className="number-icon">18</div>
                <p className="sa-button-label">Court Reporters</p>
              </div>
            </Button>
          </Col>
          <Col className="min-w-100-sa-dash">
            <Button
              variant="depositions"
              size="lg"
              className={`dashboard-button btn-depositions ${
                selectedButton === "depositions" ? "selected" : ""
              }`}
              block="true"
              onClick={() => fetchDepositions("depositions")}
            >
              <div className="content-wrapper">
                <div className="number-icon">18</div>
                <p className="sa-button-label">Depositions</p>
              </div>
            </Button>
          </Col>
          <Col className="min-w-100-sa-dash">
            <Button
              variant="support-queries"
              size="lg"
              className={`dashboard-button btn-support-queries ${
                selectedButton === "support-queries" ? "selected" : ""
              }`}
              block="true"
              onClick={() => fetchSupportQueries("support-queries")}
            >
              <div className="content-wrapper">
                <div className="number-icon">18</div>
                <p className="sa-button-label">Support Queries</p>
              </div>
            </Button>
          </Col>
        </Row>
        {/* Filter Row */}
        <Row className="g-4 mt-0 d-flex">
          <Col className="responsive-filter">
            <Form.Group controlId="organizationSelect">
              <Form.Label>Select Organization</Form.Label>
              <Form.Control as="select" onChange={handleOrganizationChange}>
                <option value="">Select an organization</option>
                {organizations.map((org) => (
                  <option key={org.organizationId} value={org.organizationId}>
                    {org.organizationName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>
          <Col className="responsive-filter">
            <Form.Group controlId="userSelect">
              <Form.Label>Select User</Form.Label>
              <Form.Control
                as="select"
                onChange={handleUserChange}
                disabled={!selectedOrganization}
              >
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user.userRecordId} value={user.userRecordId}>
                    {user.lastName}, {user.firstName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </Col>

          <div className="mobile-filter-view">
            <Form.Group controlId="organizationSelect">
              <Form.Label>Select Organization</Form.Label>
              <Form.Control as="select" onChange={handleOrganizationChange}>
                <option value="">Select an organization</option>
                {organizations.map((org) => (
                  <option key={org.organizationId} value={org.organizationId}>
                    {org.organizationName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
          <div className="mobile-filter-view">
            <Form.Group controlId="userSelect">
              <Form.Label>Select User</Form.Label>
              <Form.Control
                as="select"
                onChange={handleUserChange}
                disabled={!selectedOrganization}
              >
                <option value="">Select a user</option>
                {users.map((user) => (
                  <option key={user.userRecordId} value={user.userRecordId}>
                    {user.firstName} {user.lastName}
                  </option>
                ))}
              </Form.Control>
            </Form.Group>
          </div>
        </Row>
        {/* Mobile Cards */}
        <Row className="g-4 mobile-card-view">
          <Col className="min-w-100-sa-dash">
            {lawyersList.length > 0 &&
              lawyersList.map((lawyer, index) => (
                <Col xs={12} key={index}>
                  <div
                    className="mobile-card"
                    onClick={() => handleRowClick(lawyer.userRecordId)}
                  >
                    <div className="mobile-card-content">
                      {/* Lawyer details */}
                      <div>
                        <strong>Name:</strong>{" "}
                        {`${lawyer.lastName}, ${lawyer.firstName}`}
                      </div>
                      <div>
                        <strong>Email ID:</strong> {lawyer.email}
                      </div>
                      <div>
                        <strong>Mobile:</strong> {lawyer.cellPhone}
                      </div>
                      <div>
                        <strong>State:</strong> {lawyer.operatingState}
                      </div>
                      <div className="status-actions">
                        <strong>Status:</strong>{" "}
                        {statusUpdates[lawyer.userRecordId] ||
                          lawyer.accountStatus}
                        {renderStatusActions(lawyer)}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
          </Col>
        </Row>

        {/* Lawyers List */}
        {selectedButton === "lawyers" && lawyersList.length > 0 && (
          <Row className="mt-4">
            <Col>
              <Table striped bordered hover className="responsive-table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Name</th>
                    <th>Email ID</th>
                    <th>Mobile Number</th>
                    <th>State</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {lawyersList.map((lawyer, index) => (
                    <tr
                      key={index}
                      onClick={() => handleRowClick(lawyer.userRecordId)}
                    >
                      <td>{index + 1}</td>
                      <td>{`${lawyer.lastName}, ${lawyer.firstName}`}</td>
                      <td>{lawyer.email}</td>
                      <td>{lawyer.cellPhone}</td>
                      <td>{lawyer.operatingState}</td>
                      <td>
                        {statusUpdates[lawyer.userRecordId] ||
                          lawyer.accountStatus}{" "}
                        {renderStatusActions(lawyer)}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        {selectedButton === "court-reporters" && courtReportersList.length > 0 && (
          <>
            {/* Mobile Cards View for Court Reporters */}
            <Row className="g-4 mobile-card-view">
              {courtReportersList.map((reporter, index) => (
                <Col xs={12} key={index}>
                  <div
                    className="mobile-card"
                    onClick={() => handleRowClick(reporter.userRecordId)} // Assuming handleRowClick navigates to the detail/edit screen for the court reporter
                  >
                    <div className="mobile-card-content">
                      <div>
                        <strong>Name:</strong>{" "}
                        {`${reporter.lastName}, ${reporter.firstName}`}
                      </div>
                      <div>
                        <strong>Email ID:</strong> {reporter.email}
                      </div>
                      <div>
                        <strong>Mobile:</strong> {reporter.cellPhone}
                      </div>
                      <div>
                        <strong>State:</strong> {reporter.operatingState}
                      </div>
                      <div className="status-actions">
                        <strong>Status:</strong>{" "}
                        {statusUpdates[reporter.userRecordId] ||
                          reporter.accountStatus}
                        {renderStatusActions(reporter)}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>

            {/* Table View for Court Reporters on Desktop */}
            <Row className="mt-4">
              <Col>
                <Table striped bordered hover className="responsive-table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Name</th>
                      <th>Email ID</th>
                      <th>Mobile Number</th>
                      <th>State</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {courtReportersList.map((reporter, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(reporter.userRecordId)}
                      >
                        <td>{index + 1}</td>
                        <td>{`${reporter.lastName}, ${reporter.firstName}`}</td>
                        <td>{reporter.email}</td>
                        <td>{reporter.cellPhone}</td>
                        <td>{reporter.operatingState}</td>
                        <td>
                          {statusUpdates[reporter.userRecordId] ||
                            reporter.accountStatus}{" "}
                          {renderStatusActions(reporter)}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
          </>
        )}
        {selectedButton === "depositions" && depositionsList.length > 0 && (
          <>
            <Row className="mt-4" style={{ overflow: "auto" }}>
              <Col>
                <Table striped bordered hover className="responsive-table">
                  <thead>
                    <tr>
                      <th>S. No.</th>
                      <th>Case Name</th>
                      <th>Depo Date</th>
                      <th>Case Number</th>
                      <th>Location</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    {depositionsList.map((deposition, index) => (
                      <tr
                        key={index}
                        onClick={() => handleRowClick(deposition.depositionId)}
                      >
                        <td>{index + 1}</td>
                        <td>{deposition.caseName}</td>
                        <td>{deposition.depoDate}</td>
                        <td>{deposition.caseNumber}</td>
                        <td>{deposition.locationAddress}</td>
                        <td>{deposition.status}</td>
                      </tr>
                    ))}
                  </tbody>
                </Table>
              </Col>
            </Row>
            <Row className="g-4 mobile-card-view">
              {depositionsList.map((deposition, index) => (
                <Col xs={12} key={index}>
                  <div
                    className="mobile-card"
                    onClick={() => handleRowClick(deposition.depositionId)}
                  >
                    <div className="mobile-card-content">
                      <div>
                        <strong>Case Name:</strong> {deposition.caseName}
                      </div>
                      <div>
                        <strong>Depo Date:</strong> {deposition.depoDate}
                      </div>
                      <div>
                        <strong>Depo Time:</strong> {deposition.depoTime}
                      </div>
                      <div>
                        <strong>Duration:</strong> {deposition.expectedDuration}
                      </div>
                      <div>
                        <strong>Case Number:</strong> {deposition.caseNumber}
                      </div>
                      <div>
                        <strong>Witness:</strong> {deposition.witnessName}
                      </div>
                      <div>
                        <strong>Location:</strong> {deposition.locationAddress}
                      </div>
                      <div>
                        <strong>Status:</strong> {deposition.status}
                      </div>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </>
        )}

        {selectedButton === "support-queries" && supportQueriesList.length > 0 && (
          <Row className="mt-4">
            <Col>
              <Table striped bordered hover className="responsive-table">
                <thead>
                  <tr>
                    <th>S. No.</th>
                    <th>Query</th>
                    <th>Submitted By</th>
                    <th>Organization</th>
                    <th>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {supportQueriesList.map((query, index) => (
                    <tr key={index} onClick={() => handleRowClick(query.id)}>
                      <td>{index + 1}</td>
                      <td>{query.query}</td>
                      <td>{query.submittedBy}</td>
                      <td>{query.organization}</td>
                      <td>{query.status}</td>
                    </tr>
                  ))}
                </tbody>
              </Table>
            </Col>
          </Row>
        )}
        {/* Pagination Buttons */}
        <Row className="mt-4">
          <Col>
            {paginationKeys.length > 1 && (
              <Button
                onClick={() => {
                  if (selectedButton === "lawyers") {
                    fetchLawyers(paginationKeys[paginationKeys.length - 2]);
                  } else if (selectedButton === "court-reporters") {
                    fetchCourtReporters(
                      paginationKeys[paginationKeys.length - 2]
                    );
                  }
                }}
              >
                Previous
              </Button>
            )}
            {(selectedButton === "lawyers" && lawyersList.length > 0) ||
            (selectedButton === "court-reporters" &&
              courtReportersList.length > 0) ? (
              <Button
                onClick={() => {
                  if (selectedButton === "lawyers") {
                    fetchLawyers(paginationKeys[paginationKeys.length - 1]);
                  } else if (selectedButton === "court-reporters") {
                    fetchCourtReporters(
                      paginationKeys[paginationKeys.length - 1]
                    );
                  }
                }}
              >
                Next
              </Button>
            ) : null}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default SystemAdminDashboard;
