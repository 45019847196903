import React, { useRef, useState, useEffect, useImperativeHandle } from "react";

const HandSigned = React.forwardRef(
  (
    {
      color = "#000000",
      backgroundColor = "#ffffff",
      borderColor = "#cccccc",
      initialData = [],
      width = 700,
      height = 300,
      ...props
    },
    ref
  ) => {
    const canvasRef = useRef(null);
    const [isDrawing, setIsDrawing] = useState(false);
    const [rawData, setRawData] = useState(
      Array.isArray(initialData) ? initialData : []
    );

    useEffect(() => {
      // Ensure the canvas element is present
      if (canvasRef.current) {
        const canvas = canvasRef.current;

        // Define the event handlers
        const preventTouchStart = (e) => e.preventDefault();
        const preventTouchMove = (e) => e.preventDefault();

        // Add event listeners to the canvas
        canvas.addEventListener("touchstart", preventTouchStart);
        canvas.addEventListener("touchmove", preventTouchMove);

        // Cleanup function to remove event listeners
        return () => {
          canvas.removeEventListener("touchstart", preventTouchStart);
          canvas.removeEventListener("touchmove", preventTouchMove);
        };
      }
    }, []); // Empty dependency array means this effect runs once on mount

    // Initialize canvas and context
    useEffect(() => {
      console.log("Received rawData in HandSigned:", rawData);
      const canvas = canvasRef.current;
      const context = canvas.getContext("2d");
      context.clearRect(0, 0, canvas.width, canvas.height); // Clear canvas first
      context.strokeStyle = color;
      // Draw existing strokes
      rawData.forEach((stroke) => {
        if (stroke.length) {
          context.beginPath();
          stroke.forEach((point, index) => {
            if (index === 0) {
              context.moveTo(point.x, point.y);
            } else {
              context.lineTo(point.x, point.y);
              context.stroke();
            }
          });
        }
      });
    }, [rawData, color]); // Redraw when rawData or color changes

    useEffect(() => {
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.style.backgroundColor = backgroundColor;
      }
    }, [backgroundColor]); // Re-run this effect if backgroundColor changes

    useEffect(() => {
      // This effect updates the canvas border color
      const canvas = canvasRef.current;
      if (canvas) {
        canvas.style.borderColor = borderColor;
      }
    }, [borderColor]); // Re-run this effect if borderColor changes

    // Drawing start
    const startDrawing = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const { x, y } = getCoordinatesFromEvent(event);
      setIsDrawing(true);
      // Start a new stroke
      setRawData([...rawData, [{ x, y }]]);
    };

    // Drawing move
    const handleDrawing = (event) => {
      event.preventDefault();
      event.stopPropagation();
      if (!isDrawing) return;
      const { x, y } = getCoordinatesFromEvent(event);
      const context = canvasRef.current.getContext("2d");
      context.lineTo(x, y);
      context.stroke();
      // Add point to the current (last) stroke
      const newRawData = [...rawData];
      newRawData[newRawData.length - 1].push({ x, y });
      setRawData(newRawData);
    };

    // Drawing end
    const stopDrawing = (event) => {
      if (event) {
        event.preventDefault();
        event.stopPropagation();
      }
      setIsDrawing(false);
    };

    // Convert event coordinates to canvas coordinates
    const getCoordinatesFromEvent = (event) => {
      event.preventDefault();
      event.stopPropagation();
      const canvas = canvasRef.current;
      const rect = canvas.getBoundingClientRect();
      const scaleX = canvas.width / rect.width;
      const scaleY = canvas.height / rect.height;
      let x, y;
      if (event.touches) {
        x = (event.touches[0].clientX - rect.left) * scaleX;
        y = (event.touches[0].clientY - rect.top) * scaleY;
      } else {
        x = (event.clientX - rect.left) * scaleX;
        y = (event.clientY - rect.top) * scaleY;
      }
      return { x, y };
    };

    // Expose methods to parent via ref
    useImperativeHandle(ref, () => ({
      clear: () => {
        const context = canvasRef.current.getContext("2d");
        context.clearRect(
          0,
          0,
          canvasRef.current.width,
          canvasRef.current.height
        );
        setRawData([[]]); // Reset to initial state with one empty stroke
      },
      getRawData: () => rawData,
      getDataURL: () => canvasRef.current.toDataURL(),
    }));

    return (
      <canvas
        style={{
          backgroundColor: backgroundColor,
          border: `1px solid ${borderColor}`,
        }}
        ref={canvasRef}
        {...props}
        width={width}
        height={height}
        onMouseDown={startDrawing}
        onMouseMove={handleDrawing}
        onMouseUp={stopDrawing}
        onMouseOut={stopDrawing}
        onTouchStart={startDrawing}
        onTouchMove={handleDrawing}
        onTouchEnd={stopDrawing}
      />
    );
  }
);

export default HandSigned;
