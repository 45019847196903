//import marketplaceBgLeft from 'assets/images/partials/marketplace-bg-left.svg';
//import marketplaceBgRight from 'assets/images/partials/marketplace-bg-right.svg';
//import { useApiCall } from 'hooks';
//import { getItems } from 'services/apiService';
import React, { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { LoaderButton } from "../../components/LoaderButton";
import { LandingPage } from "../../components/LandingPage";
import { LawyerDashboard } from "../../components/LawyerDashboard";
import { onError } from "../../libs/errorLib";
import Main from "../../layouts/Main";
import { ApplicationContext } from "../../context/GlobalState";
import {
  userHasAuthenticated,
  setUserEmail,
  updateUser,
} from "../../context/actions";
import SystemAdminDashboard from "../../components/SystemAdminDashboard";
import "./Home.css";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faGavel,
  faLifeRing,
  faUserPlus,
  faEnvelope,
  faHistory,
  faBalanceScale,
} from "@fortawesome/free-solid-svg-icons";

const Home = ({ calendar }) => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { user, barRef, isAuthenticated } = state;
  const [notifications, setNotifications] = useState([
    { id: 1, text: "New message from user", isRead: false },
    { id: 2, text: "New message from user", isRead: false },
    { id: 3, text: "New message from user", isRead: false },
    // Add more notifications as needed
  ]);

  useEffect(() => {
    //
    console.log("HOME STATE: ", state);
  }, []);

  // Implementing handleMetricClick
  const handleMetricClick = (metricName) => {
    console.log(`${metricName} clicked`);
    // Here you can add more logic based on the metric clicked
    // For example, navigating to a different page or showing more details
  };

  // Function to mark a notification as read
  const markAsRead = (notificationId) => {
    setNotifications(
      notifications.map((notification) => {
        if (notification.id === notificationId) {
          return { ...notification, isRead: true };
        }
        return notification;
      })
    );
  };

  return (
    <>
      {isAuthenticated &&
      (user.userRole === "Owner" ||
        user.userRole === "System_Admin" ||
        user.userRole === "System_Manager") ? (
        <Main noFooter={false}>
          {/* Your alternative layout and its child components go here */}
          <article
            className="alternative-lander"
            style={{ margin: "0rem 1rem 1rem 1rem" }}
          >
            <div className="dashboard-overview">
              <div className="dashboard-metrics">
                <h2>Dashboard Overview</h2>
                <div className="horizontal-scroll-container">
                  {/* Each metric-card is now individually clickable and can animate */}
                  <div
                    className="metric-card"
                    style={{ marginLeft: ".6rem" }}
                    onClick={() => handleMetricClick("Active Depositions")}
                  >
                    <FontAwesomeIcon icon={faGavel} size="2x" />
                    <h3>Active Depositions</h3>
                    <p>2,134</p>
                  </div>

                  <div
                    className="metric-card"
                    onClick={() => handleMetricClick("Pending Support Cases")}
                  >
                    <FontAwesomeIcon icon={faLifeRing} size="2x" />
                    <h3>Pending Support Cases</h3>
                    <p>15</p>
                  </div>

                  <div
                    className="metric-card"
                    onClick={() => handleMetricClick("New Users to Approve")}
                  >
                    <FontAwesomeIcon icon={faUserPlus} size="2x" />
                    <h3>New Users to Approve</h3>
                    <p>23</p>
                  </div>

                  <div
                    className="metric-card"
                    style={{ marginRight: ".6rem" }}
                    onClick={() => handleMetricClick("Disputes")}
                  >
                    <FontAwesomeIcon icon={faBalanceScale} size="2x" />
                    <h3>Disputes</h3>
                    <p>3</p>{" "}
                    {/* Replace 'Number' with the actual number of disputes */}
                  </div>
                  {/* Add more metric-cards as needed */}
                </div>
              </div>

              <div className="quick-actions">
                <h2>Quick Actions</h2>
                <LoaderButton variant="primary" className="quick-action-tile">
                  Create New Deposition
                </LoaderButton>
                <LoaderButton variant="secondary" className="quick-action-tile">
                  View Calendar
                </LoaderButton>
                <LoaderButton variant="info" className="quick-action-tile">
                  View Transactions
                </LoaderButton>
                <LoaderButton variant="success" className="quick-action-tile">
                  Create New Invoice
                </LoaderButton>
              </div>

              <div className="notifications">
                <h2>Notifications</h2>
                <div className="horizontal-scroll-container">
                  {notifications.map((notification, index) => (
                    <div
                      className={`notification-card ${
                        notification.isRead ? "read" : ""
                      }`}
                      key={notification.id}
                      style={{
                        marginLeft: index === 0 ? ".6rem" : "",
                        marginRight:
                          index === notifications.length - 1 ? ".6rem" : "",
                      }}
                    >
                      <FontAwesomeIcon icon={faEnvelope} />
                      <p>{notification.text}</p>
                      {!notification.isRead && (
                        <button
                          className="mark-as-read"
                          onClick={() => markAsRead(notification.id)}
                        >
                          Mark as Read
                        </button>
                      )}
                    </div>
                  ))}
                </div>
              </div>

              <div className="recent-activity">
                <h2>Recent Activity</h2>
                <div className="horizontal-scroll-container">
                  <div
                    className="activity-card"
                    style={{ marginLeft: ".6rem" }}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                    <p>User John Doe created a new case</p>
                  </div>
                  {/* Add more activities as needed */}

                  <div className="activity-card">
                    <FontAwesomeIcon icon={faHistory} />
                    <p>User Juan Pepe uploaded a deposition</p>
                  </div>

                  <div
                    className="activity-card"
                    style={{ marginRight: ".6rem" }}
                  >
                    <FontAwesomeIcon icon={faHistory} />
                    <p>User Mary Jane updated a profile</p>
                  </div>
                </div>
              </div>
            </div>
            <SystemAdminDashboard />
          </article>
        </Main>
      ) : isAuthenticated &&
        (user.userRole === "Law_Firm_Admin" ||
          user.userRole === "Law_Firm_Manager" ||
          user.userRole === "Lawyer" ||
          user.userRole === "Legal_Assistant") ? (
        <Main noFooter={false}>
          {/* Your alternative layout and its child components go here */}
          <article
            className="home-lander"
            style={{ margin: "0rem 1rem 1rem 1rem" }}
          >
            <h3>Depositions</h3>
            <LawyerDashboard calendar={calendar} />
          </article>
        </Main>
      ) : isAuthenticated &&
        (user.userRole === "Court_Reporter" ||
          user.userRole === "Scopist" ||
          user.userRole === "Videgrapher" ||
          user.userRole === "Interpreter") ? (
        <Main noFooter={false}>
          {/* Your alternative layout and its child components go here */}
          <article className="alternative-lander">
            <h1>FREELANCER (and others) Layout</h1>
            <p>This is the alternative layout content.</p>
          </article>
        </Main>
      ) : (
        <Main noFooter={false}>
          {/* Your main layout and its child components go here */}

          <article className="home-lander" style={{ marginBottom: "3rem" }}>
            <LandingPage />
          </article>
        </Main>
      )}
    </>
  );
};

export default Home;
