import React, { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import Dropdown from "react-bootstrap/Dropdown";
import ListGroup from "react-bootstrap/ListGroup";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { LinkContainer } from "react-router-bootstrap";
import { useParams, useNavigate } from "react-router-dom";
import { LoaderButton } from "../../components/LoaderButton";
import { onError } from "../../libs/errorLib";
import { useFormFields } from "../../hooks";
import Main from "../../layouts/Main";
import { ApplicationContext } from "../../context/GlobalState";
import { BsPencilSquare } from "react-icons/bs";
import {
	userHasAuthenticated,
	setUserEmail,
	updateUser,
} from "../../context/actions";

import "./SalesLead.css";

const SalesLead = () => {
	const nav = useNavigate();
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);
	const leadId = useParams();
	const [lead, setLead] = useState(null);

	const { user, barRef } = state;

	console.log("SalesLeads state: ", state);
	console.log("SalesLeads leadId: ", leadId.leadId);

	useEffect(() => {
		if (isLoading) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading]);

	useEffect(() => {
		const loadSalesLead = () => {
			//
			return API.get("automater-api", `/lead-record/${leadId.leadId}`);
		};

		const onLoad = async () => {
			try {
				//
				const lead = await loadSalesLead();
				const { email, phoneNumber, campaignId } = lead;

				setLead(lead);
			} catch (err) {
				//
				onError(err);
			}
		};

		onLoad();
	}, [leadId]);

	return (
		<>
			<Main>
				<article className="SalesLead">
					<div className="lander">
						<h1>Sales Lead Data</h1>
						<p>Seller Data available for a cash opportunity.</p>
						{state.isAuthenticated ? lead : nav("/login")}
					</div>
				</article>
			</Main>
		</>
	);
};

export default SalesLead;
