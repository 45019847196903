import React, {
  useState,
  forwardRef,
  useContext,
  useEffect,
  useRef,
} from "react";
import {
  Button,
  Form,
  Col,
  Row,
  Container,
  Image,
  Dropdown,
} from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheckCircle,
  faExclamationCircle,
  faUser,
  faEnvelope,
  faPhone,
  faBuilding,
  faGlobeAmericas,
  faBriefcase,
  faEllipsisH,
} from "@fortawesome/free-solid-svg-icons";
import { ApplicationContext } from "../../context/GlobalState";
//import { addPaymentMethod } from "../../context/actions";

import "./LawyerProfile.css";

const LawyerProfile = ({ user, updateUser, deleteUser, navigateToEdit }) => {
  // Assuming `user` object includes `emailVerified` and `phoneVerified` boolean properties
  // Inside LawyerProfile component, before the return statement
  const [state, dispatch] = useContext(ApplicationContext);
  const [activePaymentOption, setActivePaymentOption] = useState("creditCard");

  useEffect(() => {
    console.log("CollectJS defined:", window.CollectJS);
  }, []);

  // Function to handle showing the add card form
  const configureCollectJSForCard = () => {
    window.CollectJS?.configure({
      paymentType: "cc",
      paymentSelector: "#payment",
      variant: "inline",
      styleSniffer: true,
      callback: (token) => {
        console.log("CREDIT CARD PAYMENT TOKEN: ", token);
        // Assuming finishSubmit is correctly bound or defined, you might need to adjust this
        finishSubmit(token);
      },
      fields: {
        ccnumber: {
          placeholder: "CC Number",
          selector: "#ccnumber",
        },
        ccexp: {
          placeholder: "CC Expiration",
          selector: "#ccexp",
        },
        cvv: {
          placeholder: "CVV",
          selector: "#cvv",
        },
      },
    });
  };

  const configureCollectJSForBank = () => {
    window.CollectJS?.configure({
      paymentType: "check", // Changed from "cc" to "check" for bank account
      paymentSelector: "#payment", // Assuming this selector is still applicable
      variant: "inline",
      styleSniffer: true,
      callback: (token) => {
        console.log("ACH PAYMENT TOKEN: ", token);
        // Assuming finishSubmit is correctly bound or defined, you might need to adjust this
        finishSubmit(token);
      },
      fields: {
        checkname: {
          placeholder: "Accountholder Name",
          selector: "#acountHolderName", // Ensure this matches your form's ID for the accountholder's name
        },
        checkaba: {
          placeholder: "Bank Routing Number",
          selector: "#routingNumber", // Ensure this matches your form's ID for the routing number
        },
        checkaccount: {
          placeholder: "Bank Account Number",
          selector: "#accountNumber", // Ensure this matches your form's ID for the account number
        },
        // Note: Bank Name and Billing Address are not directly supported by Collect.js for tokenization
        // You may need to handle these fields separately in your form submission logic
      },
    });
  };

  const handleAddCardClick = () => {
    setActivePaymentOption("addCreditCard"); // This line assumes you're managing which form to show

    // Call the function to configure Collect.js
    configureCollectJSForCard();
  };

  const handleAddBankAccountClick = () => {
    setActivePaymentOption("addBankAccount"); // This line assumes you're managing which form to show

    // Call the function to configure Collect.js
    configureCollectJSForCard();
  };

  const handlePaymentOptionClick = (option) => {
    setActivePaymentOption(option);
  };

  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <button
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
      className="ellipsis-button" // Apply your custom styles
    >
      {children}
    </button>
  ));

  const formatPhoneNumber = (phoneNumber) => {
    const match = phoneNumber.match(/^1(\d{3})(\d{3})(\d{4})$/);
    if (match) {
      return `+1 (${match[1]}) ${match[2]}-${match[3]}`;
    }
    return phoneNumber; // Return original if no match, adjust as needed
  };

  const finishSubmit = (response) => {
    const { isSubmitting, alertMessage, ...formData } = { ...state };
    formData.token = response.token;
    console.log("TOKEN FORM DATA - PAYMENT: ", formData);
    this.setState({
      isSubmitting: false,
      alertMessage:
        "The form was submitted. Check the console to see the output data.",
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    // Tokenize the payment information
    window.CollectJS.tokenize(
      (token, cardData) => {
        console.log("Token:", token);
        // Proceed with the token
      },
      (errors) => {
        console.error("Errors:", errors);
        // Handle errors
      }
    );
  };

  return (
    <Container className="lp-container">
      <Row>
        <Col>
          <Form className="lp-form">
            {/* Display Profile Image */}
            <div className="profile-image-container">
              <Image
                src={
                  user?.profileImage ||
                  "https://cdn-assets.nativestack.io/images/branding/NS.Icon.Light.png"
                }
                roundedCircle
                className="profile-image"
              />
            </div>

            {/* User Profile Details Section */}
            <Dropdown className="user-details-dropdown">
              <Dropdown.Toggle
                as={CustomToggle}
                id="dropdown-custom-components"
              >
                {/* Your custom icon or content here */}
                <FontAwesomeIcon icon={faEllipsisH} className="ellipsis-icon" />
              </Dropdown.Toggle>

              {/* Button to navigate to edit/delete view */}
              <Dropdown.Menu align="end">
                <Dropdown.Item onClick={navigateToEdit}>
                  Edit Profile
                </Dropdown.Item>
                {/* Additional options can be added here */}
              </Dropdown.Menu>
            </Dropdown>

            <div className="user-details skeuomorphic-element">
              <h3 style={{ marginBottom: "1rem" }}>User Details</h3>
              <Row>
                {/* Column 1 for smaller screens, contains the first set of attributes */}
                <Col xs={12} md={6}>
                  <p className="profile-attribute">
                    <FontAwesomeIcon icon={faUser} className="icon-circle" />{" "}
                    Full Name:
                  </p>
                  <p className="profile-value">
                    {user?.firstName + " " + user?.lastName}
                  </p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faEnvelope}
                      className="icon-circle"
                    />{" "}
                    Email:
                  </p>
                  <p className="profile-value">
                    {user?.email}{" "}
                    {user?.user.verified ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="icon-verified"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="icon-unverified"
                      />
                    )}
                  </p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon icon={faPhone} className="icon-circle" />{" "}
                    Phone:
                  </p>
                  <p className="profile-value">
                    {formatPhoneNumber(user?.officePhone)}{" "}
                    {user?.user.phoneVerified ? (
                      <FontAwesomeIcon
                        icon={faCheckCircle}
                        className="icon-verified"
                      />
                    ) : (
                      <FontAwesomeIcon
                        icon={faExclamationCircle}
                        className="icon-unverified"
                      />
                    )}
                  </p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faBriefcase}
                      className="icon-circle"
                    />{" "}
                    User Role:
                  </p>
                  <p className="profile-value">{user?.user.userRole}</p>
                </Col>

                {/* Column 2 for smaller screens, contains the remaining attributes */}
                <Col xs={12} md={6}>
                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faGlobeAmericas}
                      className="icon-circle"
                    />{" "}
                    LinkedIn Profile:
                  </p>
                  <p className="profile-value">{user?.linkedinProfile}</p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="icon-circle"
                    />{" "}
                    Company Name:
                  </p>
                  <p className="profile-value">{user?.corporateName}</p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="icon-circle"
                    />{" "}
                    Street Address:
                  </p>
                  <p className="profile-value">
                    {user?.billingAddress.streetAddress}
                  </p>

                  <p className="profile-attribute">
                    <FontAwesomeIcon
                      icon={faBuilding}
                      className="icon-circle"
                    />{" "}
                    City, State, Zip:
                  </p>
                  <p className="profile-value">
                    {user?.billingAddress.city}, {user?.billingAddress.stateOf}{" "}
                    {user?.billingAddress.zipCode}
                  </p>
                </Col>
              </Row>
            </div>
          </Form>
          <Form>
            {/* Payment Details Section */}
            <div className="payment-details">
              <h3>Payment Details</h3>

              {/* Buttons to toggle between payment options */}
              <Button
                className={`payment-option-button ${
                  activePaymentOption === "creditCard" ? "active" : ""
                }`}
                onClick={() => handlePaymentOptionClick("creditCard")}
              >
                Credit Card
              </Button>

              <Button
                className={`payment-option-button ${
                  activePaymentOption === "bankAccount" ? "active" : ""
                }`}
                onClick={() => handlePaymentOptionClick("bankAccount")}
              >
                Bank Account (ACH)
              </Button>
              {/* Add more buttons for other payment options as needed i.e. BTC, ETH/EVM */}

              {/* Conditional rendering based on activePaymentOption */}
              {activePaymentOption === "creditCard" && (
                <>
                  {user.paymentMethods &&
                  user.paymentMethods.some(
                    (method) => method.type === "Credit Card"
                  ) ? (
                    user.paymentMethods.map(
                      (method, index) =>
                        method.type === "Credit Card" && (
                          <div
                            key={index}
                            className="payment-method skeuomorphic-element"
                          >
                            <p>
                              Cardholder Name: {method.details.cardholderName}
                            </p>
                            <p>Card Number: {method.details.cardNumber}</p>
                            <p>
                              Expiration: {method.details.expirationMonthYear}
                            </p>
                            <p>CVV: {method.details.cvv}</p>
                            <p>
                              Billing Address: {method.details.billingAddress}
                            </p>
                            <Button
                              className="edit-payment-method payment-option-button"
                              onClick={() => {
                                /* logic to edit this specific method */
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        )
                    )
                  ) : (
                    <div>
                      <p>
                        No Credit Card on file. Please add a payment method.
                      </p>

                      <Button
                        className="payment-option-button"
                        onClick={handleAddCardClick}
                      >
                        Add Card
                      </Button>
                    </div>
                  )}
                </>
              )}

              {activePaymentOption === "addCreditCard" && (
                <>
                  <section id="payment">
                    <label htmlFor="ccnumber" className="payment-label">
                      Card Number
                    </label>
                    <div id="ccnumber" className="payment-field" />

                    <div className="exp-cvv-container">
                      <div>
                        <label htmlFor="ccexp" className="payment-label">
                          Expiration Date
                        </label>
                        <div id="ccexp" className="payment-field" />
                      </div>
                      <div>
                        <label
                          htmlFor="cvv"
                          className="payment-label cvv-label"
                        >
                          CVV
                        </label>
                        <div id="cvv" className="payment-field" />
                      </div>
                    </div>
                  </section>
                  <Button
                    variant="primary"
                    type="submit"
                    className="payment-option-button"
                  >
                    Submit
                  </Button>
                </>
              )}

              {activePaymentOption === "bankAccount" && (
                <>
                  {/* Similar structure for Bank Account details and form */}
                  {user.paymentMethods &&
                  user.paymentMethods.some(
                    (method) => method.type === "Bank Account"
                  ) ? (
                    user.paymentMethods.map(
                      (method, index) =>
                        method.type === "Bank Account" && (
                          <div
                            key={index}
                            className="payment-method skeuomorphic-element"
                          >
                            <p>
                              Account Holder Name:{" "}
                              {method.details.acountHolderName}
                            </p>
                            <p>Account: {method.details.account}</p>
                            <p>Routing: {method.details.routing}</p>
                            <p>Bank Name: {method.details.bankName}</p>
                            <p>
                              Billing Address: {method.details.billingAddress}
                            </p>
                            <Button
                              className="edit-payment-method payment-option-button"
                              onClick={() => {
                                /* logic to edit this specific method */
                              }}
                            >
                              Edit
                            </Button>
                          </div>
                        )
                    )
                  ) : (
                    <div>
                      <p>
                        No Bank Account on file. Please add a payment method.
                      </p>

                      <Button
                        className="payment-option-button"
                        onClick={handleAddBankAccountClick}
                      >
                        Add Bank Account
                      </Button>
                    </div>
                  )}
                </>
              )}

              {/* Form for adding or editing a Bank Account */}
              {activePaymentOption === "addBankAccount" && (
                <>
                  <section id="payment">
                    <label htmlFor="checkname" className="payment-label">
                      Account holder Name
                    </label>
                    <div id="checkname" className="payment-field" />

                    <div className="bank-details-container">
                      <div>
                        <label htmlFor="checkaba" className="payment-label">
                          Bank Routing Number
                        </label>
                        <div id="checkaba" className="payment-field" />
                      </div>
                      <div>
                        <label htmlFor="checkaccount" className="payment-label">
                          Account Number
                        </label>
                        <div id="checkaccount" className="payment-field" />
                      </div>
                    </div>
                  </section>
                  <Button
                    variant="primary"
                    type="submit"
                    className="payment-option-button"
                  >
                    Submit
                  </Button>
                </>
              )}

              {/* Add conditional blocks for other payment options as needed */}
            </div>
          </Form>
        </Col>
      </Row>
    </Container>
  );
};

export default LawyerProfile;
