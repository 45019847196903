import React, { useState, useEffect, useContext } from "react";
import { Auth, API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import { LoaderButton } from "../../components/LoaderButton";
import SystemAdminDashboard from "../../components/SystemAdminDashboard";
import LawyerProfile from "../../components/LawyerProfile";
import { onError } from "../../libs/errorLib";
import Main from "../../layouts/Main";
import { ApplicationContext } from "../../context/GlobalState";

import "./Settings.css";
import {
  userHasAuthenticated,
  setUserEmail,
  updateUser,
} from "../../context/actions";

const Settings = () => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [isLoading, setIsLoading] = useState(false);
  const { user, barRef, isAuthenticated } = state;

  return (
    <>
      {isAuthenticated &&
      (user.userRole === "Owner" ||
        user.userRole === "System_Admin" ||
        user.userRole === "System_Manager") ? (
        <Main noFooter={false}>
          <article className="Settings" style={{ marginBottom: "3rem" }}>
            {/*...*/}
          </article>
        </Main>
      ) : user.userRole === "Law_Firm_Admin" ||
        user.userRole === "Law_Firm_Manager" ||
        user.userRole === "Lawyer" ||
        user.userRole === "Legal_Assistant" ? (
        <Main noFooter={false}>
          <article className="Settings" style={{ margin: "0rem 1rem" }}>
            {/*...*/}
            <h3 style={{ marginBottom: "1rem" }}>Profile & Payment Settings</h3>
            <LawyerProfile user={state} />
          </article>
        </Main>
      ) : user.userRole === "Court_Reporter" ||
        user.userRole === "Scopist" ||
        user.userRole === "Videgrapher" ||
        user.userRole === "Interpreter" ? (
        <Main noFooter={false}>
          <article className="Settings" style={{ marginBottom: "3rem" }}>
            {/*...*/}
          </article>
        </Main>
      ) : (
        <Main noFooter={false}>
          <article className="Settings" style={{ marginBottom: "3rem" }}>
            {/*...*/}
          </article>
        </Main>
      )}
    </>
  );
};

export default Settings;
