/**
 *
 *  @function isEmail
 *
 *  This is a function used to validate email
 *  addresses.
 *
 *  @param {string} email - the email to validate upon
 *
 *  @returns {boolean}
 *
 *  @example
 *
 *    isEmail('not an email') => false
 *
 *    isEmail('valid.email@gmail.com') => true
 *
 *    isEmail('@invalid.com') => false
 *
 */
export const isEmail = (email) => {
  const re =
    /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return re.test(email);
};

export const regexAccountId = (msg) => {
  const l = parseInt(msg.length);
  let m1 = msg.split(",")?.join("");
  let m2 = m1.split(" ").join("");
  let m3 = m2.split(".").join("");

  const regexFront = /([A-Za-z0-9]+)/g;
  const regexBack = /([A-Za-z0-9]+)$/g;

  let str = "NativeStack, Inc";

  var resultFront = regexFront.exec(m3);
  var resultBack = regexBack.exec(m3);

  if (resultFront[0] === resultBack[0]) {
    console.log("HERE IS FRONT: ", resultFront[0] + " back:" + resultBack[0]);

    return resultFront[0].toLowerCase();
  } else {
    console.log("HERE IS FRONT/BACK: ", resultFront[0] + resultBack[0]);

    return resultFront[0] + resultBack[0].toLowerCase();
  }
};

export const isIOS = () => {
  return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
};

export const isSafari = () => {
  const userAgent = window.navigator.userAgent;
  return isIOS() && !userAgent.includes("CriOS");
};
