import React, { useState, useContext, useEffect } from "react";
import { Auth } from "aws-amplify";
import { Link, useNavigate } from "react-router-dom";
import Form from "react-bootstrap/Form";
import { BsCheck } from "react-icons/bs";
import Main from "../../layouts/Main";
import { LoaderButton } from "../../components/LoaderButton";
import { useFormFields } from "../../hooks";
import { onError } from "../../libs/errorLib";
import "./ResetPassword.css";
import { ApplicationContext } from "../../context/GlobalState";
import {
	userHasAuthenticated,
	setUserEmail,
	updateUser,
} from "../../context/actions";

const ResetPassword = () => {
	const [state, dispatch] = useContext(ApplicationContext);
	const [isLoading, setIsLoading] = useState(false);

	const { barRef } = state;
	const nav = useNavigate();

	const [fields, handleFieldChange] = useFormFields({
		code: "",
		email: "",
		password: "",
		confirmPassword: "",
	});

	const [codeSent, setCodeSent] = useState(false);
	const [confirmed, setConfirmed] = useState(false);
	const [isConfirming, setIsConfirming] = useState(false);
	const [isSendingCode, setIsSendingCode] = useState(false);

	console.log("RESET_PASSWORD_STATE: ", state);

	useEffect(() => {
		if (isLoading || isConfirming || isSendingCode) {
			barRef?.current?.continuousStart();
		} else {
			barRef?.current?.complete();
		}
	}, [isLoading, isConfirming, isSendingCode]);

	function validateCodeForm() {
		return fields.email.length > 0;
	}

	function validateResetForm() {
		return (
			fields.code.length > 0 &&
			fields.password.length > 0 &&
			fields.password === fields.confirmPassword
		);
	}

	async function handleSendCodeClick(e) {
		e.preventDefault();

		setIsSendingCode(true);

		try {
			await Auth.forgotPassword(fields.email.toLowerCase());
			setCodeSent(true);
		} catch (error) {
			onError(error);
			setIsSendingCode(false);
		}
	}

	async function handleConfirmClick(e) {
		e.preventDefault();

		setIsConfirming(true);

		try {
			await Auth.forgotPasswordSubmit(
				fields.email.toLowerCase(),
				fields.code,
				fields.password
			);
			setConfirmed(true);
		} catch (error) {
			onError(error);
			setIsConfirming(false);
		}
	}

	function renderRequestCodeForm() {
		return (
			<Form
				onSubmit={handleSendCodeClick}
				className="reset-password-form"
			>
				<Form.Group size="lg" controlId="email">
					<Form.Label>Email</Form.Label>
					<Form.Control
						autoFocus
						type="email"
						value={fields.email}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
					/>
				</Form.Group>

				<section className="reset-password-submit-btn">
					<LoaderButton
						block="true"
						type="submit"
						size="large"
						variant="success"
						isLoading={isSendingCode}
						disabled={!validateCodeForm()}
					>
						Send Confirmation
					</LoaderButton>
				</section>
			</Form>
		);
	}

	function renderConfirmationForm() {
		return (
			<Form onSubmit={handleConfirmClick} className="reset-password-form">
				<Form.Group size="lg" controlId="code">
					<Form.Label>Confirmation Code</Form.Label>
					<Form.Control
						autoFocus
						type="tel"
						value={fields.code}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
					/>
					<Form.Text>
						Please check your email ({fields.email}) for the
						confirmation code.
					</Form.Text>
				</Form.Group>
				<hr />
				<Form.Group size="lg" controlId="password" id="fg5">
					<Form.Label>New Password</Form.Label>
					<Form.Control
						type="password"
						value={fields.password}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
					/>
				</Form.Group>
				<Form.Group size="lg" controlId="confirmPassword" id="fg6">
					<Form.Label>Confirm Password</Form.Label>
					<Form.Control
						type="password"
						value={fields.confirmPassword}
						onChange={handleFieldChange}
						className="nucleus-form-fields"
					/>
				</Form.Group>

				<section className="reset-password-submit-btn">
					<LoaderButton
						block="true"
						type="submit"
						size="lg"
						variant="success"
						isLoading={isConfirming}
						disabled={!validateResetForm()}
					>
						Confirm
					</LoaderButton>
				</section>
			</Form>
		);
	}

	function renderSuccessMessage() {
		return (
			<div className="success">
				<p>
					<BsCheck size={16} /> Your password has been reset.
				</p>
				<p>
					<Link to="/login">
						Click here to login with your new credentials.
					</Link>
				</p>
			</div>
		);
	}

	return (
		<>
			<Main>
				<article className="ResetPassword">
					{!codeSent
						? renderRequestCodeForm()
						: !confirmed
						? renderConfirmationForm()
						: renderSuccessMessage()}
				</article>
			</Main>
		</>
	);
};

export default ResetPassword;
