import React, { useState, useEffect } from "react";
import "./InstallPrompt.css";
import DepoAppIcon from "../../assets/icons/depo-app-icon.svg"; // Adjust the path as needed

const InstallPrompt = () => {
  const [deferredPrompt, setDeferredPrompt] = useState(null);
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useEffect(() => {
    const handleBeforeInstallPrompt = (e) => {
      e.preventDefault();
      setDeferredPrompt(e);
      setShowInstallPrompt(true);
    };

    window.addEventListener("beforeinstallprompt", handleBeforeInstallPrompt);

    return () => {
      window.removeEventListener(
        "beforeinstallprompt",
        handleBeforeInstallPrompt
      );
    };
  }, []);

  const handleInstallClick = async () => {
    if (deferredPrompt) {
      deferredPrompt.prompt();
      const { outcome } = await deferredPrompt.userChoice;
      if (outcome === "accepted") {
        console.log("User accepted the install prompt");
      } else {
        console.log("User dismissed the install prompt");
      }
      setDeferredPrompt(null);
      setShowInstallPrompt(false);
    }
  };

  const handleDismiss = (e) => {
    e.stopPropagation();
    setShowInstallPrompt(false);
  };

  if (!showInstallPrompt) {
    return null;
  }

  return (
    <div
      className={`install-prompt ${showInstallPrompt ? "show" : ""}`}
      onClick={handleDismiss}
    >
      <div className="install-prompt-icon-container">
        <img
          src={DepoAppIcon}
          alt="Depo App Icon"
          className="install-prompt-icon"
        />
      </div>
      <p>
        Install Depo App to your home screen as an App for a better experience
        with push notifications.
      </p>
      <button onClick={handleInstallClick}>Install</button>
    </div>
  );
};

export default InstallPrompt;
