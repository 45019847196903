import React, { useState, useEffect } from "react";
import ReactMarkdown from "react-markdown"; // /with-html";
import Main from "../../layouts/Main";
import "./TermsAndConditions.css";
import MD from "./termsConditions.js";

const TermsAndConditions = () => {
  return (
    <>
      <Main>
        <h1 style={{ marginBottom: "1rem" }}>Terms & Conditions</h1>
        <div className="terms-and-conditions">
          <ReactMarkdown children={MD} />
        </div>
      </Main>
    </>
  );
};

export default TermsAndConditions;
