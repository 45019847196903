const MD = `

#### A Deposition Marketplace

Payments will be automatically withdrawn from desired account on a schedule and account chosen by you on the following page. You will be able to change the account on a per engagement basis. Time frame for payments can be changed at anytime however earned income from already pertormed engagements will be withdrawn on schedule that was listed at the time since it is part of the information given to freelancers as a inducement to take gig. Terms and conditions of payments set forth here as well.

Last updated: August 23, 2021

Please read these terms and conditions carefully before using Our
Service.

Interpretation and Definitions

## Interpretation

The words of which the initial letter is capitalized have meanings
defined under the following conditions. The following definitions shall
have the same meaning regardless of whether they appear in singular or
in plural.

## Definitions

For the purposes of these Terms and Conditions:

**Application** means the software program provided by the Company
downloaded by You on any electronic device, named Depo App

**Application Store** means the digital distribution service operated
and developed by Apple Inc. (Apple App Store) or Google Inc. (Google
Play Store) in which the Application has been downloaded.

**Affiliate** means an entity that controls, is controlled by or is
under common control with a party, where \"control\" means ownership of
50% or more of the shares, equity interest or other securities entitled
to vote for election of directors or other managing authority.

**Account** means a unique account created for You to access our Service
or parts of our Service.

**Attorney** means an attorney, a law firm, or a representative of an
attorney that uses the Service.

**Country** refers to: Delaware, United States

**Company** (referred to as either \"the Company\", \"We\", \"Us\" or
\"Our\" in this Agreement) refers to The Depo App Company, having an
office at 2641 Pratt Street, Philadelphia, Pa, 19137.

**Content** refers to content such as text (including transcripts),
images, videos or other information that can be posted, uploaded, linked
to or otherwise made available by You, regardless of the form of that
content, using the Service.

**Deposition Good** means any Good related to a deposition, including a
deposition transcript or a deposition DVD.

**Device** means any device that can access the Service such as a
computer, a cellphone or a digital tablet.

**Feedback** means feedback, innovations, inventions or suggestions sent
by You regarding the attributes, performance or features of our Service.

**Freelancer** means a provider of Goods.

**Goods** refer to the items offered for sale on the Service, including
any Deposition Good.

**In-app Purchase** refers to the purchase of a product, item, service
or Subscription made through the Application and subject to these Terms
and Conditions and/or the Application Store\'s own terms and conditions.

**Orders** mean a request by You to purchase Goods from Us and/or from a
Freelancer.

**Service** refers to the Application or the Website or both.

**Terms and Conditions** (also referred as \"Terms\") mean these Terms
and Conditions that form the entire agreement between You and the
Company regarding the use of the Service.

**Third-party Social Media Service** means any services or content
(including data, information, products or services) provided by a
third-party that may be displayed, included or made available by the
Service.

**Website** refers to Depo App, accessible from <http://www.depoapp.com>

**You** means the individual accessing or using the Service, or the
company, or other legal entity on behalf of which such individual is
accessing or using the Service, as applicable.

Acknowledgment

These are the Terms and Conditions governing the use of this Service and
the agreement that operates between You and the Company. These Terms and
Conditions set out the rights and obligations of all users regarding the
use of the Service.

Your access to and use of the Service is conditioned on Your acceptance
of and compliance with these Terms and Conditions. These Terms and
Conditions apply to all visitors, users and others who access or use the
Service.

By accessing or using the Service You agree to be bound by these Terms
and Conditions. If You disagree with any part of these Terms and
Conditions then You may not access the Service.

You represent that you are over the age of 18. The Company does not
permit those under 18 to use the Service.

Your access to and use of the Service is also conditioned on Your
acceptance of and compliance with the Privacy Policy of the Company. Our
Privacy Policy describes Our policies and procedures on the collection,
use and disclosure of Your personal information when You use the
Application or the Website and tells You about Your privacy rights and
how the law protects You. Please read Our Privacy Policy carefully
before using Our Service.

Placing Orders for Goods

By placing an Order for Goods through the Service, You warrant that You
are legally capable of entering into binding contracts.

## Your Information

If You wish to place an Order for Goods available on the Service, You
may be asked to supply certain information relevant to Your Order
including, without limitation, Your name, Your email, Your phone number,
Your credit card number, the expiration date of Your credit card, Your
banking information, Your billing address, Your office address, Your
shipping information and an address for a deposition location.

You represent and warrant that: (i) You have the legal right to use any
credit or debit card(s) or other payment method(s) in connection with
any Order; and that (ii) the information You supply to us is true,
correct and complete.

By submitting such information, You grant us the right to provide the
information to payment processing third parties for purposes of
facilitating the completion of Your Order.

## Order Cancellation

We reserve the right to refuse or cancel Your Order at any time for
certain reasons including but not limited to:

-   Goods availability

-   Errors in the description or prices for Goods

-   Errors in Your Order

We reserve the right to refuse or cancel Your Order if fraud or an
unauthorized or illegal transaction is suspected.

### Your Order Cancellation Rights

Any Goods an Attorney purchases can only be returned by the Attorney
independently reaching a dispute resolution with the relevant
Freelancer. You acknowledge and agree that We will not and are not
obligated to provide any dispute assistance.

An Attorney making an Order for a Good will receive at least one
reminder from Us before the date for provision of the Good. If an
Attorney cancels an Order less than twenty four (24) hours from the time
when a Good was scheduled to be provided, the Attorney may be charged a
late cancellation fee.

You will not have any right to cancel an Order for the supply of any of
the following Goods:

-   The supply of a Deposition Good after the relevant deposition has
    occurred.

-   The supply of Goods made to Your specifications or clearly
    personalized.

-   The supply of Goods which according to their nature are not suitable
    to be returned.

-   The supply of Goods which are, after delivery, according to their
    nature, inseparably mixed with other items.

-   The supply of digital content if the performance has begun with Your
    prior express consent.

## Availability, Errors and Inaccuracies

We are constantly updating Our offerings of Goods on the Service. The
Goods available on Our Service may be mispriced, described inaccurately,
or unavailable, and We may experience delays in updating information
regarding our Goods on the Service and in Our advertising on other
websites.

We cannot and do not guarantee the accuracy or completeness of any
information, including prices, product images, specifications,
availability, and services. We reserve the right to change or update
information and to correct errors, inaccuracies, or omissions at any
time without prior notice.

## We cannot and do not guarantee the accuracy of any Deposition Goods, including any deposition transcripts or DVDs. Prices Policy

The Company reserves the right to revise its prices, such as its service
fee(s), at any time prior to accepting an Order.

We cannot and do not guarantee the accuracy of any price estimate
provided by a Freelancer to an Attorney. The stated rate (for example,
the stated price per page or per hour) will control.

The prices quoted may be revised by the Company subsequent to accepting
an Order in the event of any occurrence affecting delivery caused by
government action, variation in customs duties, increased shipping
charges, higher foreign exchange costs and any other matter beyond the
control of the Company. In that event, You will have the right to cancel
Your Order.

## Payments

All Goods purchased are subject to a one-time payment. Payment can be
made through various payment methods we have available, such as bank
withdrawal, Visa, MasterCard, Affinity Card, American Express cards or
online payment methods (PayPal, for example).

Payment cards (credit cards or debit cards) are subject to validation
checks and authorization by Your card issuer. If we do not receive the
required authorization, We will not be liable for any delay or
non-delivery of Your Order.

## No Fee for Introducing or Finding Projects

We do not introduce Attorneys to Freelancers and do not help Freelancers
secure projects. We merely make the Service available to enable
Freelancers to do so themselves and may from time to time highlight
projects that may be of interest. Therefore, we do not charge a fee when
a Freelancer finds a suitable project. In addition, we does not charge
any fee or dues for posting public feedback and composite or compiled
feedback, including composite information about a user.

## Following Relevant Laws

Regarding Deposition Goods, users are expected to follow the court rules
of the relevant court, and to abide by any other rules or obligations
previously agreed to by the users.

In-app Purchases

The Application may include In-app Purchases that allow you to buy
products, services or Subscriptions.

More information about how you may be able to manage In-app Purchases
using your Device may be set out in the Application Store\'s own terms
and conditions or in your Device\'s Help settings.

In-app Purchases can only be consumed within the Application. If you
make an In-app Purchase, that In-app Purchase cannot be cancelled after
you have initiated its download. In-app Purchases cannot be redeemed for
cash or other consideration or otherwise transferred.

If any In-app Purchase is not successfully downloaded or does not work
once it has been successfully downloaded, we will, after becoming aware
of the fault or being notified to the fault by You, investigate the
reason for the fault. We will act reasonably in deciding whether to
provide You with a replacement In-app Purchase or issue You with a patch
to repair the fault. In no event will We charge You to replace or repair
the In-app Purchase. In the unlikely event that we are unable to replace
or repair the relevant In-app Purchase or are unable to do so within a
reasonable period of time and without significant inconvenience to You,
We will authorize the Application Store to refund You an amount up to
the cost of the relevant In-app Purchase. Alternatively, if You wish to
request a refund, You may do so by contacting the Application Store
directly. You acknowledge and agree that all billing and transaction
processes are handled by the Application Store from where you downloaded
the Application and are governed by that Application Store\'s own terms
and conditions.

If you have any payment related issues with In-app Purchases, then you
need to contact the Application Store directly.

User Accounts

When You create an Account with Us, You must provide Us information that
is accurate, complete, and current at all times. Failure to do so
constitutes a breach of the Terms, which may result in immediate
termination of Your account on Our Service.

You are responsible for safeguarding the password that You use to access
the Service and for any activities or actions under Your password,
whether Your password is with Our Service or a Third-Party Social Media
Service.

You agree not to disclose Your password to any third party. You must
notify Us immediately upon becoming aware of any breach of security or
unauthorized use of Your account.

You may not use as a username the name of another person or entity or
that is not lawfully available for use, a name or trademark that is
subject to any rights of another person or entity other than You without
appropriate authorization, or a name that is otherwise offensive, vulgar
or obscene.

By registering for an account to use the Service or by clicking to
accept the Terms and Conditions when prompted on the Application or
Website, you agree to abide by this Agreement and the other Terms and
Conditions.

To access and use certain portions of the Service, you must register for
an Account. Certain portions of the Application and Website may be
available to visitors. We reserve the right to decline a registration to
use the Service or to add an Account of any type (i.e., as an Attorney
or Freelancer), for any lawful reason, including supply and demand, cost
to maintain data, or other business considerations.

If you create an Account as an employee or agent on behalf of a company,
you represent and warrant that you are authorized to enter into binding
contracts, including the Terms and Conditions, on behalf of yourself and
the company. Your privacy is important to Us and your information will
be handled in accordance with our Privacy Policy and applicable law.

## Account Eligibility

We offer the Service for your business purposes only and not for
personal, household, or consumer use. To register for an Account or use
the Service, you must, and hereby represent that you: (a) are an
employee or agent of and authorized to act for and bind an independent
business (whether it be as a self-employed individual/sole proprietor or
as a corporation, limited liability company, or other entity); (b) will
use the Application, Website, and Service for business purposes only;
(c) will comply with any licensing, registration, or other requirements
with respect to your business, or the business for which you are acting;
and (d) are either a legal entity or an individual who is 18 years or
older (or have otherwise reached the age of majority in the jurisdiction
in which you conduct business) in each case who can form legally binding
contracts.

## Account Profile

To register for an Account, you must complete a user profile
("Profile"), which you consent to be shown to other users and, unless
you change your privacy settings, to be shown to the public. You agree
to provide true, accurate, and complete information on your Profile and
all registration and other forms you access using the Service or provide
to us and to update your information to maintain its truthfulness,
accuracy, and completeness. You agree not to provide any false or
misleading information about your identity or location, your business,
your skills, or the services your business provides and to correct any
such information that is or becomes false or misleading.

## Account Types

As described in this section, there are a number of different Account
types. You agree not to have or register for more than one Account
without express written permission from us. We reserve the right to
revoke the privileges of the Account or access to or use of the Service,
and those of any and all linked Accounts without warning if, in our sole
discretion, false or misleading information has been provided in
creating, marketing, or maintaining your Profile or Account.

Attorney: You can register for an Account or add an Account type to use
the Service as an Attorney (an "Attorney Account"). Different users
under an Attorney Account ("Team Member") may be given different
permissions to act on behalf of the Attorney Account.

Freelancer: You can register for an Account or add an Account type to
use the Service as a Freelancer (a "Freelancer Account").

Content

## Your Right to Post Content

Our Service allows You to post Content. You are responsible for the
Content that You post to the Service, including its legality,
reliability, and appropriateness.

By posting Content to the Service, You grant Us the right and license to
use, modify, publicly perform, publicly display, reproduce, and
distribute such Content on and through the Service. You retain any and
all of Your rights to any Content You submit, post or display on or
through the Service and You are responsible for protecting those rights.
You agree that this license includes the right for Us to make Your
Content available to other users of the Service, who may also use Your
Content subject to these Terms.

You represent and warrant that: (i) the Content is Yours (You own it) or
You have the right to use it and grant Us the rights and license as
provided in these Terms, and (ii) the posting of Your Content on or
through the Service does not violate the privacy rights, publicity
rights, copyrights, contract rights or any other rights of any person.

## Content Restrictions

The Company is not responsible for the Content of the Service\'s users.
You expressly understand and agree that You are solely responsible for
the Content and for all activity that occurs under your account, whether
done so by You or any third person using Your account.

You may not transmit any Content that is unlawful, offensive, upsetting,
intended to disgust, threatening, libelous, defamatory, obscene or
otherwise objectionable. Examples of such objectionable Content include,
but are not limited to, the following:

-   Unlawful or promoting unlawful activity.

-   Defamatory, discriminatory, or mean-spirited content, including
    references or commentary about religion, race, sexual orientation,
    gender, national/ethnic origin, or other targeted groups.

-   Spam, machine -- or randomly -- generated, constituting unauthorized
    or unsolicited advertising, chain letters, any other form of
    unauthorized solicitation, or any form of lottery or gambling.

-   Containing or installing any viruses, worms, malware, trojan horses,
    or other content that is designed or intended to disrupt, damage, or
    limit the functioning of any software, hardware or
    telecommunications equipment or to damage or obtain unauthorized
    access to any data or other information of a third person.

-   Infringing on any proprietary rights of any party, including patent,
    trademark, trade secret, copyright, right of publicity or other
    rights.

-   Impersonating any person or entity including the Company and its
    employees or representatives.

-   Violating the privacy of any third person.

-   False information and features.

The Company reserves the right, but not the obligation, to, in its sole
discretion, determine whether or not any Content is appropriate and
complies with this Terms, refuse or remove this Content. The Company
further reserves the right to make formatting and edits and change the
manner any Content. The Company can also limit or revoke the use of the
Service if You post such objectionable Content. As the Company cannot
control all content posted by users and/or third parties on the Service,
you agree to use the Service at your own risk. You understand that by
using the Service You may be exposed to content that You may find
offensive, indecent, incorrect or objectionable, and You agree that
under no circumstances will the Company be liable in any way for any
content, including any errors or omissions in any content, or any loss
or damage of any kind incurred as a result of your use of any content.

## Content Backups

Although regular backups of Content may be performed, the Company do not
guarantee there will be no loss or corruption of data. You, and not the
Company, are responsible for keeping backups of Content and of Goods
produced or purchased using the Service.

Corrupt or invalid backup points may be caused by, without limitation,
Content that is corrupted prior to being backed up or that changes
during the time a backup is performed.

The Company will provide support and attempt to troubleshoot any known
or discovered issues that may affect the backups of Content. But You
acknowledge that the Company has no liability related to the integrity
of Content or the failure to successfully restore Content to a usable
state.

You agree to maintain a complete and accurate copy of any Content in a
location independent of the Service.

## Marketplace Feedback and User Content

You hereby acknowledge and agree that users publish and request the
Company to publish on their behalf information on the Application and
Website about the user, such as feedback, composite feedback,
geographical location, or verification of identity or credentials.
However, such information is based solely on unverified data that users
voluntarily submit to the Company and does not constitute and will not
be construed as an introduction, endorsement, or recommendation by the
Company; the Company provides such information solely for the
convenience of users.

You acknowledge and agree that user feedback benefits the marketplace,
all users, and the efficiency of the Service and you specifically
request that the Company post composite or compiled feedback about
users, including yourself, on user profiles and elsewhere on the
Service. You acknowledge and agree that feedback results for you,
including user Content highlighted by the Company on the Service or
otherwise ("Composite Information"), if any, may include user comments,
user ratings, indicators of user satisfaction, and other feedback left
exclusively by other users. You further acknowledge and agree that the
Company will make Composite Information available to other users,
including composite or compiled feedback. The Company provides its
feedback system as a means through which users can share their opinions
of other users publicly, and the Company does not monitor, influence,
contribute to or censor these opinions. You acknowledge and agree that
posted composite or compiled feedback and any other Composite
Information relates only to the business advertised in the profile and
not to any individual person. You agree not to use the Composite
Information to make any employment, credit, credit valuation,
underwriting, or other similar decision about any other user.

The Company does not generally investigate any remarks posted by users
or other user Content for accuracy or reliability and does not guarantee
that user Content is accurate. You are solely responsible for your user
Content, including the accuracy of any user Content, and are solely
responsible for any legal action that may be instituted by other users
or third parties as a result of or in connection with your user Content.
The Company is not legally responsible for any feedback or comments
posted or made available on the Service by any users or third parties,
even if that information is defamatory or otherwise legally actionable.
In order to protect the integrity of the feedback system and protect
users from abuse, the Company reserves the right (but is under no
obligation) to remove posted feedback or information that, in the
Company's sole judgment, violates the Terms and Conditions or negatively
affects our marketplace, diminishes the integrity of the feedback system
or otherwise is inconsistent with the business interests of the Company.
You acknowledge and agree that you will notify the Company of any error
or inaccurate statement in your feedback results, including the
Composite Information, and that if you do not do so, the Company may
rely on the accuracy of such information.

Copyright Policy

## Intellectual Property Infringement

We respect the intellectual property rights of others. It is Our policy
to respond to any claim that Content posted on the Service infringes a
copyright or other intellectual property infringement of any person.

If You are a copyright owner, or authorized on behalf of one, and You
believe that the copyrighted work has been copied in a way that
constitutes copyright infringement that is taking place through the
Service, You must submit Your notice in writing to the attention of our
copyright agent via email at rl@thedepoappcompany.com and include in
Your notice a detailed description of the alleged infringement.

You may be held accountable for damages (including costs and attorneys\'
fees) for misrepresenting that any Content is infringing Your copyright.

## DMCA Notice and DMCA Procedure for Copyright Infringement Claims

You may submit a notification pursuant to the Digital Millennium
Copyright Act (DMCA) by providing our Copyright Agent with the following
information in writing (see 17 U.S.C 512(c)(3) for further detail):

-   An electronic or physical signature of the person authorized to act
    on behalf of the owner of the copyright\'s interest.

-   A description of the copyrighted work that You claim has been
    infringed, including the URL (i.e., web page address) of the
    location where the copyrighted work exists or a copy of the
    copyrighted work.

-   Identification of the URL or other specific location on the Service
    where the material that You claim is infringing is located.

-   Your address, telephone number, and email address.

-   A statement by You that You have a good faith belief that the
    disputed use is not authorized by the copyright owner, its agent, or
    the law.

-   A statement by You, made under penalty of perjury, that the above
    information in Your notice is accurate and that You are the
    copyright owner or authorized to act on the copyright owner\'s
    behalf.

You can contact our copyright agent via email at
rl@thedepoappcompany.com. Upon receipt of a notification, the Company
will take whatever action, in its sole discretion, it deems appropriate,
including removal of the challenged content from the Service.

Intellectual Property

The Service and its original content (excluding Content provided by You
or other users), features and functionality are and will remain the
exclusive property of the Company and its licensors.

The Service is protected by copyright, trademark, and other laws of both
the Country and foreign countries.

Our trademarks and trade dress may not be used in connection with any
product or service without the prior written consent of the Company.

Your Feedback to Us

You assign all rights, title and interest in any Feedback You provide
the Company. If for any reason such assignment is ineffective, You agree
to grant the Company a non-exclusive, perpetual, irrevocable, royalty
free, worldwide right and license to use, reproduce, disclose,
sub-license, distribute, modify and exploit such Feedback without
restriction.

Links to Other Websites

Our Service may contain links to third-party web sites or services that
are not owned or controlled by the Company.

The Company has no control over, and assumes no responsibility for, the
content, privacy policies, or practices of any third party web sites or
services. You further acknowledge and agree that the Company shall not
be responsible or liable, directly or indirectly, for any damage or loss
caused or alleged to be caused by or in connection with the use of or
reliance on any such content, goods or services available on or through
any such web sites or services.

We strongly advise You to read the terms and conditions and privacy
policies of any third-party web sites or services that You visit.

Termination

We may terminate or suspend Your Account immediately, without prior
notice or liability, for any reason whatsoever, including without
limitation if You breach these Terms and Conditions.

Upon termination, Your right to use the Service will cease immediately.
If You wish to terminate Your Account, You may simply discontinue using
the Service.

Limitation of Liability

Notwithstanding any damages that You might incur, the entire liability
of the Company and any of its suppliers under any provision of this
Terms and Your exclusive remedy for all of the foregoing shall be
limited to the amount actually paid by You through the Service or 100
USD if You haven\'t purchased anything through the Service.

In no event shall the Company be liable for any errors or omissions made
by a user in providing or requesting a Good, such as a Freelancer's
mistake in providing a Deposition Good to an Attorney (such as an error
in a transcript, or a loss of a transcript), or for any cancellation
related to provision of a Good.

You hereby indemnify, defend and hold harmless the Company, its
Affiliates, Sublicensees and assigns and each of its and their
respective directors, officers and employees (each, a "Company
Indemnified Party" and collectively, the "Company Indemnified Parties")
from and against any and all losses suffered or incurred by any Company
Indemnified Party arising out of, related to or connected with (a) a
breach of any of You or Your Affiliates' representations, warranties,
covenants or agreements contained in this Agreement or in any ancillary
agreements, (b) a claim arising out of or related to a transaction You
scheduled (or otherwise arranged or attempted to arrange) with another
user using the Service, including a claim related to a cancellation,
deficiency, or failure to provide a Good.

To the maximum extent permitted by applicable law, in no event shall the
Company or its suppliers be liable for any special, incidental,
indirect, or consequential damages whatsoever (including, but not
limited to, damages for affecting the outcome of a lawsuit or a client's
legal position, loss of profits, loss of data or other information, for
business interruption, for personal injury, loss of privacy arising out
of or in any way related to the use of or inability to use the Service,
third-party software and/or third-party hardware used with the Service,
or otherwise in connection with any provision of this Terms), even if
the Company or any supplier has been advised of the possibility of such
damages and even if the remedy fails of its essential purpose.

Some states do not allow the exclusion of implied warranties or
limitation of liability for incidental or consequential damages, which
means that some of the above limitations may not apply. In these states,
each party\'s liability will be limited to the greatest extent permitted
by law.

\"AS IS\" and \"AS AVAILABLE\" Disclaimer

The Service is provided to You \"AS IS\" and \"AS AVAILABLE\" and with
all faults and defects without warranty of any kind. To the maximum
extent permitted under applicable law, the Company, on its own behalf
and on behalf of its Affiliates and its and their respective licensors
and service providers, expressly disclaims all warranties, whether
express, implied, statutory or otherwise, with respect to the Service,
including all implied warranties of merchantability, fitness for a
particular purpose, title and non-infringement, and warranties that may
arise out of course of dealing, course of performance, usage or trade
practice. Without limitation to the foregoing, the Company provides no
warranty or undertaking, and makes no representation of any kind that
the Service will meet Your requirements, achieve any intended results,
be compatible or work with any other software, applications, systems or
services, operate without interruption, meet any performance or
reliability standards or be error free or that any errors or defects can
or will be corrected.

Without limiting the foregoing, neither the Company nor any of the
company\'s provider makes any representation or warranty of any kind,
express or implied: (i) as to the operation or availability of the
Service, or the information, content, and materials or products included
thereon; (ii) that the Service will be uninterrupted or error-free;
(iii) as to the accuracy, reliability, or currency of any information or
content provided through the Service; or (iv) that the Service, its
servers, the content, or e-mails sent from or on behalf of the Company
are free of viruses, scripts, trojan horses, worms, malware, timebombs
or other harmful components.

Some jurisdictions do not allow the exclusion of certain types of
warranties or limitations on applicable statutory rights of a consumer,
so some or all of the above exclusions and limitations may not apply to
You. But in such a case the exclusions and limitations set forth in this
section shall be applied to the greatest extent enforceable under
applicable law.

Note that certain use of the Service may form part of beta testing.

Bypassing the Service

You are strictly prohibited from using the Service to identify potential
opportunities for receiving or providing a Good and then ordering or
providing that Good without using the Service. The Company reserves all
rights to take legal action against any user so bypassing the Service.

Governing Law

The laws of the Commonwealth of Pennsylvania, excluding its conflicts of
law rules, shall govern these Terms and Conditions and Your use of the
Service. Your use of the Application may also be subject to other local,
state, national, or international laws.

Disputes Resolution

If a dispute arises between You and the Company regarding the Service,
You agree to first try to resolve the dispute informally by contacting
the Company. If a resolution is not reached informally, you and the
Company agree to resolve any claim, dispute, or controversy that arises
out of or relates to this Agreement, the other Terms and Conditions,
your relationship with the Company, or the Service by arbitration
administered by the American Arbitration Association under its
Commercial Arbitration Rules. The number of arbitrators shall be one.
The place of arbitration shall be Philadelphia, Pennsylvania.
Pennsylvania law shall apply. Judgment on the award rendered by the
arbitrator may be entered in any court having jurisdiction thereof. This
arbitration requirement applies to all users except those located
outside of the United States and its territories.

For European Union (EU) Users

If You are a European Union consumer, you will benefit from any
mandatory provisions of the law of the country in which you are resident
in.

United States Legal Compliance

You represent and warrant that (i) You are not located in a country that
is subject to the United States government embargo, or that has been
designated by the United States government as a \"terrorist supporting\"
country, and (ii) You are not listed on any United States government
list of prohibited or restricted parties.

Severability and Waiver

## Severability

If any provision of these Terms is held to be unenforceable or invalid,
such provision will be changed and interpreted to accomplish the
objectives of such provision to the greatest extent possible under
applicable law and the remaining provisions will continue in full force
and effect.

## Waiver

Except as provided herein, the failure to exercise a right or to require
performance of an obligation under this Terms shall not effect a
party\'s ability to exercise such right or require such performance at
any time thereafter nor shall be the waiver of a breach constitute a
waiver of any subsequent breach.

Translation Interpretation

These Terms and Conditions may have been translated if We have made them
available to You on our Service. You agree that the original English
text shall prevail in the case of a dispute.

Changes to These Terms and Conditions

We reserve the right, at Our sole discretion, to modify or replace these
Terms at any time. If a revision is material We will make reasonable
efforts to provide at least 30 days\' notice prior to any new terms
taking effect. What constitutes a material change will be determined at
Our sole discretion.

By continuing to access or use Our Service after those revisions become
effective, You agree to be bound by the revised terms. If You do not
agree to the new terms, in whole or in part, please stop using the
website and the Service.

Contact Us

If you have any questions about these Terms and Conditions, You can
contact us:

-   By email: <rl@thedepoappcompany.com>`;

export default MD;
