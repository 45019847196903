import React from "react";
import Button from "react-bootstrap/Button";
import { BsArrowRepeat, BsPlusLg } from "react-icons/bs";
import "./LoaderButton.css";

const LoaderButton = ({
	isLoading,
	className = "",
	disabled = false,
	...props
}) => {
	//
	return (
		<>
			<Button
				disabled={disabled || isLoading}
				className={`LoaderButton ${className}`}
				{...props}
			>
				{isLoading && <BsArrowRepeat className="spinning" />}
				{props.children}
			</Button>
		</>
	);
};

const LoaderButtonPlus = ({
	isLoading,
	className = "",
	disabled = false,
	...props
}) => {
	//
	return (
		<>
			<Button
				disabled={disabled || isLoading}
				className={`LoaderButton ${className}`}
				{...props}
			>
				{props.children}{" "}
				{!isLoading && <BsPlusLg className="plus-btn" />}
			</Button>
		</>
	);
};

export { LoaderButton, LoaderButtonPlus };
