import { useState } from "react";

export const useFormFields = (initialState) => {
  const [fields, setValues] = useState(initialState);
  console.log("CHECK fieldSTATE IN PASSWORD VIEW: ", fields);

  const handleFieldChange = (event) => {
    setValues((prevFields) => ({
      ...prevFields,
      [event.target.id]: event.target.value,
      userRole:
        event.target.id.includes("userRoleRadio") ||
        event.target.id.includes("userRoleSelect")
          ? event.target.value
          : prevFields.userRole,
    }));
  };

  const setFieldValue = (fieldId, value) => {
    setValues((prevFields) => ({
      ...prevFields,
      [fieldId]: value,
      userRole:
        fieldId.includes("userRoleRadio") || fieldId.includes("userRoleSelect")
          ? value
          : prevFields.userRole,
    }));
  };

  const setAnyFieldValue = (fieldId, value) => {
    setValues((prevFields) => ({
      ...prevFields,
      [fieldId]: value,
    }));
  };

  return [fields, handleFieldChange, setFieldValue, setAnyFieldValue];
};
