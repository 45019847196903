import React, { useState, useEffect } from "react";
import { isIOS, isSafari } from "../../libs/utilities";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpFromBracket,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import "../InstallPrompt/InstallPrompt.css";
import DepoAppIcon from "../../assets/icons/depo-app-icon.svg"; // Adjust the path as needed

const IOSInstallPrompt = () => {
  const [showInstallPrompt, setShowInstallPrompt] = useState(false);

  useEffect(() => {
    if (isSafari() && !window.navigator.standalone) {
      setShowInstallPrompt(true);
    }
  }, []);

  const handleDismiss = (e) => {
    e.stopPropagation();
    setShowInstallPrompt(false);
  };

  if (!showInstallPrompt) {
    return null;
  }

  return (
    <div
      className={`ios-install-prompt ${showInstallPrompt ? "show" : ""}`}
      onClick={handleDismiss}
    >
      <div className="install-prompt-icon-container">
        <img
          src={DepoAppIcon}
          alt="Depo App Icon"
          className="install-prompt-icon"
        />
      </div>
      <p>
        Install Depo App to your home screen as an App for a better experience
        with push notifications.
      </p>
      <div className="ios-instructions">
        <div className="ios-instruction">
          <FontAwesomeIcon icon={faArrowUpFromBracket} size="2x" />
          <p>Tap the share button</p>
        </div>
        <div className="ios-instruction">
          <FontAwesomeIcon icon={faPlus} size="2x" className="add-icon" />
          <p>Then "Add to Home Screen"</p>
        </div>
      </div>
    </div>
  );
};

export default IOSInstallPrompt;
