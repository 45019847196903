import { useContext } from "react";
import Media from "react-media";
import Button from "react-bootstrap/Button";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
import { useState, useEffect } from "react";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import NavDropdown from "react-bootstrap/NavDropdown";
import Offcanvas from "react-bootstrap/Offcanvas";
import Image from "react-bootstrap/Image";
import { LinkContainer } from "react-router-bootstrap";
import { Auth, API } from "aws-amplify";
import { ApplicationContext } from "../../context/GlobalState";
import { userHasAuthenticated, updateUser } from "../../context/actions";
import { useNavigate } from "react-router-dom";
import "./NavigationBar.css";

const NavigationBar = () => {
  const [state, dispatch] = useContext(ApplicationContext);
  const [loading, setLoading] = useState(false);
  const [showOffcanvas, setShowOffcanvas] = useState(false);
  const { user, barRef, isAuthenticated } = state;

  console.log("NAV auth?: ", isAuthenticated);

  console.log("NAV MENU state?: ", state);

  const nav = useNavigate();

  const handleLogout = async () => {
    setLoading(true);

    // NOTE: handle logout from AWS
    await Auth.signOut();
    dispatch(updateUser(null));
    dispatch(userHasAuthenticated(false));
    setLoading(false);
    nav("/login");
  };

  useEffect(() => {
    // Scroll to the top of the page when the component mounts
    window.scrollTo(0, 0);
  });

  useEffect(() => {
    if (loading) {
      barRef?.current?.continuousStart();
    } else {
      barRef?.current?.complete();
    }
  }, [loading]);

  const handleNavigation = (route) => {
    nav(route);
    setShowOffcanvas(false); // Close the menu
  };

  return (
    <>
      {["xxl"].map((expand) => (
        <Navbar
          key={expand}
          bg="light"
          variant="light"
          expand={expand}
          className="mb-3"
        >
          <Container fluid>
            <LinkContainer to="/">
              <Navbar.Brand className="nav-bar-brand">
                {/*
                <Image
                  src="../icons/depo-app-logo.svg" //isAuthenticated ? "../icons/xxx.svg" : "./icons/xxx.svg"
                  className="nav-logo"
                  responsive="true"
                />
                */}

                <svg
                  viewBox="0 0 1920 450"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="nav-logo"
                >
                  <g id="depo-app/brand/logo/original/depo-app-brand-logo-original">
                    <g id="depo-app/brand/logo/original/depo-app-brand-logo-original_2">
                      <g id="group">
                        <path
                          id="Vector5"
                          d="M900.068 368.936C878.828 368.936 859.624 364.291 842.457 355.003C825.581 345.432 812.051 332.625 801.867 316.581C791.974 300.256 787.027 282.101 787.027 262.116C787.027 242.131 791.974 224.258 801.867 208.495C811.76 192.451 825.29 179.785 842.457 170.496C859.624 160.926 878.682 156.141 899.632 156.141C921.163 156.141 940.367 160.926 957.243 170.496C974.41 179.785 987.94 192.451 997.833 208.495C1007.73 224.258 1012.67 242.131 1012.67 262.116C1012.67 282.101 1007.73 300.256 997.833 316.581C987.94 332.625 974.41 345.432 957.243 355.003C940.367 364.291 921.309 368.936 900.068 368.936ZM899.632 317.426C910.397 317.426 919.854 315.174 928.001 310.67C936.439 305.885 942.84 299.411 947.205 291.249C951.86 282.804 954.188 273.234 954.188 262.538C954.188 251.842 951.86 242.413 947.205 234.25C942.549 226.087 936.148 219.754 928.001 215.25C919.854 210.465 910.397 208.073 899.632 208.073C889.157 208.073 879.846 210.465 871.699 215.25C863.552 219.754 857.15 226.087 852.495 234.25C847.84 242.413 845.512 251.842 845.512 262.538C845.512 273.234 847.84 282.804 852.495 291.249C857.15 299.411 863.552 305.885 871.699 310.67C879.846 315.174 889.157 317.426 899.632 317.426Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector"
                          d="M113.041 368.936C91.8002 368.936 72.5964 364.291 55.4293 355.003C38.5532 345.432 25.0232 332.625 14.8393 316.581C4.94645 300.256 0 282.101 0 262.116C0 242.131 4.94645 224.258 14.8393 208.495C24.7322 192.451 38.2622 179.785 55.4293 170.496C72.5964 160.926 91.6548 156.141 112.604 156.141C134.136 156.141 153.34 160.926 170.216 170.496C187.383 179.785 200.913 192.451 210.806 208.495C220.699 224.258 225.645 242.131 225.645 262.116C225.645 282.101 220.699 300.256 210.806 316.581C200.913 332.625 187.383 345.432 170.216 355.003C153.34 364.291 134.281 368.936 113.041 368.936ZM112.604 317.426C123.37 317.426 132.827 315.174 140.974 310.67C149.412 305.885 155.813 299.411 160.178 291.249C164.833 282.804 167.161 273.234 167.161 262.538C167.161 251.842 164.833 242.413 160.178 234.25C155.522 226.087 149.121 219.754 140.974 215.25C132.827 210.465 123.37 208.073 112.604 208.073C102.13 208.073 92.8186 210.465 84.6715 215.25C76.5244 219.754 70.1232 226.087 65.4677 234.25C60.8122 242.413 58.4845 251.842 58.4845 262.538C58.4845 273.234 60.8122 282.804 65.4677 291.249C70.1232 299.411 76.5244 305.885 84.6715 310.67C92.8186 315.174 102.13 317.426 112.604 317.426Z"
                          fill="white"
                        />
                        <path
                          id="Vector_2"
                          d="M113.041 368.936C91.8002 368.936 72.5964 364.291 55.4293 355.003C38.5532 345.432 25.0232 332.625 14.8393 316.581C4.94645 300.256 0 282.101 0 262.116C0 242.131 4.94645 224.258 14.8393 208.495C24.7322 192.451 38.2622 179.785 55.4293 170.496C72.5964 160.926 91.6548 156.141 112.604 156.141C134.136 156.141 153.34 160.926 170.216 170.496C187.383 179.785 200.913 192.451 210.806 208.495C220.699 224.258 225.645 242.131 225.645 262.116C225.645 282.101 220.699 300.256 210.806 316.581C200.913 332.625 187.383 345.432 170.216 355.003C153.34 364.291 134.281 368.936 113.041 368.936ZM112.604 317.426C123.37 317.426 132.827 315.174 140.974 310.67C149.412 305.885 155.813 299.411 160.178 291.249C164.833 282.804 167.161 273.234 167.161 262.538C167.161 251.842 164.833 242.413 160.178 234.25C155.522 226.087 149.121 219.754 140.974 215.25C132.827 210.465 123.37 208.073 112.604 208.073C102.13 208.073 92.8186 210.465 84.6715 215.25C76.5244 219.754 70.1232 226.087 65.4677 234.25C60.8122 242.413 58.4845 251.842 58.4845 262.538C58.4845 273.234 60.8122 282.804 65.4677 291.249C70.1232 299.411 76.5244 305.885 84.6715 310.67C92.8186 315.174 102.13 317.426 112.604 317.426Z"
                          fill="white"
                        />
                        <path
                          id="Vector2"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M112.604 156.141C133.03 156.141 151.361 160.447 167.597 169.059C168.476 169.525 169.349 170.004 170.216 170.496C187.383 179.784 200.913 192.451 210.806 208.495C220.699 224.258 225.645 242.131 225.645 262.116C225.645 297.463 224.336 332.975 224.336 368.421C187.24 368.421 150.113 368.936 113.041 368.936C91.8002 368.936 72.5964 364.291 55.4293 355.003C54.3746 354.404 53.3329 353.794 52.3042 353.17C36.875 343.819 24.3867 331.623 14.8393 316.581L14.6061 316.195L14.3792 315.815C13.3141 314.023 12.3082 312.209 11.3614 310.373C3.78711 295.688 0 279.603 0 262.116C0 260.867 0.0193298 259.626 0.0579728 258.394C0.637635 239.905 5.56474 223.272 14.8393 208.495C16.076 206.49 17.3694 204.537 18.7197 202.637C28.1716 189.337 40.4081 178.623 55.4293 170.496C72.4932 160.983 91.4258 156.198 112.227 156.141L112.604 156.141ZM140.974 310.67C132.827 315.174 123.37 317.426 112.604 317.426C102.13 317.426 92.8186 315.174 84.6715 310.67C76.5244 305.885 70.1232 299.411 65.4677 291.249C60.8122 282.804 58.4845 273.234 58.4845 262.538C58.4845 251.842 60.8122 242.413 65.4677 234.25C70.1232 226.087 76.5244 219.754 84.6715 215.25C92.3094 210.764 100.97 208.381 110.654 208.101C111.3 208.082 111.95 208.073 112.604 208.073C122.024 208.073 130.442 209.904 137.857 213.568C138.917 214.092 139.955 214.652 140.974 215.25C148.102 219.191 153.894 224.532 158.35 231.275C158.986 232.238 159.596 233.23 160.178 234.25C164.833 242.413 167.161 251.842 167.161 262.538C167.161 271.897 165.379 280.394 161.814 288.029C161.305 289.12 160.76 290.193 160.178 291.249C156.086 298.901 150.204 305.069 142.532 309.753C142.021 310.066 141.501 310.371 140.974 310.67Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector1"
                          d="M224.336 0H167.597V151.035C190.869 161.397 212.223 179.553 225.467 201.465C225.342 178.049 225.167 154.619 224.991 131.186L224.991 131.144L224.991 131.134C224.663 87.4155 224.336 43.6831 224.336 0Z"
                          fill="#036136"
                        />
                        <path
                          id="Vector6"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1262.22 156.141C1282.64 156.141 1300.97 160.447 1317.21 169.059C1318.09 169.525 1318.96 170.004 1319.83 170.496C1337 179.784 1350.53 192.451 1360.42 208.495C1370.31 224.258 1375.26 242.131 1375.26 262.116C1375.26 297.463 1373.95 332.975 1373.95 368.421C1336.85 368.421 1299.73 368.936 1262.65 368.936C1241.41 368.936 1222.21 364.291 1205.04 355.003C1203.99 354.404 1202.95 353.794 1201.92 353.17C1186.49 343.819 1174 331.623 1164.45 316.581L1164.22 316.195L1163.99 315.815C1162.93 314.023 1161.92 312.209 1160.97 310.373C1153.4 295.688 1149.61 279.603 1149.61 262.116C1149.61 260.867 1149.63 259.626 1149.67 258.394C1150.25 239.905 1155.18 223.272 1164.45 208.495C1165.69 206.49 1166.98 204.537 1168.33 202.637C1177.78 189.337 1190.02 178.623 1205.04 170.496C1222.11 160.983 1241.04 156.198 1261.84 156.141L1262.22 156.141ZM1290.59 310.67C1282.44 315.174 1272.98 317.426 1262.22 317.426C1251.74 317.426 1242.43 315.174 1234.28 310.67C1226.14 305.885 1219.74 299.411 1215.08 291.249C1210.42 282.804 1208.1 273.234 1208.1 262.538C1208.1 251.842 1210.42 242.413 1215.08 234.25C1219.74 226.087 1226.14 219.754 1234.28 215.25C1241.92 210.764 1250.58 208.381 1260.27 208.101C1260.91 208.082 1261.56 208.073 1262.22 208.073C1271.64 208.073 1280.05 209.904 1287.47 213.568C1288.53 214.092 1289.57 214.652 1290.59 215.25C1297.71 219.191 1303.51 224.532 1307.96 231.275C1308.6 232.238 1309.21 233.23 1309.79 234.25C1314.45 242.413 1316.77 251.842 1316.77 262.538C1316.77 271.897 1314.99 280.394 1311.43 288.029C1310.92 289.12 1310.37 290.193 1309.79 291.249C1305.7 298.901 1299.82 305.069 1292.14 309.753C1291.63 310.066 1291.11 310.371 1290.59 310.67Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector7"
                          d="M1375.1 150.877L1317.21 151.035C1341.02 161.635 1361.53 179.049 1375.08 201.465C1375.1 177.632 1375.08 168.211 1375.1 150.877Z"
                          fill="#036136"
                        />
                        <path
                          id="Vector9"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1776.84 360.069C1789.06 365.98 1802.74 368.935 1817.87 368.935C1837.37 368.935 1854.82 364.291 1870.24 355.002C1885.67 345.714 1897.74 333.047 1906.47 317.003C1915.49 300.959 1920 282.945 1920 262.96C1920 242.694 1915.49 224.539 1906.47 208.495C1897.74 192.451 1885.67 179.784 1870.24 170.496C1854.82 161.207 1837.37 156.562 1817.87 156.562C1802.74 156.562 1789.06 159.659 1776.84 165.851C1769.83 169.49 1763.58 173.905 1758.08 179.098V160.785H1700.9V450H1757.64V346.757C1763.16 351.943 1769.57 356.381 1776.84 360.069ZM1835.76 311.092C1827.62 315.596 1818.31 317.848 1807.83 317.848C1797.36 317.848 1788.05 315.596 1779.9 311.092C1772.04 306.307 1765.79 299.833 1761.13 291.67C1756.77 283.226 1754.58 273.515 1754.58 262.538C1754.58 251.842 1756.77 242.412 1761.13 234.25C1765.79 225.805 1772.19 219.331 1780.34 214.828C1788.48 210.043 1797.65 207.65 1807.83 207.65C1818.31 207.65 1827.62 210.043 1835.76 214.828C1843.91 219.331 1850.17 225.805 1854.53 234.25C1859.19 242.412 1861.52 251.842 1861.52 262.538C1861.52 273.515 1859.19 283.226 1854.53 291.67C1850.17 299.833 1843.91 306.307 1835.76 311.092Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector8"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M1504.98 360.069C1517.2 365.98 1530.88 368.935 1546.01 368.935C1565.5 368.935 1582.96 364.291 1598.38 355.002C1613.8 345.714 1625.88 333.047 1634.61 317.003C1643.63 300.959 1648.14 282.945 1648.14 262.96C1648.14 242.694 1643.63 224.539 1634.61 208.495C1625.88 192.451 1613.8 179.784 1598.38 170.496C1582.96 161.207 1565.5 156.562 1546.01 156.562C1530.88 156.562 1517.2 159.659 1504.98 165.851C1497.97 169.49 1491.72 173.905 1486.22 179.098V160.785H1429.04V450H1485.78V346.757C1491.3 351.943 1497.7 356.381 1504.98 360.069ZM1563.9 311.092C1555.76 315.596 1546.45 317.848 1535.97 317.848C1525.5 317.848 1516.18 315.596 1508.04 311.092C1500.18 306.307 1493.93 299.833 1489.27 291.67C1484.91 283.226 1482.72 273.515 1482.72 262.538C1482.72 251.842 1484.91 242.412 1489.27 234.25C1493.93 225.805 1500.33 219.331 1508.47 214.828C1516.62 210.043 1525.79 207.65 1535.97 207.65C1546.45 207.65 1555.76 210.043 1563.9 214.828C1572.05 219.331 1578.31 225.805 1582.67 234.25C1587.33 242.412 1589.65 251.842 1589.65 262.538C1589.65 273.515 1587.33 283.226 1582.67 291.67C1578.31 299.833 1572.05 306.307 1563.9 311.092Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector4"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M605.075 360.069C617.295 365.98 630.971 368.935 646.101 368.935C665.596 368.935 683.054 364.291 698.475 355.002C713.897 345.714 725.972 333.047 734.701 317.003C743.721 300.959 748.231 282.945 748.231 262.96C748.231 242.694 743.721 224.539 734.701 208.495C725.972 192.451 713.897 179.784 698.475 170.496C683.054 161.207 665.596 156.562 646.101 156.562C630.971 156.562 617.295 159.659 605.075 165.851C598.066 169.49 591.81 173.905 586.307 179.098V160.785H529.132V450H585.871V346.758C591.395 351.944 597.796 356.381 605.075 360.069ZM663.996 311.092C655.849 315.596 646.538 317.848 636.063 317.848C625.588 317.848 616.277 315.596 608.13 311.092C600.274 306.307 594.018 299.833 589.363 291.67C584.998 283.226 582.816 273.515 582.816 262.538C582.816 251.842 584.998 242.412 589.363 234.25C594.018 225.805 600.419 219.331 608.566 214.828C616.714 210.043 625.879 207.65 636.063 207.65C646.538 207.65 655.849 210.043 663.996 214.828C672.143 219.331 678.399 225.805 682.763 234.25C687.419 242.412 689.746 251.842 689.746 262.538C689.746 273.515 687.419 283.226 682.763 291.67C678.399 299.833 672.143 306.307 663.996 311.092Z"
                          fill="#09305D"
                        />
                        <path
                          id="Vector3"
                          fillRule="evenodd"
                          clipRule="evenodd"
                          d="M319.629 355.425C336.796 364.432 356.436 368.936 378.55 368.936C395.717 368.936 411.575 365.98 426.123 360.069C440.962 354.158 453.474 345.433 463.658 333.892L430.051 301.382C423.65 308.419 416.085 313.767 407.356 317.426C398.627 320.804 388.879 322.492 378.113 322.492C366.184 322.492 355.709 320.1 346.689 315.315C337.669 310.248 330.686 303.211 325.739 294.204C323.879 290.492 322.39 286.533 321.273 282.327L475.442 281.96C476.606 277.175 477.333 272.953 477.624 269.294C478.206 265.353 478.497 261.553 478.497 257.894C478.497 238.472 473.987 221.161 464.967 205.962C456.238 190.481 444.018 178.377 428.305 169.651C412.884 160.644 394.99 156.141 374.622 156.141C353.963 156.141 335.196 160.926 318.32 170.496C301.443 179.785 288.059 192.451 278.166 208.495C268.564 224.258 263.763 242.272 263.763 262.538C263.763 283.086 268.71 301.382 278.603 317.426C288.786 333.47 302.462 346.136 319.629 355.425ZM423.373 240.654C422.221 235.811 420.665 231.424 418.703 227.495C414.921 219.332 409.247 213.139 401.682 208.917C394.408 204.414 385.388 202.162 374.622 202.162C363.274 202.162 353.381 204.695 344.943 209.762C336.505 214.547 329.958 221.443 325.303 230.45C323.672 233.711 322.345 237.208 321.324 240.941L423.373 240.654Z"
                          fill="#09305D"
                        />
                      </g>
                    </g>
                  </g>
                </svg>
              </Navbar.Brand>
            </LinkContainer>
            <Navbar.Toggle
              aria-controls={`offcanvasNavbar-expand-${expand}`}
              onClick={() => setShowOffcanvas(true)}
            />
            <Navbar.Offcanvas
              id={`offcanvasNavbar-expand-${expand}`}
              aria-labelledby={`offcanvasNavbarLabel-expand-${expand}`}
              placement="end"
              show={showOffcanvas}
              onHide={() => setShowOffcanvas(false)}
            >
              <Offcanvas.Header className="mobile-menu-header" closeButton>
                <Offcanvas.Title id={`offcanvasNavbarLabel-expand-${expand}`}>
                  {isAuthenticated &&
                  (user?.userRole === "System_Admin" ||
                    user?.userRole === "System_Manager")
                    ? "System Dashboard"
                    : user?.userRole === "Law_Firm_Admin" ||
                      user?.userRole === "Law_Firm_Manager" ||
                      user?.userRole === "Lawyer" ||
                      user?.userRole === "Legal_Assistant"
                    ? "Deposition Dashboard"
                    : user?.userRole === "Court_Reporter" ||
                      user?.userRole === "Scopist" ||
                      user?.userRole === "Videgrapher" ||
                      user?.userRole === "Interpreter"
                    ? "Freelancer Dashboard"
                    : "Public Menu"}
                </Offcanvas.Title>
              </Offcanvas.Header>

              <Media
                query="(max-width: 1399px)"
                render={() => (
                  <Offcanvas.Body className="mobile-menu">
                    {/* NOTE: Set an activeKey so the route in the address bar matches and highlights the link selected */}
                    <Nav
                      className="justify-content-end flex-grow-1"
                      activeKey={window.location.pathname}
                    >
                      {isAuthenticated ? (
                        <div>
                          <h6 style={{ marginLeft: "1rem" }}>
                            <em>
                              <strong>Welcome </strong>
                              {state.firstName + " " + state.lastName}
                            </em>
                          </h6>
                          <p style={{ fontSize: ".72rem", marginLeft: "1rem" }}>
                            <strong>Org ID: </strong>
                            {state.user.organizationId}
                          </p>

                          <LinkContainer to="/">
                            <Nav.Link onClick={() => handleNavigation("/")}>
                              Dashboard
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/settings">
                            <Nav.Link
                              onClick={() => handleNavigation("/settings")}
                            >
                              Settings
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/logout" onClick={handleLogout}>
                            <Nav.Link
                              onClick={() => handleNavigation("/logout")}
                            >
                              Logout
                            </Nav.Link>
                          </LinkContainer>
                        </div>
                      ) : (
                        <>
                          <LinkContainer to="/register">
                            <Nav.Link
                              onClick={() => handleNavigation("/register")}
                            >
                              Register
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/login">
                            <Nav.Link
                              onClick={() => handleNavigation("/login")}
                            >
                              Login
                            </Nav.Link>
                          </LinkContainer>
                        </>
                      )}

                      {isAuthenticated &&
                      (user.userRole === "System_Admin" ||
                        user.userRole === "System_Manager") ? (
                        <>
                          <LinkContainer to="/lawyers">
                            <Nav.Link
                              onClick={() => handleNavigation("/lawyers")}
                            >
                              Lawyers
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/court-reporters">
                            <Nav.Link
                              onClick={() =>
                                handleNavigation("/court-reporters")
                              }
                            >
                              Court Reporters
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <Nav.Link
                              onClick={() => handleNavigation("/depositions")}
                            >
                              Depositions
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/rejected-reasons">
                            <Nav.Link
                              onClick={() =>
                                handleNavigation("/rejected-reasons")
                              }
                            >
                              Rejected Reasons
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/customer-support">
                            <Nav.Link
                              onClick={() =>
                                handleNavigation("/customer-support")
                              }
                            >
                              Support Cases
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/invoices">
                            <Nav.Link
                              onClick={() => handleNavigation("/invoices")}
                            >
                              Invoices
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/transactions">
                            <Nav.Link
                              onClick={() => handleNavigation("/transactions")}
                            >
                              Transactions
                            </Nav.Link>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer
                            to="/configuration"
                            style={{ marginBottom: "1rem" }}
                          >
                            <Nav.Link
                              onClick={() => handleNavigation("/configuration")}
                            >
                              Configuration
                            </Nav.Link>
                          </LinkContainer>
                        </>
                      ) : isAuthenticated &&
                        (user?.userRole === "Law_Firm_Admin" ||
                          user?.userRole === "Law_Firm_Manager" ||
                          user?.userRole === "Lawyer" ||
                          user?.userRole === "Legal_Assistant") ? (
                        <>
                          <LinkContainer to="/deposition/new">
                            <Nav.Link
                              onClick={() =>
                                handleNavigation("/deposition/new")
                              }
                            >
                              Schedule New Depo
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/calendar">
                            <Nav.Link
                              onClick={() => handleNavigation("/calendar")}
                            >
                              Calendar
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <Nav.Link
                              onClick={() => handleNavigation("/depositions")}
                            >
                              Depositions
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/freelancers">
                            <Nav.Link
                              onClick={() => handleNavigation("/freelancers")}
                            >
                              Freelancer Inventory
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/invite-user">
                            <Nav.Link
                              onClick={() => handleNavigation("/invite-user")}
                            >
                              Invite Users
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <Nav.Link
                              onClick={() => handleNavigation("/payments")}
                            >
                              Payments
                            </Nav.Link>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/invoices">
                            <Nav.Link
                              onClick={() => handleNavigation("/invoices")}
                            >
                              Invoices
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/support-cases">
                            <Nav.Link
                              onClick={() => handleNavigation("/support-cases")}
                            >
                              Support Cases
                            </Nav.Link>
                          </LinkContainer>
                        </>
                      ) : isAuthenticated &&
                        (user?.userRole === "Court_Reporter" ||
                          user?.userRole === "Scopist" ||
                          user?.userRole === "Videgrapher" ||
                          user?.userRole === "Interpreter") ? (
                        <>
                          <LinkContainer to="/job/new">
                            <Nav.Link
                              onClick={() => handleNavigation("/job/new")}
                            >
                              Create New Job
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/jobs">
                            <Nav.Link onClick={() => handleNavigation("/jobs")}>
                              Jobs
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/calendar">
                            <Nav.Link
                              onClick={() => handleNavigation("/calendar/new")}
                            >
                              Calendar
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <Nav.Link
                              onClick={() => handleNavigation("/depositions")}
                            >
                              Deposition Requests
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/freelancers">
                            <Nav.Link
                              onClick={() => handleNavigation("/freelancers")}
                            >
                              Subcontractor Inventory
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/invite-user">
                            <Nav.Link
                              onClick={() => handleNavigation("/invite-user")}
                            >
                              Invite Users
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <Nav.Link
                              onClick={() => handleNavigation("/payments")}
                            >
                              Payments
                            </Nav.Link>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/invoices">
                            <Nav.Link
                              onClick={() => handleNavigation("/invoices")}
                            >
                              Invoices
                            </Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/support-cases">
                            <Nav.Link
                              onClick={() => handleNavigation("/support-cases")}
                            >
                              Support Cases
                            </Nav.Link>
                          </LinkContainer>
                        </>
                      ) : (
                        <>
                          <>
                            <LinkContainer to="/lawyers">
                              <Nav.Link
                                onClick={() => handleNavigation("/lawyers")}
                              >
                                Lawyers
                              </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/freelancers">
                              <Nav.Link
                                onClick={() => handleNavigation("/freelancers")}
                              >
                                Freelancers
                              </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/pricing">
                              <Nav.Link
                                onClick={() => handleNavigation("/pricing")}
                              >
                                Pricing
                              </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/contact-us">
                              <Nav.Link
                                onClick={() => handleNavigation("/contact-us")}
                              >
                                Contact Us
                              </Nav.Link>
                            </LinkContainer>

                            <NavDropdown.Divider className="nav-divider" />
                            <LinkContainer to="/about-us">
                              <Nav.Link
                                onClick={() => handleNavigation("/about-us")}
                              >
                                About Us
                              </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/terms-and-conditions">
                              <Nav.Link
                                onClick={() =>
                                  handleNavigation("/terms-and-conditions")
                                }
                              >
                                Terms & Conditions
                              </Nav.Link>
                            </LinkContainer>

                            <LinkContainer to="/privacy-policy">
                              <Nav.Link
                                onClick={() =>
                                  handleNavigation("/privacy-policy")
                                }
                              >
                                Privacy Policy
                              </Nav.Link>
                            </LinkContainer>
                          </>
                        </>
                      )}
                    </Nav>

                    <Form className="mobile-narrow d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        style={{ marginRight: "1rem", marginTop: "1rem" }}
                        className="nucleus-form-fields"
                        aria-label="Search"
                      />
                      <Button
                        variant="outline-success"
                        style={{ marginTop: "1rem" }}
                      >
                        Search
                      </Button>
                    </Form>
                  </Offcanvas.Body>
                )}
              />

              <Media
                query="(min-width: 1400px)"
                render={() => (
                  <Offcanvas.Body className="">
                    {/* NOTE: Set an activeKey so the route in the address bar matches and highlights the link selected */}
                    <Nav
                      className="justify-content-end flex-grow-1 pe-3"
                      activeKey={window.location.pathname}
                    >
                      {isAuthenticated ? (
                        <>
                          <LinkContainer to="/">
                            <Nav.Link href="/">Dashboard</Nav.Link>
                          </LinkContainer>

                          <LinkContainer className="" to="/settings">
                            <Nav.Link href="/settings">Settings</Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/logout" onClick={handleLogout}>
                            <Nav.Link href="/logout">Logout</Nav.Link>
                          </LinkContainer>
                        </>
                      ) : (
                        <>
                          <LinkContainer to="/register">
                            <Nav.Link href="/register">Register</Nav.Link>
                          </LinkContainer>

                          <LinkContainer to="/login">
                            <Nav.Link href="/login">Login</Nav.Link>
                          </LinkContainer>
                        </>
                      )}

                      {isAuthenticated &&
                      (user?.userRole === "System_Admin" ||
                        user?.userRole === "System_Manager") ? (
                        <NavDropdown
                          title="Services"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                          className="dropdown-menu-adNucleus"
                        >
                          <LinkContainer to="/lawyers">
                            <NavDropdown.Item href="/lawyers">
                              Lawyers
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/court-reporters">
                            <NavDropdown.Item href="/court-reporters">
                              Court Reporters
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <NavDropdown.Item href="/depositions">
                              Depositions
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/rejected-reasons">
                            <NavDropdown.Item href="/rejected-reasons">
                              Rejected Reasons
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/customer-support">
                            <NavDropdown.Item href="/customer-support">
                              Support Cases
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/invoices">
                            <NavDropdown.Item href="/invoices">
                              Invoices
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/transactions">
                            <NavDropdown.Item href="/transactions">
                              Transactions
                            </NavDropdown.Item>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/configuration">
                            <NavDropdown.Item href="/configuration">
                              Configuration
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : isAuthenticated &&
                        (user?.userRole === "Law_Firm_Admin" ||
                          user?.userRole === "Law_Firm_Manager" ||
                          user?.userRole === "Lawyer" ||
                          user?.userRole === "Legal_Assistant") ? (
                        <NavDropdown
                          title="Services"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                          className="dropdown-menu-adNucleus"
                        >
                          <LinkContainer to="/deposition/new">
                            <NavDropdown.Item href="/deposition/new">
                              Schedule New Depo
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/calendar">
                            <NavDropdown.Item href="/calendar/new">
                              Calendar
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <NavDropdown.Item href="/depositions">
                              Depositions
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/freelancers">
                            <NavDropdown.Item href="/freelancers">
                              Freelancer Inventory
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/invite-user">
                            <NavDropdown.Item href="/invite-user">
                              Invite Users
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <NavDropdown.Item href="/payments">
                              Payments
                            </NavDropdown.Item>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/invoices">
                            <NavDropdown.Item href="/invoices">
                              Invoices
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/support-cases">
                            <NavDropdown.Item href="/support-cases">
                              Support Cases
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : isAuthenticated &&
                        (user?.userRole === "Court_Reporter" ||
                          user?.userRole === "Scopist" ||
                          user?.userRole === "Videgrapher" ||
                          user?.userRole === "Interpreter") ? (
                        <NavDropdown
                          title="Services"
                          id={`offcanvasNavbarDropdown-expand-${expand}`}
                          className="dropdown-menu-adNucleus"
                        >
                          <LinkContainer to="/job/new">
                            <NavDropdown.Item href="/job/new">
                              Create New Job
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/jobs">
                            <NavDropdown.Item href="/jobs">
                              Jobs
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/calendar">
                            <NavDropdown.Item href="/calendar/new">
                              Calendar
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/depositions">
                            <NavDropdown.Item href="/depositions">
                              Deposition Requests
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/freelancers">
                            <NavDropdown.Item href="/freelancers">
                              Subcontractor Inventory
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/invite-user">
                            <NavDropdown.Item href="/invite-user">
                              Invite Users
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/payments">
                            <NavDropdown.Item href="/payments">
                              Payments
                            </NavDropdown.Item>
                          </LinkContainer>

                          <NavDropdown.Divider className="nav-divider" />

                          <LinkContainer to="/invoices">
                            <NavDropdown.Item href="/invoices">
                              Invoices
                            </NavDropdown.Item>
                          </LinkContainer>

                          <LinkContainer to="/support-cases">
                            <NavDropdown.Item href="/support-cases">
                              Support Cases
                            </NavDropdown.Item>
                          </LinkContainer>
                        </NavDropdown>
                      ) : (
                        <>
                          <NavDropdown
                            title="Services"
                            id={`offcanvasNavbarDropdown-expand-${expand}`}
                            className="dropdown-menu-adNucleus"
                          >
                            <LinkContainer to="/lawyers">
                              <NavDropdown.Item href="/lawyers">
                                Lawyers
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/freelancers">
                              <NavDropdown.Item href="/freelancers">
                                Freelancers
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/pricing">
                              <NavDropdown.Item href="/pricing">
                                Pricing
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/contact-us">
                              <NavDropdown.Item href="/contact-us">
                                Contact Us
                              </NavDropdown.Item>
                            </LinkContainer>

                            <NavDropdown.Divider className="nav-divider" />
                            <LinkContainer to="/about-us">
                              <NavDropdown.Item href="/about-us">
                                About Us
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/terms-and-conditions">
                              <NavDropdown.Item href="/terms-and-conditions">
                                Terms & Conditions
                              </NavDropdown.Item>
                            </LinkContainer>

                            <LinkContainer to="/privacy-policy">
                              <NavDropdown.Item href="/privacy-policy">
                                Privacy Policy
                              </NavDropdown.Item>
                            </LinkContainer>
                          </NavDropdown>
                        </>
                      )}
                    </Nav>

                    <Form className="d-flex">
                      <Form.Control
                        type="search"
                        placeholder="Search"
                        className="me-2 nucleus-form-fields"
                        aria-label="Search"
                      />
                      <Button variant="outline-success">Search</Button>
                    </Form>
                  </Offcanvas.Body>
                )}
              />
            </Navbar.Offcanvas>
          </Container>
        </Navbar>
      ))}
    </>
  );
};

export default NavigationBar;
