export const userHasAuthenticated = (bool) => ({
  type: "USER_HAS_AUTHENTICATED",
  payload: bool,
});

export const isModalOpen = (bool) => ({
  type: "IS_MODAL_OPEN",
  payload: bool,
});

export const setBodyOverflow = (overflow) => ({
  type: "SET_BODY_OVERFLOW",
  payload: overflow,
});

export const addPaymentMethod = (paymentMethod) => ({
  type: "ADD_PAYMENT_METHOD",
  payload: paymentMethod,
});

export const updatePaymentMethod = (index, updatedPaymentMethod) => ({
  type: "UPDATE_PAYMENT_METHOD",
  payload: { index, updatedPaymentMethod },
});

export const removePaymentMethod = (index) => ({
  type: "REMOVE_PAYMENT_METHOD",
  payload: index,
});

export const setUserAccountStatus = (accountStatus) => ({
  type: "USER_ACCOUNT_STATUS",
  payload: accountStatus,
});

export const updateUser = (data) => ({
  type: "UPDATE_USER",
  payload: data,
});

export const setFirstName = (firstName) => ({
  type: "SET_FIRST_NAME",
  payload: firstName,
});

export const setLastName = (lastName) => ({
  type: "SET_LAST_NAME",
  payload: lastName,
});

export const setUserEmail = (email) => ({
  type: "SET_USER_EMAIL",
  payload: email,
});

export const setUserCellPhone = (cellPhone) => ({
  type: "SET_USER_CELL_PHONE",
  payload: cellPhone,
});

export const setOrgOfficePhone = (officePhone) => ({
  type: "SET_ORG_OFFICE_PHONE",
  payload: officePhone,
});

export const setUserBillingAddress = (billingAddress) => ({
  type: "SET_USER_BILLING_ADDRESS",
  payload: billingAddress,
});

export const setUserSignature = (signature) => ({
  type: "SET_USER_SIGNATURE",
  payload: signature,
});

export const setUserTermsAccepted = (termsAccepted) => ({
  type: "SET_USER_TERMS_ACCEPTED",
  payload: termsAccepted,
});

export const setUserOrganizationId = (organizationId) => ({
  type: "SET_USER_ORGANIZATION_ID",
  payload: organizationId,
});

export const setCorporateName = (corporateName) => ({
  type: "SET_CORPORATE_NAME",
  payload: corporateName,
});

// v0.1 below
export const setLoadingBar = (ref) => ({
  type: "SET_LOADING_BAR",
  payload: ref,
});

export const setTheme = (theme) => ({
  type: "SET_THEME",
  payload: theme,
});

export const setActiveTab = (tab) => ({
  type: "SET_ACTIVE_TAB",
  payload: tab,
});

export const setFilterTab = (tab) => ({
  type: "SET_FILTER_TAB",
  payload: tab,
});

export const connectWallet = (wallet) => ({
  type: "CONNECT_WALLET",
  payload: wallet,
});

export const connectWalletModalOpen = () => ({
  type: "CONNECT_WALLET_MODAL_OPEN",
});

export const connectWalletModalClose = () => ({
  type: "CONNECT_WALLET_MODAL_CLOSE",
});

export const logout = () => ({
  type: "LOG_OUT",
  payload: null,
});
