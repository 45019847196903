import React, { useContext } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { ApplicationContext } from "../../context/GlobalState";

const AuthenticatedRoute = ({ children }) => {
  //
  const [state, dispatch] = useContext(ApplicationContext);
  const { pathname, search } = useLocation();
  const { isAuthenticated } = state;

  console.log("AUTH pathname: ", pathname);
  console.log("AUTH search: ", search);
  if (!isAuthenticated) {
    return <Navigate to={`/login?redirect=${pathname}${search}`} />;
  }
  console.log("AUTH CHILDREN: ", children);
  return children;
};

export default AuthenticatedRoute;
