import { useReducer, createContext } from "react";
//import { Network } from "services/web3Service";

export const ApplicationContext = createContext();

const initialState = {
  theme: "dark",
  //activeTab: "art",
  //filterTab: "trending",
  user: null,
  firstName: "",
  lastName: "",
  email: "",
  cellPhone: "",
  officePhone: "",
  organizationId: "",
  corporateName: "",
  billingAddress: {
    streetAddress: "",
    streetAddressLine2: "",
    city: "",
    stateOf: "",
    zipCode: "",
    country: "",
  },
  paymentMethods: [],
  accountStatus: null,
  isAuthenticated: false,
  isModalOpen: false,
  bodyOverflow: "auto",
  barRef: null,
};

const reducer = (state, action) => {
  switch (action.type) {
    case "USER_HAS_AUTHENTICATED":
      return {
        ...state,
        isAuthenticated: action.payload,
      };

    case "IS_MODAL_OPEN":
      return {
        ...state,
        isModalOpen: action.payload,
      };

    case "SET_BODY_OVERFLOW":
      return {
        ...state,
        bodyOverflow: action.payload,
      };

    case "ADD_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethods: [...state.paymentMethods, action.payload],
      };

    case "UPDATE_PAYMENT_METHOD":
      const updatedPaymentMethods = [...state.paymentMethods];
      updatedPaymentMethods[action.payload.index] =
        action.payload.updatedPaymentMethod;
      return {
        ...state,
        paymentMethods: updatedPaymentMethods,
      };

    case "REMOVE_PAYMENT_METHOD":
      return {
        ...state,
        paymentMethods: state.paymentMethods.filter(
          (_, index) => index !== action.payload
        ),
      };

    case "USER_ACCOUNT_STATUS":
      return {
        ...state,
        accountStatus: action.payload,
      };

    case "UPDATE_USER":
      return {
        ...state,
        user: action.payload,
      };

    case "SET_FIRST_NAME":
      return {
        ...state,
        firstName: action.payload,
      };

    case "SET_LAST_NAME":
      return {
        ...state,
        lastName: action.payload,
      };

    case "SET_USER_EMAIL":
      return {
        ...state,
        email: action.payload,
      };

    case "SET_USER_CELL_PHONE":
      return {
        ...state,
        cellPhone: action.payload,
      };

    case "SET_ORG_OFFICE_PHONE":
      return {
        ...state,
        officePhone: action.payload,
      };

    case "SET_USER_OFFICE_PHONE":
      return {
        ...state,
        officePhone: action.payload,
      };

    case "SET_USER_BILLING_ADDRESS":
      return {
        ...state,
        billingAddress: action.payload,
      };

    case "SET_USER_SIGNATURE":
      return {
        ...state,
        signature: action.payload,
      };

    case "SET_USER_TERMS_ACCEPTED":
      return {
        ...state,
        termsAccepted: action.payload,
      };

    case "SET_USER_ORGANIZATION_ID":
      return {
        ...state,
        organizationId: action.payload,
      };

    case "SET_CORPORATE_NAME":
      return {
        ...state,
        corporateName: action.payload,
      };

    case "SET_THEME":
      return {
        ...state,
        theme: action.payload,
      };

    case "SET_ACTIVE_TAB":
      return {
        ...state,
        activeTab: action.payload,
      };

    case "SET_FILTER_TAB":
      return {
        ...state,
        filterTab: action.payload,
      };

    case "CONNECT_WALLET":
      return {
        ...state,
        walletInfo: action.payload,
      };

    case "CONNECT_WALLET_MODAL_OPEN":
      return {
        ...state,
        connectWalletModalOpen: true,
      };

    case "CONNECT_WALLET_MODAL_CLOSE":
      return {
        ...state,
        connectWalletModalOpen: false,
      };

    case "SET_LOADING_BAR":
      return {
        ...state,
        barRef: action.payload,
      };

    // NOTE: Handled with userHasAuthenticated
    case "LOG_OUT":
      sessionStorage.removeItem("token");
      sessionStorage.removeItem("signature");
      //Network._resetSingletons();
      return {
        ...state,
        user: null,
        //walletInfo: null,
      };

    case "CHANGE_AFFILIATE":
      return {
        ...state,
        affiliate: action.payload,
      };

    default:
      return {
        state,
      };
  }
};

export const ApplicationContextProvider = (props) => {
  const [state, defaultDispatch] = useReducer(reducer, initialState);

  // Thunk for leveraging async actions
  const dispatch = (action) => {
    if (typeof action === "function") {
      action(dispatch);
    } else {
      defaultDispatch(action);
    }
  };

  return (
    <ApplicationContext.Provider value={[state, dispatch]}>
      {props.children}
    </ApplicationContext.Provider>
  );
};
