import React from "react";
import { Routes, Route } from "react-router-dom";

//import AppliedRoute from "../AppliedRoute";
import AuthenticatedRoute from "../AuthenticatedRoute";
import UnauthenticatedRoute from "../UnauthenticatedRoute";

import Home from "../../containers/Home";
import Login from "../../containers/Login";
import Register from "../../containers/Register";
import ResetPassword from "../../containers/ResetPassword";

import Settings from "../../containers/Settings";

import TermsAndConditions from "../../containers/TermsAndConditions";
import PrivacyPolicy from "../../containers/PrivacyPolicy";

import NewSalesLead from "../../containers/NewSalesLead";
import SalesLeads from "../../containers/SalesLeads";
import SalesLead from "../../containers/SalesLead";

import Page404 from "../../components/Page404";
//import TermsAndConditions from "./components/TermsAndConditions";
//import PrivacyPolicy from "./components/PrivacyPolicy";

const Router = ({ childProps }) => (
  <Routes>
    {/* This is our home page route for the main landing page to the app dashboard */}

    <Route
      path="/"
      exact
      element={<Home calendar={true} />}
      props={childProps}
    />

    <Route
      path="/login"
      element={
        <UnauthenticatedRoute>
          <Login />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    <Route
      path="/register"
      element={
        <UnauthenticatedRoute>
          <Register />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    <Route
      path="/terms-and-conditions"
      element={
        <UnauthenticatedRoute>
          <TermsAndConditions />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    <Route
      path="/privacy-policy"
      element={
        <UnauthenticatedRoute>
          <PrivacyPolicy />
        </UnauthenticatedRoute>
      }
      props={childProps}
    />

    {/* FIXME: This MUST BE <UnauthenticatedRoute /> Route*/}
    <Route
      path="/reset-password"
      element={<ResetPassword />}
      props={childProps}
    />

    <Route
      path="/settings"
      element={
        <AuthenticatedRoute>
          <Settings />
        </AuthenticatedRoute>
      }
    />

    <Route
      path="/deposition/new"
      element={
        <AuthenticatedRoute>
          <Home calendar={false} />
        </AuthenticatedRoute>
      }
    />

    <Route
      path="/calendar"
      element={
        <AuthenticatedRoute>
          <Home calendar={true} />
        </AuthenticatedRoute>
      }
    />

    {/* FIXME: INVESTIGATE Route for REUSE */}
    <Route
      path="/insertion-order/new"
      element={
        <AuthenticatedRoute>
          <NewSalesLead />
        </AuthenticatedRoute>
      }
    />

    {/* FIXME: INVESTIGATE Route for REUSE */}
    <Route
      path="/sales-leads"
      element={
        <AuthenticatedRoute>
          <SalesLeads />
        </AuthenticatedRoute>
      }
    />

    {/* FIXME: INVESTIGATE Route for REUSE */}
    <Route
      path="/lead-records/:leadId"
      element={
        <AuthenticatedRoute>
          <SalesLead />
        </AuthenticatedRoute>
      }
    />

    {/* This is the Login Route
    <Route path="/" exact element={<Home />} props={childProps} />*/}

    <Route path="*" element={<Page404 />} />
  </Routes>
);

export default Router;
