import NavigationBar from "../../shared/NavigationBar";
import Footer from "../../shared/Footer";
import "./Main.css";
//import clsx from "clsx";

const Main = ({
  children,
  noFooter,
  noOffset,
  noTopOffset,
  noBottomOffset,
  className,
  style,
}) => {
  /**
   * const contentClsx = clsx({
   * "mt-10": !noOffset && !noTopOffset,
   * "mb-20": !noOffset && !noBottomOffset,
   * [className]: className,
   * });
   */
  const contentClsx = "main-layout";
  console.log("TEST: ", noFooter);

  return (
    <>
      <main className={contentClsx} style={style}>
        <NavigationBar />
        {children}

        {!noFooter && <Footer className="" />}
      </main>
    </>
  );
};

export default Main;
