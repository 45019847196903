// Configure dev and PROD for CICD
// FIXME: Implement using process.env for prod!

const dev = {
  s3: {
    REGION: "us-east-1",
    BUCKET: "depo-app-uploads-api-dev-depoappattachmentsbucket-1oupaw483uz7l", //
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://w0bfzbhn3f.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ypqiffoGU",
    APP_CLIENT_ID: "3oeup125ie64un9mum78nh9sn1",
    IDENTITY_POOL_ID: "us-east-1:76dc3569-a215-42bb-a4a1-eafdec30384e",
  },

  Places_API_KEY: "AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c",
};

const prod = {
  // FIXME: Needs prod credentials
  s3: {
    REGION: "us-east-1",
    BUCKET: "depo-app-uploads-api-dev-depoappattachmentsbucket-1oupaw483uz7l", //
  },

  apiGateway: {
    REGION: "us-east-1",
    URL: "https://w0bfzbhn3f.execute-api.us-east-1.amazonaws.com/dev",
  },

  cognito: {
    REGION: "us-east-1",
    USER_POOL_ID: "us-east-1_ypqiffoGU",
    APP_CLIENT_ID: "3oeup125ie64un9mum78nh9sn1",
    IDENTITY_POOL_ID: "us-east-1:76dc3569-a215-42bb-a4a1-eafdec30384e",
  },

  Places_API_KEY: "AIzaSyCf9gHej_w8002o5tBNJ9jaKO90kDRLM8c",
};

// Extract the subdomain from the URL
const hostname = window.location.hostname;
const hostnameParts = hostname.split(".");

let stage;
if (hostname === "localhost") {
  // Treat localhost as 'dev'
  stage = "dev";
} else {
  // Default to 'prod' if no subdomain, otherwise take the subdomain
  stage = hostnameParts.length >= 3 ? hostnameParts[0] : "prod";
}

console.log("STAGE: ", stage);

// Select the appropriate configuration based on the subdomain
export const config = stage === "prod" ? prod : dev;

// NOTE: Set max upload size
export default {
  MAX_ATTACHMENT_SIZE: 5000000,

  ...config,
};
