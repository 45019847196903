import { API } from "aws-amplify";
import axios from "axios";

/**
 *
 * @function getOrganizations(userRecordId)
 *
 * This function will return a list of users when given a userRecordId
 * used to authorize the request which can only be performed by a
 * System_Admin user type.
 *
 * @param { string } userRecordId - the System_Admin user's unique record ID
 *
 * @returns { object } orgData as a list of organizations to select from
 *
 */
export const getOrganizations = async (userRecordId) => {
	const response = await API.get(
		"depo-app-api",
		`/corporate-records/${userRecordId}`
	);
	console.log("[AUDIT] - GET Organizations List DATA: ", response);
	return response.data;
};

/**
 *
 * @function listUsersByOrganization(payload)
 *
 * This function will get a list of users based on the
 * organization passed and requires an organizationId in the body
 * of the payload sent with the request
 *
 * @param { object } payload - the organiztionId to be queried on the
 * backend
 *
 * @return list of user based on the organizationId provided
 *
 */
export const listUsersByOrganization = async (payload) => {
	const response = await API.post("depo-app-api", `/list-users`, {
		body: payload,
	});
	return response;
};

// FIXME:
export const listSupportQueries = async (payload) => {
	const response = await API.post("depo-app-api", `/list-users`, {
		body: payload,
	});
	return [];
};

/**
 *
 * @function getUserRecordAPI(organizationId, userRecordId)
 *
 * This function will return user for a given userRecordId and
 * organizationid combination
 *
 * @param { string } userRecordId - the user's unique record ID
 *
 * @returns { object } userData
 *
 */
export const getUserRecordAPI = async (organizationId, userRecordId) => {
	const response = await API.get(
		"depo-app-api",
		`/user/${organizationId}/${userRecordId}`
	);
	console.log("[AUDIT] - GET USER DATA: ", response);
	return response.data;
};

/**
 *
 *  @function getOrganizationData(organizationId)
 *
 *  @params { string } organizationId
 *
 *  @returns { object } corporate data
 *
 **/
export const getOrganizationData = async (organizationId) => {
	// need api details
	const response = await API.get(
		"depo-app-api",
		`/corporate-record-info/${organizationId}`
	);
	console.log("[AUDIT] - GET ORG DATA: ", response);
	return response.data;
};

/**
 *
 * 	use this method to get an organizationId
 * 	and a corresponding userRole for this user
 *
 *
 *	@function corporateRecordCheck(payload)
 *	@param payload
 *
 * 	@example
 *
 * 	payload = {
 * 		"adminEmail": "david@ecom.com"
 * 	}
 *
 **/
export const corporateRecordCheck = async (payload) => {
	const response = await API.post("depo-app-api", `/corporate-record-check`, {
		body: payload,
	});
	return response;
};

/**
 *
 * @function postSignatureAPI(userRecordId)
 *
 * This function will return a signature for a given userRecordId
 *
 * @param { string } userRecordId - the user's unique record ID
 *
 * @returns { string } signature
 *
 */
export const postSignatureAPI = async (userRecordId) => {
	const response = await API.post(
		"depo-app-api",
		`/user-security?sign=true&userRecordId=${userRecordId}`,
		{
			body: {},
		}
	);
	return response.data;
};

/**
 *
 * @function createNewUser(userData)
 *
 * This function post a new user to the DB
 *
 * @param { string } userData - the user's unique record data
 *
 */
export const createNewUser = (payload) => {
	return API.post("depo-app-api", "/users/add", {
		headers: {
			"X-ECOM-REQUEST": payload.signature,
		},
		body: payload.user,
	});
};

/**
 *
 * @function getUsersByRole(userRole, payload)
 *
 * This function will get a list of users based on the
 * role passed and requires and organizationId in the body
 * of the payload sent with the request
 *
 * @param { string } userRole - the user's role to be verified by the backend
 * @param { object } organizationId - the user's organiztionId to be verified by the backend
 *
 * @return list of user based on the userRole provided
 *
 */
export const getUsersByRole = (userRole, payload) => {
	return API.post("depo-app-api", `/users/${userRole}`, {
		body: payload,
	});
};

// NOTE: updateUser
// FIXME: Add docs here asap
export const updateUser = (organizationId, userRecordId, payload) => {
	return API.put("depo-app-api", `/user/${organizationId}/${userRecordId}`, {
		body: payload,
	});
};

/**
 *
 * @function getDepositions(userRole, payload)
 *
 * This function will get a list of depositions based on
 * the role of the user asking for the records, and does
 * require the organization to be passed into the payload
 * of the request.
 *
 * @param { string } userRole - the user's role to be
 * 		verified by the backend & can be any of the following:
 *
 * 				1. System_Admin - can see all record data
 * 				2. Lawyer - can see all record data
 * 				3. Court_Reporter - can see data associated to the CR
 *
 * @param { object } organizationId - the user's organiztionId to be
 * 		verified by the backend
 *
 *
 *
 */
export const getDeposition = (userRole, payload) => {
	return API.get("depo-app-api", `/get-deposition/${userRole}`, {
		body: payload,
	});
};

export const listDepositions = (organizationId) => {
	return API.get("depo-app-api", `/list-depositions/${organizationId}`);
};

export const createDeposition = (payload) => {
	return API.post("depo-app-api", "/create-deposition", {
		body: payload,
	});
};

export const updateDeposition = (payload) => {
	return API.put("depo-app-api", "/update-deposition", {
		body: payload,
	});
};
